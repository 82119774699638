/*
* Diagrams
*
* description: Interactive diagrams for Pachyderm
*/

.diagram {
  background: $color-grey-1;
  border-radius: 16px;
  margin: 60px auto 80px auto;

  @include respond-to('medium') {
    margin: 24px auto 40px auto;
  }

  .diagram-header {
    align-items: center;
    display: flex;
    height: 80px;
    padding: 0 16px;

    img {
      display: inline-block;
      margin-right: 24px;
    }

    .diagram-dropdown {
      align-items: center;
      border: 1px solid $color-grey-2; 
      border-radius: 4px;
      box-sizing: border-box;
      color: $color-grey-4;
      display: flex;
      font-size: 12px;
      font-weight: 500;
      height: 40px;
      justify-content: space-between;

      i {
        margin: 0 16px;
        opacity: .56;
      }
    }
  }

  .diagram-process {
    background: url("/src/wp-content/themes/pachyderm/img/graphics/data-transfer.webp") center 112px no-repeat;
    padding: 0 80px;

    @include respond-to('large') {
      padding: 0 16px;
    }

    @include respond-to('medium') {
      background: url("/src/wp-content/themes/pachyderm/img/graphics/data-transfer-vertical.webp") center 148px no-repeat;
    }
  }

  .diagram-footer {
    height: 80px;
    line-height: 80px;
    padding: 0 16px;
  }
}


/*
* Diagram Data Card
*
* description: Data card to represent input/output of data
*/

.diagram-card {
  background: $color-white;
  box-shadow: 0 8px 32px rgba($color: $color-black, $alpha: .24);
  border: 1px solid $color-grey-3;
  border-radius: 8px;
  overflow: hidden;

  &.is-original {
    margin-right: 40px;

    @include respond-to('medium') {
      margin: 0 0 40px 0;
    }  
  }

  &.is-new {
    margin-left: 40px;

    @include respond-to('medium') {
      margin: 40px 0 0 0;
    }  
  }

  header {
    align-items: center;
    background: $color-grey-1;
    border-bottom: 1px solid $color-grey-2;
    display: flex;
    height: 48px;
    justify-content: space-between;
    margin-bottom: 8px;

    h3 {
      color: $color-text;
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 0 16px;

      @include respond-to('large') {
        font-size: 12px;
      } 
    }

    .diagram-card-version {
      align-items: center;
      border: 1px solid $color-grey-2; 
      border-radius: 4px;
      box-sizing: border-box;
      color: $color-grey-3;
      display: flex;
      font-size: 12px;
      font-weight: 500;
      height: 32px;
      justify-content: space-between;
      margin-right: 8px;

      i {
        margin: 0 8px;
      }
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0 8px;

    li {
      margin: 8px;

      @include respond-to('large') {
        margin: 4px;
      } 
    }

    img {
      height: 56px;
      width: 56px;

      @include respond-to('large') {
        height: 48px;
        width: 48px;
      } 

      @include respond-to('medium') {
        height: 64px;
        width: 64px;
      } 
    }
  }

  footer {
    align-items: center;
    background: $color-grey-1;
    border-top: 1px solid $color-grey-2;
    color: $color-grey-3;
    display: flex;
    font-size: 10px;
    height: 32px;
    margin-top: 8px;
    padding: 0 16px;
  }
}

.diagram-store {
  margin: 16px auto 0 auto;

  @include respond-to('medium') {
    visibility: hidden;
  }  
}

/*
* Diagram Terminal
*
* description: Transformation code in the diagram
*/

.diagram-terminal {
  background: $color-black;
  box-shadow: 0 8px 32px rgba($color: $color-black, $alpha: .56);
  border: 1px solid $color-text;
  border-radius: 8px;
  height: 250px;
  margin-bottom: 48px;
  overflow: hidden;

  header {
    align-items: center;
    border-bottom: 1px solid rgba($color: $color-grey-4, $alpha: .24);
    display: flex;
    height: 48px;
    justify-content: space-between;

    h3 {
      color: $color-white;
      font-size: 14px;
      font-weight: 500;
      margin: 0 0 0 16px;

      i {
        color: rgba($color: $color-grey-4, $alpha: .56); 
        margin-right: 8px;
      }
    }

    .diagram-terminal-dropdown {
      align-items: center;
      border: 1px solid rgba($color: $color-grey-4, $alpha: .56); 
      border-radius: 4px;
      box-sizing: border-box;
      color: rgba($color: $color-grey-4, $alpha: .56); 
      display: flex;
      font-size: 12px;
      font-weight: 500;
      height: 32px;
      justify-content: space-between;
      margin-right: 8px;

      i {
        margin: 0 8px;
      }
    }
  }

  pre {
    margin: 0;
    padding: 0;
    font-size: 12px;
    border: none;
    color: $color-white !important;
    background: none !important;
    tab-size: 0;
  }

  code, code.hljs {
    background: $color-black;
    margin: 0;
    overflow: hidden;
    opacity: .40;
    padding: 0;
    text-align: left;
  }
}


/*
* Diagram Features
*
* description: diagram feature list
*/

.diagram-features {
  display: flex;
  flex-wrap: wrap;

  @include respond-to('medium') {
    display: block;
  }

  > div {
    box-sizing: border-box;
    width: 20%; 
    padding: 0 8px;

    @include respond-to('medium') {
      background: $color-grey-1;
      border-radius: 8px;
      margin-bottom: 16px;
      padding: 16px 16px 8px 16px;
      width: auto;
    }
  }
}