/*
* Logo
*
* description: variations of the Pachyderm logo
*/

.logo {
  align-items: center;
  color: $color-text;
  display: flex;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  text-decoration: none;

  a {
    align-items: center;
    color: $color-text;
    display: flex;
    font-size: 22px;
    font-weight: 700;
    text-decoration: none;
  }

  img {
    height: 48px;
    margin-right: 12px;
    margin-top: -12px;
  }

  strong {
  }
}