$color-codebox: #0C021E;

.codebox-wrapper {
  background: $color-gradient-purple;
  border-radius: 16px;
  box-shadow: 0 4px 32px rgba($color: #000000, $alpha: .56), -16px -16px 156px rgba($color: $color-coral, $alpha: .32);
  padding: 2px; 
}

.codebox {
  background: $color-codebox;
  border-radius: 16px;
  overflow: hidden;
  
  nav {
    background: $color-black;
    display: block;
    padding: 8px 0 0 0;
    
    ul {
      list-style-type: none;
      margin: 0; 
      padding: 8px 24px 0 24px; 
    }

    li {
      display: inline-block;
      margin: 0 8px 0 0; 
    }

    button {
      align-items: center;
      background: none;
      box-sizing: border-box;
      border: none;
      border-bottom: 4px solid $color-black;
      color: $color-grey-4;
      cursor: pointer;
      font-family: $mono-font;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      height: 40px;
      padding: 0 8px;
      text-decoration: none;
      transition: all .3s;

      &:hover, &:focus {
        background: $color-text;
        border-color: rgba($color: $color-grey-4, $alpha: .32);
      }

      &.is-active {
        border-color: $color-primary;
        color: $color-white;
      }

      img {
        margin-right: 8px;
        height: 18px;

        @include respond-to('medium') {
          display: none;
        }
      }
    }
  }

  .codebox-code {
    display: none;
    max-height: 500px;
    overflow: auto;

    &.is-active {
      display: block;
      height: 440px;
    }
  }

  pre {
    background: $color-codebox;
    border: none;
    color: $color-white !important;
    padding: 0 24px 24px 24px;
    margin: 0;
    background: none !important;
    tab-size: 0;
  }

  code, code.hljs {
    background: $color-codebox;
    font-size: 14px;
    padding: 0;
    margin: 0;
    line-height: 24px;
  }
}