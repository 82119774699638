.pricing-table table {
  border-collapse: collapse;
  box-sizing: border-box;
  max-width: 100%;

  .is-community, 
  .is-enterprise {
    text-align: center;
  }

  thead {
    .text-meta-title {
      line-height: 32px;
    }
    .text-info-title {
      margin-bottom: 16px;
    }

    th {
      font-weight: normal;
      padding: 0 16px;
      text-align: center;
      text-transform: none;

      &:first-child {
        vertical-align: bottom;

        img {
          max-width: 80%;
        }
      }

      @include respond-to('medium') {
        padding: 16px;
      }

      @include respond-to('small') {
        padding: 8px;
        vertical-align: top;

        h4 {
          font-size: 14px;
          text-transform: uppercase;
          line-height: 24px !important;
        }

        p {
          font-size: 12px;
          line-height: 18px;
        }
      }

      &.is-enterprise {
        background-color: $color-white;
        box-shadow: 0 4px 16px rgba($color: #000000, $alpha: .24);
        border-left: 3px solid $color-primary;
        border-right: 3px solid $color-primary;
        border-top: 3px solid $color-primary;
      }
    }
  } 

  tbody {
    box-shadow: 0 4px 16px rgba($color: #000000, $alpha: .24);

    tr {
      overflow: hidden;

      &:first-child {
        td:first-child {
          border-radius: 16px;
        }
      }
    }

    tr:nth-child(even) {
      background: $color-grey-1;
    }
    tr:nth-child(odd) {
      background: $color-white;
    }

    td {
      border: 1px solid $color-grey-2;
      font-weight: 500;
      line-height: 24px;
      padding: 24px 48px;
      width: 33%;

      @include respond-to('medium') {
        font-size: 12px;
        padding: 16px;
        line-height: 18px;
      }

      @include respond-to('small') {
        padding: 8px;
      }

      &.is-enterprise {
        border-left: 3px solid $color-primary;
        border-right: 3px solid $color-primary;
      }

      i {
        color: $color-green;
        font-size: 24px;

        @include respond-to('small') {
          font-size: 18px;
        }
      }
    }
  }

  tfoot {
    td {
      // border-bottom: 1px solid $color-grey-2;
      padding: 24px 48px;

      @include respond-to('medium') {
        font-size: 12px;
        padding: 16px;
      }

      @include respond-to('small') {
        padding: 8px;
      }

      .button {
        display: block;
        white-space: normal;
  
        @include respond-to('small') {
          font-size: 12px;
        }

        &.is-light {
          background: $color-grey-1;
        }
      }

      &.is-enterprise {
        background-color: $color-white;

        border-left: 3px solid $color-primary;
        border-right: 3px solid $color-primary;
        border-bottom: 3px solid $color-primary;
      }
    }
  }
}