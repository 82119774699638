/*
* Basic Defaults
*
*/

$unit: 8px;
$primary-font: 'Montserrat', Helvetica, Arial, "Lucida Grande", sans-serif;
$mono-font: 'Ubuntu Mono', "Courier New", Courier, monospace;


/*
* Breakpoints & Layout Sizes
*
*/

$small-breakpoint: 800px !default;
$medium-breakpoint: 960px !default;
$large-breakpoint: 1260px !default;
$x-large-breakpoint: 1400px !default;
$layout-max-width: 1240px;

/*
* Z Indexes
*
*/

$z-1: 1;
$z-2: 2;
$z-3: 3;
$z-4: 4;
$z-5: 5;

$z-max-1: 1000;
$z-max-2: 2000;
$z-max-3: 3000;
$z-max-4: 4000;
$z-max-5: 5000;


/*
* Brand Colors
*
*/

$color-primary: #62138E; 
$color-highlight: #A122CC; 

$color-coral: #EC7878; 
$color-cyan: #5BA3B0; 
$color-green: #208873; 
$color-orange: #FA9674;
$color-purple: #582F6B; 

// Neutrals 
$color-white: #FFFFFF; 
$color-black: #000000; 
$color-text: #0E0E0F; 

$color-grey-1: #F7F8FA; 
$color-grey-2: #E2E4EA; 
$color-grey-3: #CFD1D7; 
$color-grey-4: #8C9198;

// Gradients
$color-gradient-pink: linear-gradient(-60deg, #DB5B62 0%, #62138E 50%, #582F6B 100%);
$color-gradient-purple: linear-gradient(140deg, #060110 0%, #1A0441 40%, #41185C 100%);
$color-gradient-blue: linear-gradient(-90deg, #5BA3B0 0%, #62138E 60%, #582F6B 100%);
$color-gradient-green:  linear-gradient(140deg,  #305B5E 40%, $color-cyan 100%);
$color-gradient-dark: linear-gradient(140deg,  $color-black 40%, $color-grey-4 100%);

/*
* Utility Colors
*
*/

$color-utility-warning: #FFAB00; 
$color-utility-notice: #0091EA; 
$color-utility-success: #34CAAA; 
$color-utility-error: #F50057; 


