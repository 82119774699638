.showcase {
  display: flex;
  margin-top: 40px;

  @include respond-to('medium') {
    display: block;
  }

  .showcase-nav {
    @include respond-to('medium') {
      background: $color-white;
      border-radius: 16px 16px 0 0;
      border: 2px solid $color-primary;
      border-bottom: none;
      overflow: hidden;
    }
 
  
    ul {
      list-style-type: none;
      margin: 48px 0 0 0;
      padding: 0;

      @include respond-to('medium') {
        margin: 0;
        display: flex;
      }
    
      li {
        @include respond-to('medium') {
          display: inline-block;
        }
      }  
    }

    
    button {
      align-items: center;
      background: none;
      border: none;
      color: rgba($color-text, .87);
      display: flex;
      height: 88px;
      padding: 0 40px;
      width: 300px;
      transition:  all .3s;

      @include respond-to('large') {
        padding: 0 16px;
        width: 248px;
      }

      @include respond-to('medium') {
        font-size: 12px;
        height: 48px;
        padding: 0 16px;
        width: auto;
      }

      @include respond-to('small') {
        font-size: 10px;
        height: 48px;
        padding: 0 16px;
        width: auto;
      }

      &:hover {
        color: $color-primary;
      }

      &.is-active {
        background: linear-gradient(90deg, $color-white, rgba($color-primary, .12));
        color: $color-primary;
      }

      i {
        font-size: 24px;
        margin-right: 16px;

        @include respond-to('medium') {
          display: none;
        }
      }
    }
  }

  .showcase-content {
    background: $color-white url("/src/wp-content/themes/pachyderm/img/backgrounds/grid.png") top left repeat;;
    border: 2px solid $color-primary;
    border-radius: 16px;
    box-shadow: 0 8px 32px rgba($color-black, .24);
    padding: 48px 32px 48px 80px;

    @include respond-to('large') {
      padding: 32px 16px 32px 32px;
    }

    @include respond-to('medium') {
      border-radius: 0 0 16px 16px;
      padding: 16px;
    }
  }

  .showcase-data {
    background: url("/src/wp-content/themes/pachyderm/img/backgrounds/connectors.svg") 48px 88px  no-repeat;
    margin: 0;
    padding-right: 40px;

    @include respond-to('large') {
      background: url("/src/wp-content/themes/pachyderm/img/backgrounds/connectors.svg") 32px 64px  no-repeat;
    }

    @include respond-to('medium') {
      background: url("/src/wp-content/themes/pachyderm/img/backgrounds/connectors-vertical.svg")  center 56px no-repeat;
      padding: 0 0 40px 0;
    }
    
    ul {
      list-style-type: none;
      margin: 0; 
      padding: 0;
      width: 80px;
      
      @include respond-to('large') {
        width: 64px;
      }
      @include respond-to('medium') {
        width: auto;
        display: flex;
        justify-content: space-between;
      }
    }

    li {
      background: $color-white;
      border: 1px solid $color-grey-1; 
      box-shadow: 0 4px 16px rgba($color-black, .24);
      color: rgba($color-text, .87);
      font-size: 12px;
      height: 96px;
      line-height: 16px;
      margin-bottom: 16px;
      text-align: center;
      width: 80px;

      @include respond-to('large') {
        height: 80px;
        width: 64px;
      }

      @include respond-to('medium') {
        display: inline-block;
        font-size: 14px;
        width: 30%;
      }

      @include respond-to('small') {
        display: inline-block;
        font-size: 11px;
        width: 30%;
      }

      i {
        display: block;
        font-size: 24px;
        margin: 12px 0;

        @include respond-to('large') {
          margin: 8px 0;
          font-size: 16px;
        }
      }
    }
  }

  .showcase-diagram {
    display: flex;
    align-items: center;
    padding-top: 16px;

    @include respond-to('medium') {
      display: block;
    }
  }

  .showcase-slide {
    display: none;

    &.is-active {
      display: block;
    }

    h3 {
      align-items: center;
      display: flex;

      i {
        background: $color-gradient-blue;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
        font-size: 32px;
        margin-right: 16px;
      }
    }

    p {
      font-weight: normal;
      margin-top: 24px;
    }

    figure {
      margin: 0; 
      
      img {
        max-width: 100%
      };
    }

    .showcase-highlights {
      margin-left: 32px;
      width: 240px;

      @include respond-to('large') {
        margin-left: 16px;
        width: 220px;
      }
      @include respond-to('medium') {
        margin: 0;
        width: 100%;
      }
      
      li {
        align-items: flex-start;
        display: flex;
        margin-bottom: 24px;

        @include respond-to('large') {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 16px;
        }
      }

      i {
        color: $color-green;
        font-size: 24px;
      }
    }
  }
}