/*
* Graphics
* 
* description: graphics for each section to highlight selling points
*/

.graphic-image {
  box-shadow: 0 4px 32px rgba($color: $color-black, $alpha: .12);
  border-radius: 8px;
}

.graphic-any-data {
  position: relative;
  height: 500px;
  width: 100%;

  @include respond-to('small') {
    height: 440px;
  }

  img {
    position: absolute;
    z-index: $z-1;
  }

  .is-csv {
    opacity: .87;
    right: 0; 
    top: 0;

    @include respond-to('small') {
      height: 150px;
    }
  }
  .is-table {
    opacity: .87;
    right: 0; 
    bottom: 0;

    @include respond-to('small') {
      height: 150px;
    }
  }
  .is-genomics {
    opacity: .87;
    left: 0; 
    bottom: 0;

    @include respond-to('small') {
      height: 150px;
    }
  }
  .is-photos {
    left: 50%; 
    top: 50%;
    margin: -156px 0 0 -220px;

    @include respond-to('small') {
      margin: -100px 0 0 -170px;
      height: 240px;
    }
  }
  .is-video {
    opacity: .87;
    left: 0; 
    top: 0;

    @include respond-to('small') {
      height: 160px;
    }
  }
}

.graphic-integrations {
  align-items: center;
  display: flex;
  height: 540px;
  justify-content: center;
  position: relative;
  width: 100%;

  @include respond-to('small') {
    height: 440px;
  }

  .is-elephant {
    @include respond-to('small') {
      height: 240px;
    }
  }

  .is-jupyter {
    position: absolute;
    right: 50%;
    bottom: -60px;

    @include respond-to('small') {
      height: 96px;
      bottom: 0px;
      right: 60%;
    }
  }
  .is-ls {
    position: absolute;
    right: 0;
    bottom: 120px;
    
    @include respond-to('small') {
      height: 96px;
      bottom: 60px;
    }
  }
  .is-spark {
    left: 80px;
    position: absolute;
    top: 0;

    @include respond-to('small') {
      height: 96px;
      left: 120px;
    }
  }
  .is-wb {
    position: absolute;
    left: -40px;
    bottom: 200px;

    @include respond-to('small') {
      height: 96px;
      left: 0;
    }
  }
  .is-tensorflow {
    position: absolute;
    right: 16px;
    top: 16px;

    @include respond-to('small') {
      height: 96px;
      top: 116px;
    }
  }
}