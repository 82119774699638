//Jobs table on Careers page
.careers-table{
  max-width: 800px;
   margin: 0 auto;
}

table.careers td {
   vertical-align: baseline;
   border: 0px;
   margin-top: 0px;
}

table.careers td a{
 color: grey;
 transition: all .2s;
 font-weight: 600;
}

table.careers td a:hover{
 color: #62138e;
 transition: all .2s;
}

.careers-table h3.text-info-title{
   margin-bottom: 10px;
   margin-top: 30px;
}

td.location{
   font-size: 12px;
   font-weight: 600;
   color: grey;
   text-align: right;
}

table.careers tr:nth-child(odd) {background-color: #f6f6f6;}

table.careers tr{
  border: 1px solid #f6f6f6;
}

table.careers td{
  padding: 5px;
}
