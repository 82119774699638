.video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  
  iframe {
    border-radius: 16px;
    box-shadow: 0 4px 32px rgba($color: $color-black, $alpha: .24);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}