/*
* Theme Backgrounds
*
* description: background decorative styles for website sections
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
  font-weight: 700;
}


// COLOR BACKGROUNDS
.background-black {
  background: $color-black;
}
.background-transparent-black {
  background: rgba($color: $color-black, $alpha: .56) ; 
}
.background-grey {
  background: $color-grey-1;
}
.background-transparent-white {
  background: linear-gradient(90deg,  rgba($color: $color-white, $alpha: .87) 35%,  rgba($color: $color-white, $alpha: .36) );

  @include respond-to('large') {
    background: linear-gradient(0deg,  rgba($color: $color-white, $alpha: .87) 20%,  rgba($color: $color-white, $alpha: .36) );
  }
}

// GRADIENT BACKGROUNDS
.background-dark-1 {
  background: linear-gradient(140deg, #000000 0%, #040404 40%, #65158D 80%, #BC5060 100%);
}
.background-purple {
  background: $color-gradient-purple
}
.background-purple-blue {
  background: linear-gradient(140deg, #582F6B 0%, #62138E 40%, #5BA3B0 100%);
}
.background-gradient-pink {
  background: $color-gradient-pink;
}
.background-gradient-purple {
  background: $color-gradient-purple;
}
.background-gradient-blue {
  background: $color-gradient-blue;
}
.background-gradient-green {
  background: $color-gradient-green;
}
.background-gradient-dark {
  background: $color-gradient-dark;
}
.background-gradient-grey-white {
  background: linear-gradient(90deg, #F7F8FA, #FFFFFF );
}
.background-gradient-spotlight {
  background: #5D1E52;
  background: -webkit-radial-gradient(top, #5D1E52 0%, #1A0441 40%, #000000 80%, #000000 100%);
  background: -moz-radial-gradient(top, #5D1E52 0%, #1A0441 40%, #000000 80%, #000000 100%);
  background: radial-gradient(to bottom, #5D1E52 0%, #1A0441 40%, #000000 80%, #000000 100%);
}
//Did this as a cheat since we use it a lot in old site
.bg-lightblue {
  background: linear-gradient(90deg, #F7F8FA, #FFFFFF );
}

// IMAGE BACKGROUNDS
.background-squares {
  background: url("/src/wp-content/themes/pachyderm/img/backgrounds/squares.png?as=webp") top center repeat;
}
.background-dots {
  background: url("/src/wp-content/themes/pachyderm/img/backgrounds/dots.svg") center top no-repeat;
}
.background-elephant-diagram {
  background: url("/src/wp-content/themes/pachyderm/img/graphics/elephant-diagram.png?as=webp") center right no-repeat;

  @include respond-to('x-large') {
    background-size: 40%;
    background-position: center right;
  }

  @include respond-to('large') {
    background-size: 30%;
    background-position: center right;
  }

  @include respond-to('medium') {
    background: none;
  }
}
.background-agbiome {
  background: url("/src/wp-content/themes/pachyderm/img/backgrounds/customers/agbiome.png?as=webp") center center no-repeat;
  background-size: cover;
}

// ARROW BACKGROUNDS
.background-arrow-grey-down {
  background: url("/src/wp-content/themes/pachyderm/img/backgrounds/grey-arrow-down.svg") top left no-repeat;
  background-size: 100%;
  padding-top: 164px;
}
.background-arrow-grey-down-inverse {
  background: url("/src/wp-content/themes/pachyderm/img/backgrounds/grey-arrow-down-inverse.svg") top left no-repeat;
  background-size: 100%;
  padding-top: 164px;
}
.background-arrow-triple-down {
  background: url("/src/wp-content/themes/pachyderm/img/backgrounds/arrows-triple-bottom.svg") top left no-repeat;
  background-size: 100%;
  padding-top: 280px;
}
.background-arrow-triple {
  background: #000 url("/src/wp-content/themes/pachyderm/img/backgrounds/arrows-triple.svg") center center repeat;
  background-size: cover;
  min-height: 800px;
  padding: 100px 0;
}

.background-arrow-triple-light {
  background: rgb(255, 255, 255) url("/src/wp-content/themes/pachyderm/img/backgrounds/arrows-triple-white.svg") center center repeat;
  background-size: cover;
  min-height: 800px;
  padding: 100px 0;
}

// DIAGONAL BACKGROUNDS
.background-diagonal-dark {
  background: url("/src/wp-content/themes/pachyderm/img/backgrounds/diagonal-dark.svg") bottom left no-repeat;
  background-size: 100%;
  padding-bottom: 350px;

  @include respond-to('small') {
    padding-bottom: 200px;
  }
}

.background-diagonal-top {
  background: url("/src/wp-content/themes/pachyderm/img/backgrounds/diagonal-top.svg") bottom left no-repeat;
  background-size: 100%;
  padding-bottom: 350px;

  @include respond-to('small') {
    padding-bottom: 200px;
  }
}



