@mixin respond-to($media) {

  @if $media == small {
    @media screen and (max-width: $small-breakpoint),
    screen and (max-device-width: $small-breakpoint) {
      @content;
    }
  }
  @else if $media == medium {
    @media screen and (max-width: $medium-breakpoint),
    screen and (max-device-width: $medium-breakpoint),
    screen and (max-width: $medium-breakpoint) {
      @content;
    }
  }
  @else if $media == short {
    @media screen and (max-height: 880px) {
      @content;
    }
  }
  @else if $media == large {
    @media screen and (max-width: $large-breakpoint),
    screen and (max-device-width: $large-breakpoint),
    screen and (max-width: $large-breakpoint) {
      @content;
    }
  }
  @else if $media == x-large {
    @media screen and (max-width: $x-large-breakpoint),
    screen and (max-device-width: $x-large-breakpoint),
    screen and (max-width: $x-large-breakpoint) {
      @content;
    }
  }
  @else if $media == jumbo {
    @media screen and (min-width: $x-large-breakpoint) {
      @content;
    }
  }
}


@mixin retina {
  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    @content;
  }
}
