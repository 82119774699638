pre code.hljs {
  padding: 1em;
  display: block;
  overflow-x: auto;
}

code.hljs {
  padding: 3px 5px;
}

.hljs {
  color: #444;
  background: #f3f3f3;
}

.hljs-comment {
  color: #697070;
}

.hljs-punctuation, .hljs-tag {
  color: #444a;
}

.hljs-tag .hljs-attr, .hljs-tag .hljs-name {
  color: #444;
}

.hljs-attribute, .hljs-doctag, .hljs-keyword, .hljs-meta .hljs-keyword, .hljs-name, .hljs-selector-tag {
  font-weight: 700;
}

.hljs-deletion, .hljs-number, .hljs-quote, .hljs-selector-class, .hljs-selector-id, .hljs-string, .hljs-template-tag, .hljs-type {
  color: #800;
}

.hljs-section, .hljs-title {
  color: #800;
  font-weight: 700;
}

.hljs-link, .hljs-operator, .hljs-regexp, .hljs-selector-attr, .hljs-selector-pseudo, .hljs-symbol, .hljs-template-variable, .hljs-variable {
  color: #ab5656;
}

.hljs-literal {
  color: #695;
}

.hljs-addition, .hljs-built_in, .hljs-bullet, .hljs-code {
  color: #397300;
}

.hljs-meta {
  color: #1f7199;
}

.hljs-meta .hljs-string {
  color: #38a;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 700;
}

pre code.hljs {
  padding: 1em;
  display: block;
  overflow-x: auto;
}

code.hljs {
  padding: 3px 5px;
}

.hljs {
  color: #ddd;
  background: #272822;
}

.hljs-keyword, .hljs-literal, .hljs-name, .hljs-selector-tag, .hljs-strong, .hljs-tag {
  color: #f92672;
}

.hljs-code {
  color: #66d9ef;
}

.hljs-attribute, .hljs-link, .hljs-regexp, .hljs-symbol {
  color: #bf79db;
}

.hljs-addition, .hljs-built_in, .hljs-bullet, .hljs-emphasis, .hljs-section, .hljs-selector-attr, .hljs-selector-pseudo, .hljs-string, .hljs-subst, .hljs-template-tag, .hljs-template-variable, .hljs-title, .hljs-type, .hljs-variable {
  color: #a6e22e;
}

.hljs-class .hljs-title, .hljs-title.class_ {
  color: #fff;
}

.hljs-comment, .hljs-deletion, .hljs-meta, .hljs-quote {
  color: #75715e;
}

.hljs-doctag, .hljs-keyword, .hljs-literal, .hljs-section, .hljs-selector-id, .hljs-selector-tag, .hljs-title, .hljs-type {
  font-weight: 700;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: Montserrat, Helvetica, Arial, Lucida Grande, sans-serif;
  font-weight: 400;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: Montserrat, Helvetica, Arial, Lucida Grande, sans-serif;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  color: #62138e;
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.text-super-title, .text-jumbo-title, .text-large-title, .text-main-title, .hero.is-case-study .hero-content h1, .text-auto-format h2, .text-section-title, .text-auto-format h3, .text-info-title, .mktoFormWrapper h3, .aside h4, .text-auto-format h4, .text-meta-title, .aside h3, .text-auto-format h6, .text-auto-format h5, .text-paragraph, .hero.is-case-study .hero-content p, .text-auto-format p, .text-list, .text-auto-format ul, .text-meta, .mktoFormWrapper footer p, .aside p, .text-auto-format small, .text-meta-list {
  color: #0e0e0f;
  margin: 0 0 16px;
  font-family: Montserrat, Helvetica, Arial, Lucida Grande, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.text-super-title.is-italic, .text-jumbo-title.is-italic, .text-large-title.is-italic, .text-main-title.is-italic, .hero.is-case-study .hero-content h1.is-italic, .text-auto-format h2.is-italic, .text-section-title.is-italic, .text-auto-format h3.is-italic, .text-info-title.is-italic, .mktoFormWrapper h3.is-italic, .aside h4.is-italic, .text-auto-format h4.is-italic, .text-meta-title.is-italic, .aside h3.is-italic, .text-auto-format h6.is-italic, .text-auto-format h5.is-italic, .text-paragraph.is-italic, .hero.is-case-study .hero-content p.is-italic, .text-auto-format p.is-italic, .text-list.is-italic, .text-auto-format ul.is-italic, .text-meta.is-italic, .mktoFormWrapper footer p.is-italic, .aside p.is-italic, .text-auto-format small.is-italic, .text-meta-list.is-italic {
  font-style: italic;
}

.text-super-title.is-medium, .text-jumbo-title.is-medium, .text-large-title.is-medium, .text-main-title.is-medium, .hero.is-case-study .hero-content h1.is-medium, .text-auto-format h2.is-medium, .text-section-title.is-medium, .text-auto-format h3.is-medium, .text-info-title.is-medium, .mktoFormWrapper h3.is-medium, .aside h4.is-medium, .text-auto-format h4.is-medium, .text-meta-title.is-medium, .aside h3.is-medium, .text-auto-format h6.is-medium, .text-auto-format h5.is-medium, .text-paragraph.is-medium, .hero.is-case-study .hero-content p.is-medium, .text-auto-format p.is-medium, .text-list.is-medium, .text-auto-format ul.is-medium, .text-meta.is-medium, .mktoFormWrapper footer p.is-medium, .aside p.is-medium, .text-auto-format small.is-medium, .text-meta-list.is-medium {
  font-weight: 500;
}

.text-super-title.is-bold, .text-jumbo-title.is-bold, .text-large-title.is-bold, .text-main-title.is-bold, .hero.is-case-study .hero-content h1.is-bold, .text-auto-format h2.is-bold, .text-section-title.is-bold, .text-auto-format h3.is-bold, .text-info-title.is-bold, .mktoFormWrapper h3.is-bold, .aside h4.is-bold, .text-auto-format h4.is-bold, .text-meta-title.is-bold, .aside h3.is-bold, .text-auto-format h6.is-bold, .text-auto-format h5.is-bold, .text-paragraph.is-bold, .hero.is-case-study .hero-content p.is-bold, .text-auto-format p.is-bold, .text-list.is-bold, .text-auto-format ul.is-bold, .text-meta.is-bold, .mktoFormWrapper footer p.is-bold, .aside p.is-bold, .text-auto-format small.is-bold, .text-meta-list.is-bold {
  font-weight: 700;
}

.text-super-title.is-extra-bold, .text-jumbo-title.is-extra-bold, .text-large-title.is-extra-bold, .text-main-title.is-extra-bold, .hero.is-case-study .hero-content h1.is-extra-bold, .text-auto-format h2.is-extra-bold, .text-section-title.is-extra-bold, .text-auto-format h3.is-extra-bold, .text-info-title.is-extra-bold, .mktoFormWrapper h3.is-extra-bold, .aside h4.is-extra-bold, .text-auto-format h4.is-extra-bold, .text-meta-title.is-extra-bold, .aside h3.is-extra-bold, .text-auto-format h6.is-extra-bold, .text-auto-format h5.is-extra-bold, .text-paragraph.is-extra-bold, .hero.is-case-study .hero-content p.is-extra-bold, .text-auto-format p.is-extra-bold, .text-list.is-extra-bold, .text-auto-format ul.is-extra-bold, .text-meta.is-extra-bold, .mktoFormWrapper footer p.is-extra-bold, .aside p.is-extra-bold, .text-auto-format small.is-extra-bold, .text-meta-list.is-extra-bold {
  font-weight: 800;
}

.text-super-title.is-black, .text-jumbo-title.is-black, .text-large-title.is-black, .text-main-title.is-black, .hero.is-case-study .hero-content h1.is-black, .text-auto-format h2.is-black, .text-section-title.is-black, .text-auto-format h3.is-black, .text-info-title.is-black, .mktoFormWrapper h3.is-black, .aside h4.is-black, .text-auto-format h4.is-black, .text-meta-title.is-black, .aside h3.is-black, .text-auto-format h6.is-black, .text-auto-format h5.is-black, .text-paragraph.is-black, .hero.is-case-study .hero-content p.is-black, .text-auto-format p.is-black, .text-list.is-black, .text-auto-format ul.is-black, .text-meta.is-black, .mktoFormWrapper footer p.is-black, .aside p.is-black, .text-auto-format small.is-black, .text-meta-list.is-black {
  font-weight: 900;
}

.text-super-title.is-uppercase, .text-jumbo-title.is-uppercase, .text-large-title.is-uppercase, .text-main-title.is-uppercase, .hero.is-case-study .hero-content h1.is-uppercase, .text-auto-format h2.is-uppercase, .text-section-title.is-uppercase, .text-auto-format h3.is-uppercase, .text-info-title.is-uppercase, .mktoFormWrapper h3.is-uppercase, .aside h4.is-uppercase, .text-auto-format h4.is-uppercase, .text-meta-title.is-uppercase, .aside h3.is-uppercase, .text-auto-format h6.is-uppercase, .text-auto-format h5.is-uppercase, .text-paragraph.is-uppercase, .hero.is-case-study .hero-content p.is-uppercase, .text-auto-format p.is-uppercase, .text-list.is-uppercase, .text-auto-format ul.is-uppercase, .text-meta.is-uppercase, .mktoFormWrapper footer p.is-uppercase, .aside p.is-uppercase, .text-auto-format small.is-uppercase, .text-meta-list.is-uppercase {
  text-transform: uppercase;
}

.text-super-title.is-lowercase, .text-jumbo-title.is-lowercase, .text-large-title.is-lowercase, .text-main-title.is-lowercase, .hero.is-case-study .hero-content h1.is-lowercase, .text-auto-format h2.is-lowercase, .text-section-title.is-lowercase, .text-auto-format h3.is-lowercase, .text-info-title.is-lowercase, .mktoFormWrapper h3.is-lowercase, .aside h4.is-lowercase, .text-auto-format h4.is-lowercase, .text-meta-title.is-lowercase, .aside h3.is-lowercase, .text-auto-format h6.is-lowercase, .text-auto-format h5.is-lowercase, .text-paragraph.is-lowercase, .hero.is-case-study .hero-content p.is-lowercase, .text-auto-format p.is-lowercase, .text-list.is-lowercase, .text-auto-format ul.is-lowercase, .text-meta.is-lowercase, .mktoFormWrapper footer p.is-lowercase, .aside p.is-lowercase, .text-auto-format small.is-lowercase, .text-meta-list.is-lowercase {
  text-transform: lowercase;
}

.text-super-title.no-margin, .text-jumbo-title.no-margin, .text-large-title.no-margin, .text-main-title.no-margin, .hero.is-case-study .hero-content h1.no-margin, .text-auto-format h2.no-margin, .text-section-title.no-margin, .text-auto-format h3.no-margin, .text-info-title.no-margin, .mktoFormWrapper h3.no-margin, .aside h4.no-margin, .text-auto-format h4.no-margin, .text-meta-title.no-margin, .aside h3.no-margin, .text-auto-format h6.no-margin, .text-auto-format h5.no-margin, .text-paragraph.no-margin, .hero.is-case-study .hero-content p.no-margin, .text-auto-format p.no-margin, .text-list.no-margin, .text-auto-format ul.no-margin, .text-meta.no-margin, .mktoFormWrapper footer p.no-margin, .aside p.no-margin, .text-auto-format small.no-margin, .text-meta-list.no-margin {
  margin: 0 !important;
}

.text-super-title.is-center, .text-jumbo-title.is-center, .text-large-title.is-center, .text-main-title.is-center, .hero.is-case-study .hero-content h1.is-center, .text-auto-format h2.is-center, .text-section-title.is-center, .text-auto-format h3.is-center, .text-info-title.is-center, .mktoFormWrapper h3.is-center, .aside h4.is-center, .text-auto-format h4.is-center, .text-meta-title.is-center, .aside h3.is-center, .text-auto-format h6.is-center, .text-auto-format h5.is-center, .text-paragraph.is-center, .hero.is-case-study .hero-content p.is-center, .text-auto-format p.is-center, .text-list.is-center, .text-auto-format ul.is-center, .text-meta.is-center, .mktoFormWrapper footer p.is-center, .aside p.is-center, .text-auto-format small.is-center, .text-meta-list.is-center {
  text-align: center;
}

.text-super-title.is-right, .text-jumbo-title.is-right, .text-large-title.is-right, .text-main-title.is-right, .hero.is-case-study .hero-content h1.is-right, .text-auto-format h2.is-right, .text-section-title.is-right, .text-auto-format h3.is-right, .text-info-title.is-right, .mktoFormWrapper h3.is-right, .aside h4.is-right, .text-auto-format h4.is-right, .text-meta-title.is-right, .aside h3.is-right, .text-auto-format h6.is-right, .text-auto-format h5.is-right, .text-paragraph.is-right, .hero.is-case-study .hero-content p.is-right, .text-auto-format p.is-right, .text-list.is-right, .text-auto-format ul.is-right, .text-meta.is-right, .mktoFormWrapper footer p.is-right, .aside p.is-right, .text-auto-format small.is-right, .text-meta-list.is-right {
  text-align: right;
}

.text-super-title.is-disabled, .text-jumbo-title.is-disabled, .text-large-title.is-disabled, .text-main-title.is-disabled, .hero.is-case-study .hero-content h1.is-disabled, .text-auto-format h2.is-disabled, .text-section-title.is-disabled, .text-auto-format h3.is-disabled, .text-info-title.is-disabled, .mktoFormWrapper h3.is-disabled, .aside h4.is-disabled, .text-auto-format h4.is-disabled, .text-meta-title.is-disabled, .aside h3.is-disabled, .text-auto-format h6.is-disabled, .text-auto-format h5.is-disabled, .text-paragraph.is-disabled, .hero.is-case-study .hero-content p.is-disabled, .text-auto-format p.is-disabled, .text-list.is-disabled, .text-auto-format ul.is-disabled, .text-meta.is-disabled, .mktoFormWrapper footer p.is-disabled, .aside p.is-disabled, .text-auto-format small.is-disabled, .text-meta-list.is-disabled {
  opacity: .24;
}

.text-super-title.is-brand, .text-jumbo-title.is-brand, .text-large-title.is-brand, .text-main-title.is-brand, .hero.is-case-study .hero-content h1.is-brand, .text-auto-format h2.is-brand, .text-section-title.is-brand, .text-auto-format h3.is-brand, .text-info-title.is-brand, .mktoFormWrapper h3.is-brand, .aside h4.is-brand, .text-auto-format h4.is-brand, .text-meta-title.is-brand, .aside h3.is-brand, .text-auto-format h6.is-brand, .text-auto-format h5.is-brand, .text-paragraph.is-brand, .hero.is-case-study .hero-content p.is-brand, .text-auto-format p.is-brand, .text-list.is-brand, .text-auto-format ul.is-brand, .text-meta.is-brand, .mktoFormWrapper footer p.is-brand, .aside p.is-brand, .text-auto-format small.is-brand, .text-meta-list.is-brand {
  color: #62138e;
}

.text-super-title.is-gradient, .text-jumbo-title.is-gradient, .text-large-title.is-gradient, .text-main-title.is-gradient, .hero.is-case-study .hero-content h1, .text-auto-format h2, .text-section-title.is-gradient, .text-auto-format h3.is-gradient, .text-info-title.is-gradient, .mktoFormWrapper h3.is-gradient, .aside h4, .text-auto-format h4.is-gradient, .text-meta-title.is-gradient, .aside h3.is-gradient, .text-auto-format h6.is-gradient, .text-auto-format h5.is-gradient, .text-paragraph.is-gradient, .hero.is-case-study .hero-content p.is-gradient, .text-auto-format p.is-gradient, .text-list.is-gradient, .text-auto-format ul.is-gradient, .text-meta.is-gradient, .mktoFormWrapper footer p.is-gradient, .aside p.is-gradient, .text-auto-format small.is-gradient, .text-meta-list.is-gradient {
  background: linear-gradient(-90deg, #5ba3b0 0%, #62138e 60%, #582f6b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-super-title.is-gradient-pink, .text-jumbo-title.is-gradient-pink, .text-large-title.is-gradient-pink, .text-main-title.is-gradient-pink, .hero.is-case-study .hero-content h1.is-gradient-pink, .text-auto-format h2.is-gradient-pink, .text-section-title.is-gradient-pink, .text-auto-format h3.is-gradient-pink, .text-info-title.is-gradient-pink, .mktoFormWrapper h3.is-gradient-pink, .aside h4.is-gradient-pink, .text-auto-format h4.is-gradient-pink, .text-meta-title.is-gradient-pink, .aside h3.is-gradient-pink, .text-auto-format h6.is-gradient-pink, .text-auto-format h5.is-gradient-pink, .text-paragraph.is-gradient-pink, .hero.is-case-study .hero-content p.is-gradient-pink, .text-auto-format p.is-gradient-pink, .text-list.is-gradient-pink, .text-auto-format ul.is-gradient-pink, .text-meta.is-gradient-pink, .mktoFormWrapper footer p.is-gradient-pink, .aside p.is-gradient-pink, .text-auto-format small.is-gradient-pink, .text-meta-list.is-gradient-pink {
  background: linear-gradient(-90deg, #db5b62, #a122cc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-super-title.is-light, .text-jumbo-title.is-light, .text-large-title.is-light, .text-main-title.is-light, .hero.is-case-study .hero-content h1.is-light, .text-auto-format h2.is-light, .text-section-title.is-light, .text-auto-format h3.is-light, .text-info-title.is-light, .mktoFormWrapper h3.is-light, .aside h4.is-light, .text-auto-format h4.is-light, .text-meta-title.is-light, .aside h3.is-light, .text-auto-format h6.is-light, .text-auto-format h5.is-light, .text-paragraph.is-light, .hero.is-case-study .hero-content p.is-light, .text-auto-format p.is-light, .text-list.is-light, .text-auto-format ul.is-light, .text-meta.is-light, .mktoFormWrapper footer p.is-light, .aside p.is-light, .text-auto-format small.is-light, .text-meta-list.is-light {
  color: #fff;
}

.text-super-title {
  margin-bottom: 32px;
  font-size: 124px;
  font-weight: 800;
  line-height: 132px;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .text-super-title {
    margin-bottom: 40px;
    font-size: 64px;
    font-weight: 700;
    line-height: 80px;
  }
}

.text-jumbo-title {
  margin-bottom: 40px;
  font-size: 70px;
  font-weight: 700;
  line-height: 80px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .text-jumbo-title {
    margin-bottom: 24px;
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
  }
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .text-jumbo-title {
    margin-bottom: 24px;
    font-size: 48px;
    font-weight: 700;
    line-height: 54px;
  }
}

.text-large-title {
  margin-bottom: 24px;
  font-size: 64px;
  font-weight: 800;
  line-height: 80px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .text-large-title {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 56px;
  }
}

.text-main-title, .hero.is-case-study .hero-content h1, .text-auto-format h2 {
  margin-bottom: 24px;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .text-main-title, .hero.is-case-study .hero-content h1, .text-auto-format h2 {
    margin-bottom: 24px;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
  }
}

.text-section-title, .text-auto-format h3 {
  margin-bottom: 24px;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
}

.text-info-title, .mktoFormWrapper h3, .aside h4, .text-auto-format h4 {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.text-meta-title, .aside h3, .text-auto-format h6, .text-auto-format h5 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
}

.text-paragraph, .hero.is-case-study .hero-content p, .text-auto-format p {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}

.text-list.is-plain, .text-auto-format ul.is-plain {
  margin: 0 0 32px;
  padding: 0;
  list-style-type: none;
}

.text-list li, .text-auto-format ul li {
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.text-list i, .text-auto-format ul i {
  margin-right: 8px;
}

.text-meta, .mktoFormWrapper footer p, .aside p, .text-auto-format small {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.text-meta-list {
  margin: 0 0 32px;
  padding: 0;
  list-style-type: none;
}

.text-meta-list li {
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.text-meta-list a {
  color: #0e0e0fde;
  font-weight: 400;
  text-decoration: none;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .text-meta-list a {
    color: #5ba3b0;
    background: #fff;
    border-radius: 8px;
    padding: 0 16px;
    line-height: 48px;
    display: block;
  }
}

.text-auto-format h3 {
  margin-top: 48px;
}

.text-auto-format ul i {
  color: #5ba3b0;
  margin-right: 8px;
  font-size: 16px;
}

[class~="grid"], [class*="grid-"], [class*="grid_"] {
  box-sizing: border-box;
  flex-flow: wrap;
  margin: 0 -8px;
  display: flex;
}

[class~="col"], [class*="col-"], [class*="col_"] {
  box-sizing: border-box;
  max-width: 100%;
  padding: 0 8px 16px;
}

[class~="col"], [class*="col_"] {
  flex: 1;
}

[class*="col-"] {
  flex: none;
}

[class~="grid"][class~="col"], [class~="grid"][class*="col-"], [class~="grid"][class*="col_"], [class*="grid-"][class~="col"], [class*="grid-"][class*="col-"], [class*="grid-"][class*="col_"], [class*="grid_"][class~="col"], [class*="grid_"][class*="col-"], [class*="grid_"][class*="col_"] {
  margin: 0;
  padding: 0;
}

[class*="grid-"][class*="-noGutter"] {
  margin: 0;
}

[class*="grid-"][class*="-noGutter"] > [class~="col"], [class*="grid-"][class*="-noGutter"] > [class*="col-"] {
  padding: 0;
}

[class*="grid-"][class*="-noWrap"] {
  flex-wrap: nowrap;
}

[class*="grid-"][class*="-center"] {
  justify-content: center;
}

[class*="grid-"][class*="-right"] {
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: auto;
}

[class*="grid-"][class*="-top"] {
  align-items: flex-start;
}

[class*="grid-"][class*="-middle"] {
  align-items: center;
}

[class*="grid-"][class*="-bottom"] {
  align-items: flex-end;
}

[class*="grid-"][class*="-reverse"] {
  flex-direction: row-reverse;
}

[class*="grid-"][class*="-column"] {
  flex-direction: column;
}

[class*="grid-"][class*="-column"] > [class*="col-"] {
  flex-basis: auto;
}

[class*="grid-"][class*="-column-reverse"] {
  flex-direction: column-reverse;
}

[class*="grid-"][class*="-spaceBetween"] {
  justify-content: space-between;
}

[class*="grid-"][class*="-spaceAround"] {
  justify-content: space-around;
}

[class*="grid-"][class*="-equalHeight"] > [class~="col"], [class*="grid-"][class*="-equalHeight"] > [class*="col-"], [class*="grid-"][class*="-equalHeight"] > [class*="col_"] {
  align-self: stretch;
}

[class*="grid-"][class*="-equalHeight"] > [class~="col"] > *, [class*="grid-"][class*="-equalHeight"] > [class*="col-"] > *, [class*="grid-"][class*="-equalHeight"] > [class*="col_"] > * {
  height: 100%;
}

[class*="grid-"][class*="-noBottom"] > [class~="col"], [class*="grid-"][class*="-noBottom"] > [class*="col-"], [class*="grid-"][class*="-noBottom"] > [class*="col_"] {
  padding-bottom: 0;
}

[class*="col-"][class*="-top"] {
  align-self: flex-start;
}

[class*="col-"][class*="-middle"] {
  align-self: center;
}

[class*="col-"][class*="-bottom"] {
  align-self: flex-end;
}

[class*="col-"][class*="-first"] {
  order: -1;
}

[class*="col-"][class*="-last"] {
  order: 1;
}

[class*="grid-1"] > [class~="col"], [class*="grid-1"] > [class*="col-"], [class*="grid-1"] > [class*="col_"] {
  max-width: 100%;
  flex-basis: 100%;
}

[class*="grid-2"] > [class~="col"], [class*="grid-2"] > [class*="col-"], [class*="grid-2"] > [class*="col_"] {
  max-width: 50%;
  flex-basis: 50%;
}

[class*="grid-3"] > [class~="col"], [class*="grid-3"] > [class*="col-"], [class*="grid-3"] > [class*="col_"] {
  max-width: 33.3333%;
  flex-basis: 33.3333%;
}

[class*="grid-4"] > [class~="col"], [class*="grid-4"] > [class*="col-"], [class*="grid-4"] > [class*="col_"] {
  max-width: 25%;
  flex-basis: 25%;
}

[class*="grid-5"] > [class~="col"], [class*="grid-5"] > [class*="col-"], [class*="grid-5"] > [class*="col_"] {
  max-width: 20%;
  flex-basis: 20%;
}

[class*="grid-6"] > [class~="col"], [class*="grid-6"] > [class*="col-"], [class*="grid-6"] > [class*="col_"] {
  max-width: 16.6667%;
  flex-basis: 16.6667%;
}

[class*="grid-7"] > [class~="col"], [class*="grid-7"] > [class*="col-"], [class*="grid-7"] > [class*="col_"] {
  max-width: 14.2857%;
  flex-basis: 14.2857%;
}

[class*="grid-8"] > [class~="col"], [class*="grid-8"] > [class*="col-"], [class*="grid-8"] > [class*="col_"] {
  max-width: 12.5%;
  flex-basis: 12.5%;
}

[class*="grid-9"] > [class~="col"], [class*="grid-9"] > [class*="col-"], [class*="grid-9"] > [class*="col_"] {
  max-width: 11.1111%;
  flex-basis: 11.1111%;
}

[class*="grid-10"] > [class~="col"], [class*="grid-10"] > [class*="col-"], [class*="grid-10"] > [class*="col_"] {
  max-width: 10%;
  flex-basis: 10%;
}

[class*="grid-11"] > [class~="col"], [class*="grid-11"] > [class*="col-"], [class*="grid-11"] > [class*="col_"] {
  max-width: 9.09091%;
  flex-basis: 9.09091%;
}

[class*="grid-12"] > [class~="col"], [class*="grid-12"] > [class*="col-"], [class*="grid-12"] > [class*="col_"] {
  max-width: 8.33333%;
  flex-basis: 8.33333%;
}

@media (max-width: 1400px) {
  [class*="_lg-1"] > [class~="col"], [class*="_lg-1"] > [class*="col-"], [class*="_lg-1"] > [class*="col_"] {
    max-width: 100%;
    flex-basis: 100%;
  }

  [class*="_lg-2"] > [class~="col"], [class*="_lg-2"] > [class*="col-"], [class*="_lg-2"] > [class*="col_"] {
    max-width: 50%;
    flex-basis: 50%;
  }

  [class*="_lg-3"] > [class~="col"], [class*="_lg-3"] > [class*="col-"], [class*="_lg-3"] > [class*="col_"] {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  [class*="_lg-4"] > [class~="col"], [class*="_lg-4"] > [class*="col-"], [class*="_lg-4"] > [class*="col_"] {
    max-width: 25%;
    flex-basis: 25%;
  }

  [class*="_lg-5"] > [class~="col"], [class*="_lg-5"] > [class*="col-"], [class*="_lg-5"] > [class*="col_"] {
    max-width: 20%;
    flex-basis: 20%;
  }

  [class*="_lg-6"] > [class~="col"], [class*="_lg-6"] > [class*="col-"], [class*="_lg-6"] > [class*="col_"] {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }

  [class*="_lg-7"] > [class~="col"], [class*="_lg-7"] > [class*="col-"], [class*="_lg-7"] > [class*="col_"] {
    max-width: 14.2857%;
    flex-basis: 14.2857%;
  }

  [class*="_lg-8"] > [class~="col"], [class*="_lg-8"] > [class*="col-"], [class*="_lg-8"] > [class*="col_"] {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }

  [class*="_lg-9"] > [class~="col"], [class*="_lg-9"] > [class*="col-"], [class*="_lg-9"] > [class*="col_"] {
    max-width: 11.1111%;
    flex-basis: 11.1111%;
  }

  [class*="_lg-10"] > [class~="col"], [class*="_lg-10"] > [class*="col-"], [class*="_lg-10"] > [class*="col_"] {
    max-width: 10%;
    flex-basis: 10%;
  }

  [class*="_lg-11"] > [class~="col"], [class*="_lg-11"] > [class*="col-"], [class*="_lg-11"] > [class*="col_"] {
    max-width: 9.09091%;
    flex-basis: 9.09091%;
  }

  [class*="_lg-12"] > [class~="col"], [class*="_lg-12"] > [class*="col-"], [class*="_lg-12"] > [class*="col_"] {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }
}

@media (max-width: 1260px) {
  [class*="_md-1"] > [class~="col"], [class*="_md-1"] > [class*="col-"], [class*="_md-1"] > [class*="col_"] {
    max-width: 100%;
    flex-basis: 100%;
  }

  [class*="_md-2"] > [class~="col"], [class*="_md-2"] > [class*="col-"], [class*="_md-2"] > [class*="col_"] {
    max-width: 50%;
    flex-basis: 50%;
  }

  [class*="_md-3"] > [class~="col"], [class*="_md-3"] > [class*="col-"], [class*="_md-3"] > [class*="col_"] {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  [class*="_md-4"] > [class~="col"], [class*="_md-4"] > [class*="col-"], [class*="_md-4"] > [class*="col_"] {
    max-width: 25%;
    flex-basis: 25%;
  }

  [class*="_md-5"] > [class~="col"], [class*="_md-5"] > [class*="col-"], [class*="_md-5"] > [class*="col_"] {
    max-width: 20%;
    flex-basis: 20%;
  }

  [class*="_md-6"] > [class~="col"], [class*="_md-6"] > [class*="col-"], [class*="_md-6"] > [class*="col_"] {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }

  [class*="_md-7"] > [class~="col"], [class*="_md-7"] > [class*="col-"], [class*="_md-7"] > [class*="col_"] {
    max-width: 14.2857%;
    flex-basis: 14.2857%;
  }

  [class*="_md-8"] > [class~="col"], [class*="_md-8"] > [class*="col-"], [class*="_md-8"] > [class*="col_"] {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }

  [class*="_md-9"] > [class~="col"], [class*="_md-9"] > [class*="col-"], [class*="_md-9"] > [class*="col_"] {
    max-width: 11.1111%;
    flex-basis: 11.1111%;
  }

  [class*="_md-10"] > [class~="col"], [class*="_md-10"] > [class*="col-"], [class*="_md-10"] > [class*="col_"] {
    max-width: 10%;
    flex-basis: 10%;
  }

  [class*="_md-11"] > [class~="col"], [class*="_md-11"] > [class*="col-"], [class*="_md-11"] > [class*="col_"] {
    max-width: 9.09091%;
    flex-basis: 9.09091%;
  }

  [class*="_md-12"] > [class~="col"], [class*="_md-12"] > [class*="col-"], [class*="_md-12"] > [class*="col_"] {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }
}

@media (max-width: 960px) {
  [class*="_sm-1"] > [class~="col"], [class*="_sm-1"] > [class*="col-"], [class*="_sm-1"] > [class*="col_"] {
    max-width: 100%;
    flex-basis: 100%;
  }

  [class*="_sm-2"] > [class~="col"], [class*="_sm-2"] > [class*="col-"], [class*="_sm-2"] > [class*="col_"] {
    max-width: 50%;
    flex-basis: 50%;
  }

  [class*="_sm-3"] > [class~="col"], [class*="_sm-3"] > [class*="col-"], [class*="_sm-3"] > [class*="col_"] {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  [class*="_sm-4"] > [class~="col"], [class*="_sm-4"] > [class*="col-"], [class*="_sm-4"] > [class*="col_"] {
    max-width: 25%;
    flex-basis: 25%;
  }

  [class*="_sm-5"] > [class~="col"], [class*="_sm-5"] > [class*="col-"], [class*="_sm-5"] > [class*="col_"] {
    max-width: 20%;
    flex-basis: 20%;
  }

  [class*="_sm-6"] > [class~="col"], [class*="_sm-6"] > [class*="col-"], [class*="_sm-6"] > [class*="col_"] {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }

  [class*="_sm-7"] > [class~="col"], [class*="_sm-7"] > [class*="col-"], [class*="_sm-7"] > [class*="col_"] {
    max-width: 14.2857%;
    flex-basis: 14.2857%;
  }

  [class*="_sm-8"] > [class~="col"], [class*="_sm-8"] > [class*="col-"], [class*="_sm-8"] > [class*="col_"] {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }

  [class*="_sm-9"] > [class~="col"], [class*="_sm-9"] > [class*="col-"], [class*="_sm-9"] > [class*="col_"] {
    max-width: 11.1111%;
    flex-basis: 11.1111%;
  }

  [class*="_sm-10"] > [class~="col"], [class*="_sm-10"] > [class*="col-"], [class*="_sm-10"] > [class*="col_"] {
    max-width: 10%;
    flex-basis: 10%;
  }

  [class*="_sm-11"] > [class~="col"], [class*="_sm-11"] > [class*="col-"], [class*="_sm-11"] > [class*="col_"] {
    max-width: 9.09091%;
    flex-basis: 9.09091%;
  }

  [class*="_sm-12"] > [class~="col"], [class*="_sm-12"] > [class*="col-"], [class*="_sm-12"] > [class*="col_"] {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }
}

@media (max-width: 800px) {
  [class*="_xs-1"] > [class~="col"], [class*="_xs-1"] > [class*="col-"], [class*="_xs-1"] > [class*="col_"] {
    max-width: 100%;
    flex-basis: 100%;
  }

  [class*="_xs-2"] > [class~="col"], [class*="_xs-2"] > [class*="col-"], [class*="_xs-2"] > [class*="col_"] {
    max-width: 50%;
    flex-basis: 50%;
  }

  [class*="_xs-3"] > [class~="col"], [class*="_xs-3"] > [class*="col-"], [class*="_xs-3"] > [class*="col_"] {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  [class*="_xs-4"] > [class~="col"], [class*="_xs-4"] > [class*="col-"], [class*="_xs-4"] > [class*="col_"] {
    max-width: 25%;
    flex-basis: 25%;
  }

  [class*="_xs-5"] > [class~="col"], [class*="_xs-5"] > [class*="col-"], [class*="_xs-5"] > [class*="col_"] {
    max-width: 20%;
    flex-basis: 20%;
  }

  [class*="_xs-6"] > [class~="col"], [class*="_xs-6"] > [class*="col-"], [class*="_xs-6"] > [class*="col_"] {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }

  [class*="_xs-7"] > [class~="col"], [class*="_xs-7"] > [class*="col-"], [class*="_xs-7"] > [class*="col_"] {
    max-width: 14.2857%;
    flex-basis: 14.2857%;
  }

  [class*="_xs-8"] > [class~="col"], [class*="_xs-8"] > [class*="col-"], [class*="_xs-8"] > [class*="col_"] {
    max-width: 12.5%;
    flex-basis: 12.5%;
  }

  [class*="_xs-9"] > [class~="col"], [class*="_xs-9"] > [class*="col-"], [class*="_xs-9"] > [class*="col_"] {
    max-width: 11.1111%;
    flex-basis: 11.1111%;
  }

  [class*="_xs-10"] > [class~="col"], [class*="_xs-10"] > [class*="col-"], [class*="_xs-10"] > [class*="col_"] {
    max-width: 10%;
    flex-basis: 10%;
  }

  [class*="_xs-11"] > [class~="col"], [class*="_xs-11"] > [class*="col-"], [class*="_xs-11"] > [class*="col_"] {
    max-width: 9.09091%;
    flex-basis: 9.09091%;
  }

  [class*="_xs-12"] > [class~="col"], [class*="_xs-12"] > [class*="col-"], [class*="_xs-12"] > [class*="col_"] {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }
}

[class~="grid"] > [class*="col-1"], [class*="grid-"] > [class*="col-1"], [class*="grid_"] > [class*="col-1"] {
  max-width: 8.33333%;
  flex-basis: 8.33333%;
}

[class~="grid"] > [class*="col-2"], [class*="grid-"] > [class*="col-2"], [class*="grid_"] > [class*="col-2"] {
  max-width: 16.6667%;
  flex-basis: 16.6667%;
}

[class~="grid"] > [class*="col-3"], [class*="grid-"] > [class*="col-3"], [class*="grid_"] > [class*="col-3"] {
  max-width: 25%;
  flex-basis: 25%;
}

[class~="grid"] > [class*="col-4"], [class*="grid-"] > [class*="col-4"], [class*="grid_"] > [class*="col-4"] {
  max-width: 33.3333%;
  flex-basis: 33.3333%;
}

[class~="grid"] > [class*="col-5"], [class*="grid-"] > [class*="col-5"], [class*="grid_"] > [class*="col-5"] {
  max-width: 41.6667%;
  flex-basis: 41.6667%;
}

[class~="grid"] > [class*="col-6"], [class*="grid-"] > [class*="col-6"], [class*="grid_"] > [class*="col-6"] {
  max-width: 50%;
  flex-basis: 50%;
}

[class~="grid"] > [class*="col-7"], [class*="grid-"] > [class*="col-7"], [class*="grid_"] > [class*="col-7"] {
  max-width: 58.3333%;
  flex-basis: 58.3333%;
}

[class~="grid"] > [class*="col-8"], [class*="grid-"] > [class*="col-8"], [class*="grid_"] > [class*="col-8"] {
  max-width: 66.6667%;
  flex-basis: 66.6667%;
}

[class~="grid"] > [class*="col-9"], [class*="grid-"] > [class*="col-9"], [class*="grid_"] > [class*="col-9"] {
  max-width: 75%;
  flex-basis: 75%;
}

[class~="grid"] > [class*="col-10"], [class*="grid-"] > [class*="col-10"], [class*="grid_"] > [class*="col-10"] {
  max-width: 83.3333%;
  flex-basis: 83.3333%;
}

[class~="grid"] > [class*="col-11"], [class*="grid-"] > [class*="col-11"], [class*="grid_"] > [class*="col-11"] {
  max-width: 91.6667%;
  flex-basis: 91.6667%;
}

[class~="grid"] > [class*="col-12"], [class*="grid-"] > [class*="col-12"], [class*="grid_"] > [class*="col-12"] {
  max-width: 100%;
  flex-basis: 100%;
}

[class~="grid"] > [data-push-left*="off-0"], [class*="grid-"] > [data-push-left*="off-0"], [class*="grid_"] > [data-push-left*="off-0"] {
  margin-left: 0;
}

[class~="grid"] > [data-push-left*="off-1"], [class*="grid-"] > [data-push-left*="off-1"], [class*="grid_"] > [data-push-left*="off-1"] {
  margin-left: 8.33333%;
}

[class~="grid"] > [data-push-left*="off-2"], [class*="grid-"] > [data-push-left*="off-2"], [class*="grid_"] > [data-push-left*="off-2"] {
  margin-left: 16.6667%;
}

[class~="grid"] > [data-push-left*="off-3"], [class*="grid-"] > [data-push-left*="off-3"], [class*="grid_"] > [data-push-left*="off-3"] {
  margin-left: 25%;
}

[class~="grid"] > [data-push-left*="off-4"], [class*="grid-"] > [data-push-left*="off-4"], [class*="grid_"] > [data-push-left*="off-4"] {
  margin-left: 33.3333%;
}

[class~="grid"] > [data-push-left*="off-5"], [class*="grid-"] > [data-push-left*="off-5"], [class*="grid_"] > [data-push-left*="off-5"] {
  margin-left: 41.6667%;
}

[class~="grid"] > [data-push-left*="off-6"], [class*="grid-"] > [data-push-left*="off-6"], [class*="grid_"] > [data-push-left*="off-6"] {
  margin-left: 50%;
}

[class~="grid"] > [data-push-left*="off-7"], [class*="grid-"] > [data-push-left*="off-7"], [class*="grid_"] > [data-push-left*="off-7"] {
  margin-left: 58.3333%;
}

[class~="grid"] > [data-push-left*="off-8"], [class*="grid-"] > [data-push-left*="off-8"], [class*="grid_"] > [data-push-left*="off-8"] {
  margin-left: 66.6667%;
}

[class~="grid"] > [data-push-left*="off-9"], [class*="grid-"] > [data-push-left*="off-9"], [class*="grid_"] > [data-push-left*="off-9"] {
  margin-left: 75%;
}

[class~="grid"] > [data-push-left*="off-10"], [class*="grid-"] > [data-push-left*="off-10"], [class*="grid_"] > [data-push-left*="off-10"] {
  margin-left: 83.3333%;
}

[class~="grid"] > [data-push-left*="off-11"], [class*="grid-"] > [data-push-left*="off-11"], [class*="grid_"] > [data-push-left*="off-11"] {
  margin-left: 91.6667%;
}

[class~="grid"] > [data-push-right*="off-0"], [class*="grid-"] > [data-push-right*="off-0"], [class*="grid_"] > [data-push-right*="off-0"] {
  margin-right: 0;
}

[class~="grid"] > [data-push-right*="off-1"], [class*="grid-"] > [data-push-right*="off-1"], [class*="grid_"] > [data-push-right*="off-1"] {
  margin-right: 8.33333%;
}

[class~="grid"] > [data-push-right*="off-2"], [class*="grid-"] > [data-push-right*="off-2"], [class*="grid_"] > [data-push-right*="off-2"] {
  margin-right: 16.6667%;
}

[class~="grid"] > [data-push-right*="off-3"], [class*="grid-"] > [data-push-right*="off-3"], [class*="grid_"] > [data-push-right*="off-3"] {
  margin-right: 25%;
}

[class~="grid"] > [data-push-right*="off-4"], [class*="grid-"] > [data-push-right*="off-4"], [class*="grid_"] > [data-push-right*="off-4"] {
  margin-right: 33.3333%;
}

[class~="grid"] > [data-push-right*="off-5"], [class*="grid-"] > [data-push-right*="off-5"], [class*="grid_"] > [data-push-right*="off-5"] {
  margin-right: 41.6667%;
}

[class~="grid"] > [data-push-right*="off-6"], [class*="grid-"] > [data-push-right*="off-6"], [class*="grid_"] > [data-push-right*="off-6"] {
  margin-right: 50%;
}

[class~="grid"] > [data-push-right*="off-7"], [class*="grid-"] > [data-push-right*="off-7"], [class*="grid_"] > [data-push-right*="off-7"] {
  margin-right: 58.3333%;
}

[class~="grid"] > [data-push-right*="off-8"], [class*="grid-"] > [data-push-right*="off-8"], [class*="grid_"] > [data-push-right*="off-8"] {
  margin-right: 66.6667%;
}

[class~="grid"] > [data-push-right*="off-9"], [class*="grid-"] > [data-push-right*="off-9"], [class*="grid_"] > [data-push-right*="off-9"] {
  margin-right: 75%;
}

[class~="grid"] > [data-push-right*="off-10"], [class*="grid-"] > [data-push-right*="off-10"], [class*="grid_"] > [data-push-right*="off-10"] {
  margin-right: 83.3333%;
}

[class~="grid"] > [data-push-right*="off-11"], [class*="grid-"] > [data-push-right*="off-11"], [class*="grid_"] > [data-push-right*="off-11"] {
  margin-right: 91.6667%;
}

@media (max-width: 1400px) {
  [class~="grid"] > [class*="_lg-1"], [class*="grid-"] > [class*="_lg-1"], [class*="grid_"] > [class*="_lg-1"] {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }

  [class~="grid"] > [class*="_lg-2"], [class*="grid-"] > [class*="_lg-2"], [class*="grid_"] > [class*="_lg-2"] {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }

  [class~="grid"] > [class*="_lg-3"], [class*="grid-"] > [class*="_lg-3"], [class*="grid_"] > [class*="_lg-3"] {
    max-width: 25%;
    flex-basis: 25%;
  }

  [class~="grid"] > [class*="_lg-4"], [class*="grid-"] > [class*="_lg-4"], [class*="grid_"] > [class*="_lg-4"] {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  [class~="grid"] > [class*="_lg-5"], [class*="grid-"] > [class*="_lg-5"], [class*="grid_"] > [class*="_lg-5"] {
    max-width: 41.6667%;
    flex-basis: 41.6667%;
  }

  [class~="grid"] > [class*="_lg-6"], [class*="grid-"] > [class*="_lg-6"], [class*="grid_"] > [class*="_lg-6"] {
    max-width: 50%;
    flex-basis: 50%;
  }

  [class~="grid"] > [class*="_lg-7"], [class*="grid-"] > [class*="_lg-7"], [class*="grid_"] > [class*="_lg-7"] {
    max-width: 58.3333%;
    flex-basis: 58.3333%;
  }

  [class~="grid"] > [class*="_lg-8"], [class*="grid-"] > [class*="_lg-8"], [class*="grid_"] > [class*="_lg-8"] {
    max-width: 66.6667%;
    flex-basis: 66.6667%;
  }

  [class~="grid"] > [class*="_lg-9"], [class*="grid-"] > [class*="_lg-9"], [class*="grid_"] > [class*="_lg-9"] {
    max-width: 75%;
    flex-basis: 75%;
  }

  [class~="grid"] > [class*="_lg-10"], [class*="grid-"] > [class*="_lg-10"], [class*="grid_"] > [class*="_lg-10"] {
    max-width: 83.3333%;
    flex-basis: 83.3333%;
  }

  [class~="grid"] > [class*="_lg-11"], [class*="grid-"] > [class*="_lg-11"], [class*="grid_"] > [class*="_lg-11"] {
    max-width: 91.6667%;
    flex-basis: 91.6667%;
  }

  [class~="grid"] > [class*="_lg-12"], [class*="grid-"] > [class*="_lg-12"], [class*="grid_"] > [class*="_lg-12"] {
    max-width: 100%;
    flex-basis: 100%;
  }

  [class~="grid"] > [data-push-left*="_lg-0"], [class*="grid-"] > [data-push-left*="_lg-0"], [class*="grid_"] > [data-push-left*="_lg-0"] {
    margin-left: 0;
  }

  [class~="grid"] > [data-push-left*="_lg-1"], [class*="grid-"] > [data-push-left*="_lg-1"], [class*="grid_"] > [data-push-left*="_lg-1"] {
    margin-left: 8.33333%;
  }

  [class~="grid"] > [data-push-left*="_lg-2"], [class*="grid-"] > [data-push-left*="_lg-2"], [class*="grid_"] > [data-push-left*="_lg-2"] {
    margin-left: 16.6667%;
  }

  [class~="grid"] > [data-push-left*="_lg-3"], [class*="grid-"] > [data-push-left*="_lg-3"], [class*="grid_"] > [data-push-left*="_lg-3"] {
    margin-left: 25%;
  }

  [class~="grid"] > [data-push-left*="_lg-4"], [class*="grid-"] > [data-push-left*="_lg-4"], [class*="grid_"] > [data-push-left*="_lg-4"] {
    margin-left: 33.3333%;
  }

  [class~="grid"] > [data-push-left*="_lg-5"], [class*="grid-"] > [data-push-left*="_lg-5"], [class*="grid_"] > [data-push-left*="_lg-5"] {
    margin-left: 41.6667%;
  }

  [class~="grid"] > [data-push-left*="_lg-6"], [class*="grid-"] > [data-push-left*="_lg-6"], [class*="grid_"] > [data-push-left*="_lg-6"] {
    margin-left: 50%;
  }

  [class~="grid"] > [data-push-left*="_lg-7"], [class*="grid-"] > [data-push-left*="_lg-7"], [class*="grid_"] > [data-push-left*="_lg-7"] {
    margin-left: 58.3333%;
  }

  [class~="grid"] > [data-push-left*="_lg-8"], [class*="grid-"] > [data-push-left*="_lg-8"], [class*="grid_"] > [data-push-left*="_lg-8"] {
    margin-left: 66.6667%;
  }

  [class~="grid"] > [data-push-left*="_lg-9"], [class*="grid-"] > [data-push-left*="_lg-9"], [class*="grid_"] > [data-push-left*="_lg-9"] {
    margin-left: 75%;
  }

  [class~="grid"] > [data-push-left*="_lg-10"], [class*="grid-"] > [data-push-left*="_lg-10"], [class*="grid_"] > [data-push-left*="_lg-10"] {
    margin-left: 83.3333%;
  }

  [class~="grid"] > [data-push-left*="_lg-11"], [class*="grid-"] > [data-push-left*="_lg-11"], [class*="grid_"] > [data-push-left*="_lg-11"] {
    margin-left: 91.6667%;
  }

  [class~="grid"] > [data-push-right*="_lg-0"], [class*="grid-"] > [data-push-right*="_lg-0"], [class*="grid_"] > [data-push-right*="_lg-0"] {
    margin-right: 0;
  }

  [class~="grid"] > [data-push-right*="_lg-1"], [class*="grid-"] > [data-push-right*="_lg-1"], [class*="grid_"] > [data-push-right*="_lg-1"] {
    margin-right: 8.33333%;
  }

  [class~="grid"] > [data-push-right*="_lg-2"], [class*="grid-"] > [data-push-right*="_lg-2"], [class*="grid_"] > [data-push-right*="_lg-2"] {
    margin-right: 16.6667%;
  }

  [class~="grid"] > [data-push-right*="_lg-3"], [class*="grid-"] > [data-push-right*="_lg-3"], [class*="grid_"] > [data-push-right*="_lg-3"] {
    margin-right: 25%;
  }

  [class~="grid"] > [data-push-right*="_lg-4"], [class*="grid-"] > [data-push-right*="_lg-4"], [class*="grid_"] > [data-push-right*="_lg-4"] {
    margin-right: 33.3333%;
  }

  [class~="grid"] > [data-push-right*="_lg-5"], [class*="grid-"] > [data-push-right*="_lg-5"], [class*="grid_"] > [data-push-right*="_lg-5"] {
    margin-right: 41.6667%;
  }

  [class~="grid"] > [data-push-right*="_lg-6"], [class*="grid-"] > [data-push-right*="_lg-6"], [class*="grid_"] > [data-push-right*="_lg-6"] {
    margin-right: 50%;
  }

  [class~="grid"] > [data-push-right*="_lg-7"], [class*="grid-"] > [data-push-right*="_lg-7"], [class*="grid_"] > [data-push-right*="_lg-7"] {
    margin-right: 58.3333%;
  }

  [class~="grid"] > [data-push-right*="_lg-8"], [class*="grid-"] > [data-push-right*="_lg-8"], [class*="grid_"] > [data-push-right*="_lg-8"] {
    margin-right: 66.6667%;
  }

  [class~="grid"] > [data-push-right*="_lg-9"], [class*="grid-"] > [data-push-right*="_lg-9"], [class*="grid_"] > [data-push-right*="_lg-9"] {
    margin-right: 75%;
  }

  [class~="grid"] > [data-push-right*="_lg-10"], [class*="grid-"] > [data-push-right*="_lg-10"], [class*="grid_"] > [data-push-right*="_lg-10"] {
    margin-right: 83.3333%;
  }

  [class~="grid"] > [data-push-right*="_lg-11"], [class*="grid-"] > [data-push-right*="_lg-11"], [class*="grid_"] > [data-push-right*="_lg-11"] {
    margin-right: 91.6667%;
  }

  [class~="grid"] [class*="_lg-first"], [class*="grid-"] [class*="_lg-first"], [class*="grid_"] [class*="_lg-first"] {
    order: -1;
  }

  [class~="grid"] [class*="_lg-last"], [class*="grid-"] [class*="_lg-last"], [class*="grid_"] [class*="_lg-last"] {
    order: 1;
  }
}

@media (max-width: 1260px) {
  [class~="grid"] > [class*="_md-1"], [class*="grid-"] > [class*="_md-1"], [class*="grid_"] > [class*="_md-1"] {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }

  [class~="grid"] > [class*="_md-2"], [class*="grid-"] > [class*="_md-2"], [class*="grid_"] > [class*="_md-2"] {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }

  [class~="grid"] > [class*="_md-3"], [class*="grid-"] > [class*="_md-3"], [class*="grid_"] > [class*="_md-3"] {
    max-width: 25%;
    flex-basis: 25%;
  }

  [class~="grid"] > [class*="_md-4"], [class*="grid-"] > [class*="_md-4"], [class*="grid_"] > [class*="_md-4"] {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  [class~="grid"] > [class*="_md-5"], [class*="grid-"] > [class*="_md-5"], [class*="grid_"] > [class*="_md-5"] {
    max-width: 41.6667%;
    flex-basis: 41.6667%;
  }

  [class~="grid"] > [class*="_md-6"], [class*="grid-"] > [class*="_md-6"], [class*="grid_"] > [class*="_md-6"] {
    max-width: 50%;
    flex-basis: 50%;
  }

  [class~="grid"] > [class*="_md-7"], [class*="grid-"] > [class*="_md-7"], [class*="grid_"] > [class*="_md-7"] {
    max-width: 58.3333%;
    flex-basis: 58.3333%;
  }

  [class~="grid"] > [class*="_md-8"], [class*="grid-"] > [class*="_md-8"], [class*="grid_"] > [class*="_md-8"] {
    max-width: 66.6667%;
    flex-basis: 66.6667%;
  }

  [class~="grid"] > [class*="_md-9"], [class*="grid-"] > [class*="_md-9"], [class*="grid_"] > [class*="_md-9"] {
    max-width: 75%;
    flex-basis: 75%;
  }

  [class~="grid"] > [class*="_md-10"], [class*="grid-"] > [class*="_md-10"], [class*="grid_"] > [class*="_md-10"] {
    max-width: 83.3333%;
    flex-basis: 83.3333%;
  }

  [class~="grid"] > [class*="_md-11"], [class*="grid-"] > [class*="_md-11"], [class*="grid_"] > [class*="_md-11"] {
    max-width: 91.6667%;
    flex-basis: 91.6667%;
  }

  [class~="grid"] > [class*="_md-12"], [class*="grid-"] > [class*="_md-12"], [class*="grid_"] > [class*="_md-12"] {
    max-width: 100%;
    flex-basis: 100%;
  }

  [class~="grid"] > [data-push-left*="_md-0"], [class*="grid-"] > [data-push-left*="_md-0"], [class*="grid_"] > [data-push-left*="_md-0"] {
    margin-left: 0;
  }

  [class~="grid"] > [data-push-left*="_md-1"], [class*="grid-"] > [data-push-left*="_md-1"], [class*="grid_"] > [data-push-left*="_md-1"] {
    margin-left: 8.33333%;
  }

  [class~="grid"] > [data-push-left*="_md-2"], [class*="grid-"] > [data-push-left*="_md-2"], [class*="grid_"] > [data-push-left*="_md-2"] {
    margin-left: 16.6667%;
  }

  [class~="grid"] > [data-push-left*="_md-3"], [class*="grid-"] > [data-push-left*="_md-3"], [class*="grid_"] > [data-push-left*="_md-3"] {
    margin-left: 25%;
  }

  [class~="grid"] > [data-push-left*="_md-4"], [class*="grid-"] > [data-push-left*="_md-4"], [class*="grid_"] > [data-push-left*="_md-4"] {
    margin-left: 33.3333%;
  }

  [class~="grid"] > [data-push-left*="_md-5"], [class*="grid-"] > [data-push-left*="_md-5"], [class*="grid_"] > [data-push-left*="_md-5"] {
    margin-left: 41.6667%;
  }

  [class~="grid"] > [data-push-left*="_md-6"], [class*="grid-"] > [data-push-left*="_md-6"], [class*="grid_"] > [data-push-left*="_md-6"] {
    margin-left: 50%;
  }

  [class~="grid"] > [data-push-left*="_md-7"], [class*="grid-"] > [data-push-left*="_md-7"], [class*="grid_"] > [data-push-left*="_md-7"] {
    margin-left: 58.3333%;
  }

  [class~="grid"] > [data-push-left*="_md-8"], [class*="grid-"] > [data-push-left*="_md-8"], [class*="grid_"] > [data-push-left*="_md-8"] {
    margin-left: 66.6667%;
  }

  [class~="grid"] > [data-push-left*="_md-9"], [class*="grid-"] > [data-push-left*="_md-9"], [class*="grid_"] > [data-push-left*="_md-9"] {
    margin-left: 75%;
  }

  [class~="grid"] > [data-push-left*="_md-10"], [class*="grid-"] > [data-push-left*="_md-10"], [class*="grid_"] > [data-push-left*="_md-10"] {
    margin-left: 83.3333%;
  }

  [class~="grid"] > [data-push-left*="_md-11"], [class*="grid-"] > [data-push-left*="_md-11"], [class*="grid_"] > [data-push-left*="_md-11"] {
    margin-left: 91.6667%;
  }

  [class~="grid"] > [data-push-right*="_md-0"], [class*="grid-"] > [data-push-right*="_md-0"], [class*="grid_"] > [data-push-right*="_md-0"] {
    margin-right: 0;
  }

  [class~="grid"] > [data-push-right*="_md-1"], [class*="grid-"] > [data-push-right*="_md-1"], [class*="grid_"] > [data-push-right*="_md-1"] {
    margin-right: 8.33333%;
  }

  [class~="grid"] > [data-push-right*="_md-2"], [class*="grid-"] > [data-push-right*="_md-2"], [class*="grid_"] > [data-push-right*="_md-2"] {
    margin-right: 16.6667%;
  }

  [class~="grid"] > [data-push-right*="_md-3"], [class*="grid-"] > [data-push-right*="_md-3"], [class*="grid_"] > [data-push-right*="_md-3"] {
    margin-right: 25%;
  }

  [class~="grid"] > [data-push-right*="_md-4"], [class*="grid-"] > [data-push-right*="_md-4"], [class*="grid_"] > [data-push-right*="_md-4"] {
    margin-right: 33.3333%;
  }

  [class~="grid"] > [data-push-right*="_md-5"], [class*="grid-"] > [data-push-right*="_md-5"], [class*="grid_"] > [data-push-right*="_md-5"] {
    margin-right: 41.6667%;
  }

  [class~="grid"] > [data-push-right*="_md-6"], [class*="grid-"] > [data-push-right*="_md-6"], [class*="grid_"] > [data-push-right*="_md-6"] {
    margin-right: 50%;
  }

  [class~="grid"] > [data-push-right*="_md-7"], [class*="grid-"] > [data-push-right*="_md-7"], [class*="grid_"] > [data-push-right*="_md-7"] {
    margin-right: 58.3333%;
  }

  [class~="grid"] > [data-push-right*="_md-8"], [class*="grid-"] > [data-push-right*="_md-8"], [class*="grid_"] > [data-push-right*="_md-8"] {
    margin-right: 66.6667%;
  }

  [class~="grid"] > [data-push-right*="_md-9"], [class*="grid-"] > [data-push-right*="_md-9"], [class*="grid_"] > [data-push-right*="_md-9"] {
    margin-right: 75%;
  }

  [class~="grid"] > [data-push-right*="_md-10"], [class*="grid-"] > [data-push-right*="_md-10"], [class*="grid_"] > [data-push-right*="_md-10"] {
    margin-right: 83.3333%;
  }

  [class~="grid"] > [data-push-right*="_md-11"], [class*="grid-"] > [data-push-right*="_md-11"], [class*="grid_"] > [data-push-right*="_md-11"] {
    margin-right: 91.6667%;
  }

  [class~="grid"] [class*="_md-first"], [class*="grid-"] [class*="_md-first"], [class*="grid_"] [class*="_md-first"] {
    order: -1;
  }

  [class~="grid"] [class*="_md-last"], [class*="grid-"] [class*="_md-last"], [class*="grid_"] [class*="_md-last"] {
    order: 1;
  }
}

@media (max-width: 960px) {
  [class~="grid"] > [class*="_sm-1"], [class*="grid-"] > [class*="_sm-1"], [class*="grid_"] > [class*="_sm-1"] {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }

  [class~="grid"] > [class*="_sm-2"], [class*="grid-"] > [class*="_sm-2"], [class*="grid_"] > [class*="_sm-2"] {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }

  [class~="grid"] > [class*="_sm-3"], [class*="grid-"] > [class*="_sm-3"], [class*="grid_"] > [class*="_sm-3"] {
    max-width: 25%;
    flex-basis: 25%;
  }

  [class~="grid"] > [class*="_sm-4"], [class*="grid-"] > [class*="_sm-4"], [class*="grid_"] > [class*="_sm-4"] {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  [class~="grid"] > [class*="_sm-5"], [class*="grid-"] > [class*="_sm-5"], [class*="grid_"] > [class*="_sm-5"] {
    max-width: 41.6667%;
    flex-basis: 41.6667%;
  }

  [class~="grid"] > [class*="_sm-6"], [class*="grid-"] > [class*="_sm-6"], [class*="grid_"] > [class*="_sm-6"] {
    max-width: 50%;
    flex-basis: 50%;
  }

  [class~="grid"] > [class*="_sm-7"], [class*="grid-"] > [class*="_sm-7"], [class*="grid_"] > [class*="_sm-7"] {
    max-width: 58.3333%;
    flex-basis: 58.3333%;
  }

  [class~="grid"] > [class*="_sm-8"], [class*="grid-"] > [class*="_sm-8"], [class*="grid_"] > [class*="_sm-8"] {
    max-width: 66.6667%;
    flex-basis: 66.6667%;
  }

  [class~="grid"] > [class*="_sm-9"], [class*="grid-"] > [class*="_sm-9"], [class*="grid_"] > [class*="_sm-9"] {
    max-width: 75%;
    flex-basis: 75%;
  }

  [class~="grid"] > [class*="_sm-10"], [class*="grid-"] > [class*="_sm-10"], [class*="grid_"] > [class*="_sm-10"] {
    max-width: 83.3333%;
    flex-basis: 83.3333%;
  }

  [class~="grid"] > [class*="_sm-11"], [class*="grid-"] > [class*="_sm-11"], [class*="grid_"] > [class*="_sm-11"] {
    max-width: 91.6667%;
    flex-basis: 91.6667%;
  }

  [class~="grid"] > [class*="_sm-12"], [class*="grid-"] > [class*="_sm-12"], [class*="grid_"] > [class*="_sm-12"] {
    max-width: 100%;
    flex-basis: 100%;
  }

  [class~="grid"] > [data-push-left*="_sm-0"], [class*="grid-"] > [data-push-left*="_sm-0"], [class*="grid_"] > [data-push-left*="_sm-0"] {
    margin-left: 0;
  }

  [class~="grid"] > [data-push-left*="_sm-1"], [class*="grid-"] > [data-push-left*="_sm-1"], [class*="grid_"] > [data-push-left*="_sm-1"] {
    margin-left: 8.33333%;
  }

  [class~="grid"] > [data-push-left*="_sm-2"], [class*="grid-"] > [data-push-left*="_sm-2"], [class*="grid_"] > [data-push-left*="_sm-2"] {
    margin-left: 16.6667%;
  }

  [class~="grid"] > [data-push-left*="_sm-3"], [class*="grid-"] > [data-push-left*="_sm-3"], [class*="grid_"] > [data-push-left*="_sm-3"] {
    margin-left: 25%;
  }

  [class~="grid"] > [data-push-left*="_sm-4"], [class*="grid-"] > [data-push-left*="_sm-4"], [class*="grid_"] > [data-push-left*="_sm-4"] {
    margin-left: 33.3333%;
  }

  [class~="grid"] > [data-push-left*="_sm-5"], [class*="grid-"] > [data-push-left*="_sm-5"], [class*="grid_"] > [data-push-left*="_sm-5"] {
    margin-left: 41.6667%;
  }

  [class~="grid"] > [data-push-left*="_sm-6"], [class*="grid-"] > [data-push-left*="_sm-6"], [class*="grid_"] > [data-push-left*="_sm-6"] {
    margin-left: 50%;
  }

  [class~="grid"] > [data-push-left*="_sm-7"], [class*="grid-"] > [data-push-left*="_sm-7"], [class*="grid_"] > [data-push-left*="_sm-7"] {
    margin-left: 58.3333%;
  }

  [class~="grid"] > [data-push-left*="_sm-8"], [class*="grid-"] > [data-push-left*="_sm-8"], [class*="grid_"] > [data-push-left*="_sm-8"] {
    margin-left: 66.6667%;
  }

  [class~="grid"] > [data-push-left*="_sm-9"], [class*="grid-"] > [data-push-left*="_sm-9"], [class*="grid_"] > [data-push-left*="_sm-9"] {
    margin-left: 75%;
  }

  [class~="grid"] > [data-push-left*="_sm-10"], [class*="grid-"] > [data-push-left*="_sm-10"], [class*="grid_"] > [data-push-left*="_sm-10"] {
    margin-left: 83.3333%;
  }

  [class~="grid"] > [data-push-left*="_sm-11"], [class*="grid-"] > [data-push-left*="_sm-11"], [class*="grid_"] > [data-push-left*="_sm-11"] {
    margin-left: 91.6667%;
  }

  [class~="grid"] > [data-push-right*="_sm-0"], [class*="grid-"] > [data-push-right*="_sm-0"], [class*="grid_"] > [data-push-right*="_sm-0"] {
    margin-right: 0;
  }

  [class~="grid"] > [data-push-right*="_sm-1"], [class*="grid-"] > [data-push-right*="_sm-1"], [class*="grid_"] > [data-push-right*="_sm-1"] {
    margin-right: 8.33333%;
  }

  [class~="grid"] > [data-push-right*="_sm-2"], [class*="grid-"] > [data-push-right*="_sm-2"], [class*="grid_"] > [data-push-right*="_sm-2"] {
    margin-right: 16.6667%;
  }

  [class~="grid"] > [data-push-right*="_sm-3"], [class*="grid-"] > [data-push-right*="_sm-3"], [class*="grid_"] > [data-push-right*="_sm-3"] {
    margin-right: 25%;
  }

  [class~="grid"] > [data-push-right*="_sm-4"], [class*="grid-"] > [data-push-right*="_sm-4"], [class*="grid_"] > [data-push-right*="_sm-4"] {
    margin-right: 33.3333%;
  }

  [class~="grid"] > [data-push-right*="_sm-5"], [class*="grid-"] > [data-push-right*="_sm-5"], [class*="grid_"] > [data-push-right*="_sm-5"] {
    margin-right: 41.6667%;
  }

  [class~="grid"] > [data-push-right*="_sm-6"], [class*="grid-"] > [data-push-right*="_sm-6"], [class*="grid_"] > [data-push-right*="_sm-6"] {
    margin-right: 50%;
  }

  [class~="grid"] > [data-push-right*="_sm-7"], [class*="grid-"] > [data-push-right*="_sm-7"], [class*="grid_"] > [data-push-right*="_sm-7"] {
    margin-right: 58.3333%;
  }

  [class~="grid"] > [data-push-right*="_sm-8"], [class*="grid-"] > [data-push-right*="_sm-8"], [class*="grid_"] > [data-push-right*="_sm-8"] {
    margin-right: 66.6667%;
  }

  [class~="grid"] > [data-push-right*="_sm-9"], [class*="grid-"] > [data-push-right*="_sm-9"], [class*="grid_"] > [data-push-right*="_sm-9"] {
    margin-right: 75%;
  }

  [class~="grid"] > [data-push-right*="_sm-10"], [class*="grid-"] > [data-push-right*="_sm-10"], [class*="grid_"] > [data-push-right*="_sm-10"] {
    margin-right: 83.3333%;
  }

  [class~="grid"] > [data-push-right*="_sm-11"], [class*="grid-"] > [data-push-right*="_sm-11"], [class*="grid_"] > [data-push-right*="_sm-11"] {
    margin-right: 91.6667%;
  }

  [class~="grid"] [class*="_sm-first"], [class*="grid-"] [class*="_sm-first"], [class*="grid_"] [class*="_sm-first"] {
    order: -1;
  }

  [class~="grid"] [class*="_sm-last"], [class*="grid-"] [class*="_sm-last"], [class*="grid_"] [class*="_sm-last"] {
    order: 1;
  }
}

@media (max-width: 800px) {
  [class~="grid"] > [class*="_xs-1"], [class*="grid-"] > [class*="_xs-1"], [class*="grid_"] > [class*="_xs-1"] {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }

  [class~="grid"] > [class*="_xs-2"], [class*="grid-"] > [class*="_xs-2"], [class*="grid_"] > [class*="_xs-2"] {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }

  [class~="grid"] > [class*="_xs-3"], [class*="grid-"] > [class*="_xs-3"], [class*="grid_"] > [class*="_xs-3"] {
    max-width: 25%;
    flex-basis: 25%;
  }

  [class~="grid"] > [class*="_xs-4"], [class*="grid-"] > [class*="_xs-4"], [class*="grid_"] > [class*="_xs-4"] {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  [class~="grid"] > [class*="_xs-5"], [class*="grid-"] > [class*="_xs-5"], [class*="grid_"] > [class*="_xs-5"] {
    max-width: 41.6667%;
    flex-basis: 41.6667%;
  }

  [class~="grid"] > [class*="_xs-6"], [class*="grid-"] > [class*="_xs-6"], [class*="grid_"] > [class*="_xs-6"] {
    max-width: 50%;
    flex-basis: 50%;
  }

  [class~="grid"] > [class*="_xs-7"], [class*="grid-"] > [class*="_xs-7"], [class*="grid_"] > [class*="_xs-7"] {
    max-width: 58.3333%;
    flex-basis: 58.3333%;
  }

  [class~="grid"] > [class*="_xs-8"], [class*="grid-"] > [class*="_xs-8"], [class*="grid_"] > [class*="_xs-8"] {
    max-width: 66.6667%;
    flex-basis: 66.6667%;
  }

  [class~="grid"] > [class*="_xs-9"], [class*="grid-"] > [class*="_xs-9"], [class*="grid_"] > [class*="_xs-9"] {
    max-width: 75%;
    flex-basis: 75%;
  }

  [class~="grid"] > [class*="_xs-10"], [class*="grid-"] > [class*="_xs-10"], [class*="grid_"] > [class*="_xs-10"] {
    max-width: 83.3333%;
    flex-basis: 83.3333%;
  }

  [class~="grid"] > [class*="_xs-11"], [class*="grid-"] > [class*="_xs-11"], [class*="grid_"] > [class*="_xs-11"] {
    max-width: 91.6667%;
    flex-basis: 91.6667%;
  }

  [class~="grid"] > [class*="_xs-12"], [class*="grid-"] > [class*="_xs-12"], [class*="grid_"] > [class*="_xs-12"] {
    max-width: 100%;
    flex-basis: 100%;
  }

  [class~="grid"] > [data-push-left*="_xs-0"], [class*="grid-"] > [data-push-left*="_xs-0"], [class*="grid_"] > [data-push-left*="_xs-0"] {
    margin-left: 0;
  }

  [class~="grid"] > [data-push-left*="_xs-1"], [class*="grid-"] > [data-push-left*="_xs-1"], [class*="grid_"] > [data-push-left*="_xs-1"] {
    margin-left: 8.33333%;
  }

  [class~="grid"] > [data-push-left*="_xs-2"], [class*="grid-"] > [data-push-left*="_xs-2"], [class*="grid_"] > [data-push-left*="_xs-2"] {
    margin-left: 16.6667%;
  }

  [class~="grid"] > [data-push-left*="_xs-3"], [class*="grid-"] > [data-push-left*="_xs-3"], [class*="grid_"] > [data-push-left*="_xs-3"] {
    margin-left: 25%;
  }

  [class~="grid"] > [data-push-left*="_xs-4"], [class*="grid-"] > [data-push-left*="_xs-4"], [class*="grid_"] > [data-push-left*="_xs-4"] {
    margin-left: 33.3333%;
  }

  [class~="grid"] > [data-push-left*="_xs-5"], [class*="grid-"] > [data-push-left*="_xs-5"], [class*="grid_"] > [data-push-left*="_xs-5"] {
    margin-left: 41.6667%;
  }

  [class~="grid"] > [data-push-left*="_xs-6"], [class*="grid-"] > [data-push-left*="_xs-6"], [class*="grid_"] > [data-push-left*="_xs-6"] {
    margin-left: 50%;
  }

  [class~="grid"] > [data-push-left*="_xs-7"], [class*="grid-"] > [data-push-left*="_xs-7"], [class*="grid_"] > [data-push-left*="_xs-7"] {
    margin-left: 58.3333%;
  }

  [class~="grid"] > [data-push-left*="_xs-8"], [class*="grid-"] > [data-push-left*="_xs-8"], [class*="grid_"] > [data-push-left*="_xs-8"] {
    margin-left: 66.6667%;
  }

  [class~="grid"] > [data-push-left*="_xs-9"], [class*="grid-"] > [data-push-left*="_xs-9"], [class*="grid_"] > [data-push-left*="_xs-9"] {
    margin-left: 75%;
  }

  [class~="grid"] > [data-push-left*="_xs-10"], [class*="grid-"] > [data-push-left*="_xs-10"], [class*="grid_"] > [data-push-left*="_xs-10"] {
    margin-left: 83.3333%;
  }

  [class~="grid"] > [data-push-left*="_xs-11"], [class*="grid-"] > [data-push-left*="_xs-11"], [class*="grid_"] > [data-push-left*="_xs-11"] {
    margin-left: 91.6667%;
  }

  [class~="grid"] > [data-push-right*="_xs-0"], [class*="grid-"] > [data-push-right*="_xs-0"], [class*="grid_"] > [data-push-right*="_xs-0"] {
    margin-right: 0;
  }

  [class~="grid"] > [data-push-right*="_xs-1"], [class*="grid-"] > [data-push-right*="_xs-1"], [class*="grid_"] > [data-push-right*="_xs-1"] {
    margin-right: 8.33333%;
  }

  [class~="grid"] > [data-push-right*="_xs-2"], [class*="grid-"] > [data-push-right*="_xs-2"], [class*="grid_"] > [data-push-right*="_xs-2"] {
    margin-right: 16.6667%;
  }

  [class~="grid"] > [data-push-right*="_xs-3"], [class*="grid-"] > [data-push-right*="_xs-3"], [class*="grid_"] > [data-push-right*="_xs-3"] {
    margin-right: 25%;
  }

  [class~="grid"] > [data-push-right*="_xs-4"], [class*="grid-"] > [data-push-right*="_xs-4"], [class*="grid_"] > [data-push-right*="_xs-4"] {
    margin-right: 33.3333%;
  }

  [class~="grid"] > [data-push-right*="_xs-5"], [class*="grid-"] > [data-push-right*="_xs-5"], [class*="grid_"] > [data-push-right*="_xs-5"] {
    margin-right: 41.6667%;
  }

  [class~="grid"] > [data-push-right*="_xs-6"], [class*="grid-"] > [data-push-right*="_xs-6"], [class*="grid_"] > [data-push-right*="_xs-6"] {
    margin-right: 50%;
  }

  [class~="grid"] > [data-push-right*="_xs-7"], [class*="grid-"] > [data-push-right*="_xs-7"], [class*="grid_"] > [data-push-right*="_xs-7"] {
    margin-right: 58.3333%;
  }

  [class~="grid"] > [data-push-right*="_xs-8"], [class*="grid-"] > [data-push-right*="_xs-8"], [class*="grid_"] > [data-push-right*="_xs-8"] {
    margin-right: 66.6667%;
  }

  [class~="grid"] > [data-push-right*="_xs-9"], [class*="grid-"] > [data-push-right*="_xs-9"], [class*="grid_"] > [data-push-right*="_xs-9"] {
    margin-right: 75%;
  }

  [class~="grid"] > [data-push-right*="_xs-10"], [class*="grid-"] > [data-push-right*="_xs-10"], [class*="grid_"] > [data-push-right*="_xs-10"] {
    margin-right: 83.3333%;
  }

  [class~="grid"] > [data-push-right*="_xs-11"], [class*="grid-"] > [data-push-right*="_xs-11"], [class*="grid_"] > [data-push-right*="_xs-11"] {
    margin-right: 91.6667%;
  }

  [class~="grid"] [class*="_xs-first"], [class*="grid-"] [class*="_xs-first"], [class*="grid_"] [class*="_xs-first"] {
    order: -1;
  }

  [class~="grid"] [class*="_xs-last"], [class*="grid-"] [class*="_xs-last"], [class*="grid_"] [class*="_xs-last"] {
    order: 1;
  }
}

@media (max-width: 1400px) {
  [class*="lg-hidden"] {
    display: none;
  }
}

@media (max-width: 1260px) {
  [class*="md-hidden"] {
    display: none;
  }
}

@media (max-width: 960px) {
  [class*="sm-hidden"] {
    display: none;
  }
}

@media (max-width: 800px) {
  [class*="xs-hidden"] {
    display: none;
  }
}

.l-section {
  padding: 80px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .l-section {
    padding: 16px;
  }
}

.l-section-inner {
  max-width: 1240px;
  margin: 0 auto;
}

.l-center {
  text-align: center;
}

.l-right {
  text-align: right;
}

.l-flex {
  display: flex;
}

.l-flex > div {
  margin: 0 16px;
}

.l-relative {
  position: relative;
}

.l-pad-1 {
  padding: 8px;
}

.l-pad-left-1 {
  padding-left: 8px;
}

.l-pad-right-1 {
  padding-right: 8px;
}

.l-pad-top-1 {
  padding-top: 8px;
}

.l-pad-bottom-1 {
  padding-bottom: 8px;
}

.l-pad-vertical-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.l-pad-horizontal-1 {
  padding-left: 8px;
  padding-right: 8px;
}

.l-space-1 {
  margin: 8px;
}

.l-space-left-1 {
  margin-left: 8px;
}

.l-space-right-1 {
  margin-right: 8px;
}

.l-space-top-1 {
  margin-top: 8px;
}

.l-space-bottom-1 {
  margin-bottom: 8px;
}

.l-space-negative-left-1 {
  margin-left: -8px;
}

.l-space-negative-right-1 {
  margin-right: -8px;
}

.l-space-negative-top-1 {
  margin-top: -8px;
}

.l-space-negative-bottom-1 {
  margin-bottom: -8px;
}

.l-space-vertical-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.l-space-horizontal-1 {
  margin-left: 8px;
  margin-right: 8px;
}

.l-width-1 {
  width: 8px;
}

.l-max-width-1 {
  max-width: 8px;
}

.l-pad-2 {
  padding: 16px;
}

.l-pad-left-2 {
  padding-left: 16px;
}

.l-pad-right-2 {
  padding-right: 16px;
}

.l-pad-top-2 {
  padding-top: 16px;
}

.l-pad-bottom-2 {
  padding-bottom: 16px;
}

.l-pad-vertical-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.l-pad-horizontal-2 {
  padding-left: 16px;
  padding-right: 16px;
}

.l-space-2 {
  margin: 16px;
}

.l-space-left-2 {
  margin-left: 16px;
}

.l-space-right-2 {
  margin-right: 16px;
}

.l-space-top-2 {
  margin-top: 16px;
}

.l-space-bottom-2 {
  margin-bottom: 16px;
}

.l-space-negative-left-2 {
  margin-left: -16px;
}

.l-space-negative-right-2 {
  margin-right: -16px;
}

.l-space-negative-top-2 {
  margin-top: -16px;
}

.l-space-negative-bottom-2 {
  margin-bottom: -16px;
}

.l-space-vertical-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.l-space-horizontal-2 {
  margin-left: 16px;
  margin-right: 16px;
}

.l-width-2 {
  width: 16px;
}

.l-max-width-2 {
  max-width: 16px;
}

.l-pad-3 {
  padding: 24px;
}

.l-pad-left-3 {
  padding-left: 24px;
}

.l-pad-right-3 {
  padding-right: 24px;
}

.l-pad-top-3 {
  padding-top: 24px;
}

.l-pad-bottom-3 {
  padding-bottom: 24px;
}

.l-pad-vertical-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.l-pad-horizontal-3 {
  padding-left: 24px;
  padding-right: 24px;
}

.l-space-3 {
  margin: 24px;
}

.l-space-left-3 {
  margin-left: 24px;
}

.l-space-right-3 {
  margin-right: 24px;
}

.l-space-top-3 {
  margin-top: 24px;
}

.l-space-bottom-3 {
  margin-bottom: 24px;
}

.l-space-negative-left-3 {
  margin-left: -24px;
}

.l-space-negative-right-3 {
  margin-right: -24px;
}

.l-space-negative-top-3 {
  margin-top: -24px;
}

.l-space-negative-bottom-3 {
  margin-bottom: -24px;
}

.l-space-vertical-3 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.l-space-horizontal-3 {
  margin-left: 24px;
  margin-right: 24px;
}

.l-width-3 {
  width: 24px;
}

.l-max-width-3 {
  max-width: 24px;
}

.l-pad-4 {
  padding: 32px;
}

.l-pad-left-4 {
  padding-left: 32px;
}

.l-pad-right-4 {
  padding-right: 32px;
}

.l-pad-top-4 {
  padding-top: 32px;
}

.l-pad-bottom-4 {
  padding-bottom: 32px;
}

.l-pad-vertical-4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.l-pad-horizontal-4 {
  padding-left: 32px;
  padding-right: 32px;
}

.l-space-4 {
  margin: 32px;
}

.l-space-left-4 {
  margin-left: 32px;
}

.l-space-right-4 {
  margin-right: 32px;
}

.l-space-top-4 {
  margin-top: 32px;
}

.l-space-bottom-4 {
  margin-bottom: 32px;
}

.l-space-negative-left-4 {
  margin-left: -32px;
}

.l-space-negative-right-4 {
  margin-right: -32px;
}

.l-space-negative-top-4 {
  margin-top: -32px;
}

.l-space-negative-bottom-4 {
  margin-bottom: -32px;
}

.l-space-vertical-4 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.l-space-horizontal-4 {
  margin-left: 32px;
  margin-right: 32px;
}

.l-width-4 {
  width: 32px;
}

.l-max-width-4 {
  max-width: 32px;
}

.l-pad-5 {
  padding: 40px;
}

.l-pad-left-5 {
  padding-left: 40px;
}

.l-pad-right-5 {
  padding-right: 40px;
}

.l-pad-top-5 {
  padding-top: 40px;
}

.l-pad-bottom-5 {
  padding-bottom: 40px;
}

.l-pad-vertical-5 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.l-pad-horizontal-5 {
  padding-left: 40px;
  padding-right: 40px;
}

.l-space-5 {
  margin: 40px;
}

.l-space-left-5 {
  margin-left: 40px;
}

.l-space-right-5 {
  margin-right: 40px;
}

.l-space-top-5 {
  margin-top: 40px;
}

.l-space-bottom-5 {
  margin-bottom: 40px;
}

.l-space-negative-left-5 {
  margin-left: -40px;
}

.l-space-negative-right-5 {
  margin-right: -40px;
}

.l-space-negative-top-5 {
  margin-top: -40px;
}

.l-space-negative-bottom-5 {
  margin-bottom: -40px;
}

.l-space-vertical-5 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.l-space-horizontal-5 {
  margin-left: 40px;
  margin-right: 40px;
}

.l-width-5 {
  width: 40px;
}

.l-max-width-5 {
  max-width: 40px;
}

.l-pad-6 {
  padding: 48px;
}

.l-pad-left-6 {
  padding-left: 48px;
}

.l-pad-right-6 {
  padding-right: 48px;
}

.l-pad-top-6 {
  padding-top: 48px;
}

.l-pad-bottom-6 {
  padding-bottom: 48px;
}

.l-pad-vertical-6 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.l-pad-horizontal-6 {
  padding-left: 48px;
  padding-right: 48px;
}

.l-space-6 {
  margin: 48px;
}

.l-space-left-6 {
  margin-left: 48px;
}

.l-space-right-6 {
  margin-right: 48px;
}

.l-space-top-6 {
  margin-top: 48px;
}

.l-space-bottom-6 {
  margin-bottom: 48px;
}

.l-space-negative-left-6 {
  margin-left: -48px;
}

.l-space-negative-right-6 {
  margin-right: -48px;
}

.l-space-negative-top-6 {
  margin-top: -48px;
}

.l-space-negative-bottom-6 {
  margin-bottom: -48px;
}

.l-space-vertical-6 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.l-space-horizontal-6 {
  margin-left: 48px;
  margin-right: 48px;
}

.l-width-6 {
  width: 48px;
}

.l-max-width-6 {
  max-width: 48px;
}

.l-pad-7 {
  padding: 56px;
}

.l-pad-left-7 {
  padding-left: 56px;
}

.l-pad-right-7 {
  padding-right: 56px;
}

.l-pad-top-7 {
  padding-top: 56px;
}

.l-pad-bottom-7 {
  padding-bottom: 56px;
}

.l-pad-vertical-7 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.l-pad-horizontal-7 {
  padding-left: 56px;
  padding-right: 56px;
}

.l-space-7 {
  margin: 56px;
}

.l-space-left-7 {
  margin-left: 56px;
}

.l-space-right-7 {
  margin-right: 56px;
}

.l-space-top-7 {
  margin-top: 56px;
}

.l-space-bottom-7 {
  margin-bottom: 56px;
}

.l-space-negative-left-7 {
  margin-left: -56px;
}

.l-space-negative-right-7 {
  margin-right: -56px;
}

.l-space-negative-top-7 {
  margin-top: -56px;
}

.l-space-negative-bottom-7 {
  margin-bottom: -56px;
}

.l-space-vertical-7 {
  margin-top: 56px;
  margin-bottom: 56px;
}

.l-space-horizontal-7 {
  margin-left: 56px;
  margin-right: 56px;
}

.l-width-7 {
  width: 56px;
}

.l-max-width-7 {
  max-width: 56px;
}

.l-pad-8 {
  padding: 64px;
}

.l-pad-left-8 {
  padding-left: 64px;
}

.l-pad-right-8 {
  padding-right: 64px;
}

.l-pad-top-8 {
  padding-top: 64px;
}

.l-pad-bottom-8 {
  padding-bottom: 64px;
}

.l-pad-vertical-8 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.l-pad-horizontal-8 {
  padding-left: 64px;
  padding-right: 64px;
}

.l-space-8 {
  margin: 64px;
}

.l-space-left-8 {
  margin-left: 64px;
}

.l-space-right-8 {
  margin-right: 64px;
}

.l-space-top-8 {
  margin-top: 64px;
}

.l-space-bottom-8 {
  margin-bottom: 64px;
}

.l-space-negative-left-8 {
  margin-left: -64px;
}

.l-space-negative-right-8 {
  margin-right: -64px;
}

.l-space-negative-top-8 {
  margin-top: -64px;
}

.l-space-negative-bottom-8 {
  margin-bottom: -64px;
}

.l-space-vertical-8 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.l-space-horizontal-8 {
  margin-left: 64px;
  margin-right: 64px;
}

.l-width-8 {
  width: 64px;
}

.l-max-width-8 {
  max-width: 64px;
}

.l-pad-9 {
  padding: 72px;
}

.l-pad-left-9 {
  padding-left: 72px;
}

.l-pad-right-9 {
  padding-right: 72px;
}

.l-pad-top-9 {
  padding-top: 72px;
}

.l-pad-bottom-9 {
  padding-bottom: 72px;
}

.l-pad-vertical-9 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.l-pad-horizontal-9 {
  padding-left: 72px;
  padding-right: 72px;
}

.l-space-9 {
  margin: 72px;
}

.l-space-left-9 {
  margin-left: 72px;
}

.l-space-right-9 {
  margin-right: 72px;
}

.l-space-top-9 {
  margin-top: 72px;
}

.l-space-bottom-9 {
  margin-bottom: 72px;
}

.l-space-negative-left-9 {
  margin-left: -72px;
}

.l-space-negative-right-9 {
  margin-right: -72px;
}

.l-space-negative-top-9 {
  margin-top: -72px;
}

.l-space-negative-bottom-9 {
  margin-bottom: -72px;
}

.l-space-vertical-9 {
  margin-top: 72px;
  margin-bottom: 72px;
}

.l-space-horizontal-9 {
  margin-left: 72px;
  margin-right: 72px;
}

.l-width-9 {
  width: 72px;
}

.l-max-width-9 {
  max-width: 72px;
}

.l-pad-10 {
  padding: 80px;
}

.l-pad-left-10 {
  padding-left: 80px;
}

.l-pad-right-10 {
  padding-right: 80px;
}

.l-pad-top-10 {
  padding-top: 80px;
}

.l-pad-bottom-10 {
  padding-bottom: 80px;
}

.l-pad-vertical-10 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.l-pad-horizontal-10 {
  padding-left: 80px;
  padding-right: 80px;
}

.l-space-10 {
  margin: 80px;
}

.l-space-left-10 {
  margin-left: 80px;
}

.l-space-right-10 {
  margin-right: 80px;
}

.l-space-top-10 {
  margin-top: 80px;
}

.l-space-bottom-10 {
  margin-bottom: 80px;
}

.l-space-negative-left-10 {
  margin-left: -80px;
}

.l-space-negative-right-10 {
  margin-right: -80px;
}

.l-space-negative-top-10 {
  margin-top: -80px;
}

.l-space-negative-bottom-10 {
  margin-bottom: -80px;
}

.l-space-vertical-10 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.l-space-horizontal-10 {
  margin-left: 80px;
  margin-right: 80px;
}

.l-width-10 {
  width: 80px;
}

.l-max-width-10 {
  max-width: 80px;
}

.l-pad-11 {
  padding: 88px;
}

.l-pad-left-11 {
  padding-left: 88px;
}

.l-pad-right-11 {
  padding-right: 88px;
}

.l-pad-top-11 {
  padding-top: 88px;
}

.l-pad-bottom-11 {
  padding-bottom: 88px;
}

.l-pad-vertical-11 {
  padding-top: 88px;
  padding-bottom: 88px;
}

.l-pad-horizontal-11 {
  padding-left: 88px;
  padding-right: 88px;
}

.l-space-11 {
  margin: 88px;
}

.l-space-left-11 {
  margin-left: 88px;
}

.l-space-right-11 {
  margin-right: 88px;
}

.l-space-top-11 {
  margin-top: 88px;
}

.l-space-bottom-11 {
  margin-bottom: 88px;
}

.l-space-negative-left-11 {
  margin-left: -88px;
}

.l-space-negative-right-11 {
  margin-right: -88px;
}

.l-space-negative-top-11 {
  margin-top: -88px;
}

.l-space-negative-bottom-11 {
  margin-bottom: -88px;
}

.l-space-vertical-11 {
  margin-top: 88px;
  margin-bottom: 88px;
}

.l-space-horizontal-11 {
  margin-left: 88px;
  margin-right: 88px;
}

.l-width-11 {
  width: 88px;
}

.l-max-width-11 {
  max-width: 88px;
}

.l-pad-12 {
  padding: 96px;
}

.l-pad-left-12 {
  padding-left: 96px;
}

.l-pad-right-12 {
  padding-right: 96px;
}

.l-pad-top-12 {
  padding-top: 96px;
}

.l-pad-bottom-12 {
  padding-bottom: 96px;
}

.l-pad-vertical-12 {
  padding-top: 96px;
  padding-bottom: 96px;
}

.l-pad-horizontal-12 {
  padding-left: 96px;
  padding-right: 96px;
}

.l-space-12 {
  margin: 96px;
}

.l-space-left-12 {
  margin-left: 96px;
}

.l-space-right-12 {
  margin-right: 96px;
}

.l-space-top-12 {
  margin-top: 96px;
}

.l-space-bottom-12 {
  margin-bottom: 96px;
}

.l-space-negative-left-12 {
  margin-left: -96px;
}

.l-space-negative-right-12 {
  margin-right: -96px;
}

.l-space-negative-top-12 {
  margin-top: -96px;
}

.l-space-negative-bottom-12 {
  margin-bottom: -96px;
}

.l-space-vertical-12 {
  margin-top: 96px;
  margin-bottom: 96px;
}

.l-space-horizontal-12 {
  margin-left: 96px;
  margin-right: 96px;
}

.l-width-12 {
  width: 96px;
}

.l-max-width-12 {
  max-width: 96px;
}

.l-pad-13 {
  padding: 104px;
}

.l-pad-left-13 {
  padding-left: 104px;
}

.l-pad-right-13 {
  padding-right: 104px;
}

.l-pad-top-13 {
  padding-top: 104px;
}

.l-pad-bottom-13 {
  padding-bottom: 104px;
}

.l-pad-vertical-13 {
  padding-top: 104px;
  padding-bottom: 104px;
}

.l-pad-horizontal-13 {
  padding-left: 104px;
  padding-right: 104px;
}

.l-space-13 {
  margin: 104px;
}

.l-space-left-13 {
  margin-left: 104px;
}

.l-space-right-13 {
  margin-right: 104px;
}

.l-space-top-13 {
  margin-top: 104px;
}

.l-space-bottom-13 {
  margin-bottom: 104px;
}

.l-space-negative-left-13 {
  margin-left: -104px;
}

.l-space-negative-right-13 {
  margin-right: -104px;
}

.l-space-negative-top-13 {
  margin-top: -104px;
}

.l-space-negative-bottom-13 {
  margin-bottom: -104px;
}

.l-space-vertical-13 {
  margin-top: 104px;
  margin-bottom: 104px;
}

.l-space-horizontal-13 {
  margin-left: 104px;
  margin-right: 104px;
}

.l-width-13 {
  width: 104px;
}

.l-max-width-13 {
  max-width: 104px;
}

.l-pad-14 {
  padding: 112px;
}

.l-pad-left-14 {
  padding-left: 112px;
}

.l-pad-right-14 {
  padding-right: 112px;
}

.l-pad-top-14 {
  padding-top: 112px;
}

.l-pad-bottom-14 {
  padding-bottom: 112px;
}

.l-pad-vertical-14 {
  padding-top: 112px;
  padding-bottom: 112px;
}

.l-pad-horizontal-14 {
  padding-left: 112px;
  padding-right: 112px;
}

.l-space-14 {
  margin: 112px;
}

.l-space-left-14 {
  margin-left: 112px;
}

.l-space-right-14 {
  margin-right: 112px;
}

.l-space-top-14 {
  margin-top: 112px;
}

.l-space-bottom-14 {
  margin-bottom: 112px;
}

.l-space-negative-left-14 {
  margin-left: -112px;
}

.l-space-negative-right-14 {
  margin-right: -112px;
}

.l-space-negative-top-14 {
  margin-top: -112px;
}

.l-space-negative-bottom-14 {
  margin-bottom: -112px;
}

.l-space-vertical-14 {
  margin-top: 112px;
  margin-bottom: 112px;
}

.l-space-horizontal-14 {
  margin-left: 112px;
  margin-right: 112px;
}

.l-width-14 {
  width: 112px;
}

.l-max-width-14 {
  max-width: 112px;
}

.l-pad-15 {
  padding: 120px;
}

.l-pad-left-15 {
  padding-left: 120px;
}

.l-pad-right-15 {
  padding-right: 120px;
}

.l-pad-top-15 {
  padding-top: 120px;
}

.l-pad-bottom-15 {
  padding-bottom: 120px;
}

.l-pad-vertical-15 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.l-pad-horizontal-15 {
  padding-left: 120px;
  padding-right: 120px;
}

.l-space-15 {
  margin: 120px;
}

.l-space-left-15 {
  margin-left: 120px;
}

.l-space-right-15 {
  margin-right: 120px;
}

.l-space-top-15 {
  margin-top: 120px;
}

.l-space-bottom-15 {
  margin-bottom: 120px;
}

.l-space-negative-left-15 {
  margin-left: -120px;
}

.l-space-negative-right-15 {
  margin-right: -120px;
}

.l-space-negative-top-15 {
  margin-top: -120px;
}

.l-space-negative-bottom-15 {
  margin-bottom: -120px;
}

.l-space-vertical-15 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.l-space-horizontal-15 {
  margin-left: 120px;
  margin-right: 120px;
}

.l-width-15 {
  width: 120px;
}

.l-max-width-15 {
  max-width: 120px;
}

.l-pad-16 {
  padding: 128px;
}

.l-pad-left-16 {
  padding-left: 128px;
}

.l-pad-right-16 {
  padding-right: 128px;
}

.l-pad-top-16 {
  padding-top: 128px;
}

.l-pad-bottom-16 {
  padding-bottom: 128px;
}

.l-pad-vertical-16 {
  padding-top: 128px;
  padding-bottom: 128px;
}

.l-pad-horizontal-16 {
  padding-left: 128px;
  padding-right: 128px;
}

.l-space-16 {
  margin: 128px;
}

.l-space-left-16 {
  margin-left: 128px;
}

.l-space-right-16 {
  margin-right: 128px;
}

.l-space-top-16 {
  margin-top: 128px;
}

.l-space-bottom-16 {
  margin-bottom: 128px;
}

.l-space-negative-left-16 {
  margin-left: -128px;
}

.l-space-negative-right-16 {
  margin-right: -128px;
}

.l-space-negative-top-16 {
  margin-top: -128px;
}

.l-space-negative-bottom-16 {
  margin-bottom: -128px;
}

.l-space-vertical-16 {
  margin-top: 128px;
  margin-bottom: 128px;
}

.l-space-horizontal-16 {
  margin-left: 128px;
  margin-right: 128px;
}

.l-width-16 {
  width: 128px;
}

.l-max-width-16 {
  max-width: 128px;
}

.l-pad-17 {
  padding: 136px;
}

.l-pad-left-17 {
  padding-left: 136px;
}

.l-pad-right-17 {
  padding-right: 136px;
}

.l-pad-top-17 {
  padding-top: 136px;
}

.l-pad-bottom-17 {
  padding-bottom: 136px;
}

.l-pad-vertical-17 {
  padding-top: 136px;
  padding-bottom: 136px;
}

.l-pad-horizontal-17 {
  padding-left: 136px;
  padding-right: 136px;
}

.l-space-17 {
  margin: 136px;
}

.l-space-left-17 {
  margin-left: 136px;
}

.l-space-right-17 {
  margin-right: 136px;
}

.l-space-top-17 {
  margin-top: 136px;
}

.l-space-bottom-17 {
  margin-bottom: 136px;
}

.l-space-negative-left-17 {
  margin-left: -136px;
}

.l-space-negative-right-17 {
  margin-right: -136px;
}

.l-space-negative-top-17 {
  margin-top: -136px;
}

.l-space-negative-bottom-17 {
  margin-bottom: -136px;
}

.l-space-vertical-17 {
  margin-top: 136px;
  margin-bottom: 136px;
}

.l-space-horizontal-17 {
  margin-left: 136px;
  margin-right: 136px;
}

.l-width-17 {
  width: 136px;
}

.l-max-width-17 {
  max-width: 136px;
}

.l-pad-18 {
  padding: 144px;
}

.l-pad-left-18 {
  padding-left: 144px;
}

.l-pad-right-18 {
  padding-right: 144px;
}

.l-pad-top-18 {
  padding-top: 144px;
}

.l-pad-bottom-18 {
  padding-bottom: 144px;
}

.l-pad-vertical-18 {
  padding-top: 144px;
  padding-bottom: 144px;
}

.l-pad-horizontal-18 {
  padding-left: 144px;
  padding-right: 144px;
}

.l-space-18 {
  margin: 144px;
}

.l-space-left-18 {
  margin-left: 144px;
}

.l-space-right-18 {
  margin-right: 144px;
}

.l-space-top-18 {
  margin-top: 144px;
}

.l-space-bottom-18 {
  margin-bottom: 144px;
}

.l-space-negative-left-18 {
  margin-left: -144px;
}

.l-space-negative-right-18 {
  margin-right: -144px;
}

.l-space-negative-top-18 {
  margin-top: -144px;
}

.l-space-negative-bottom-18 {
  margin-bottom: -144px;
}

.l-space-vertical-18 {
  margin-top: 144px;
  margin-bottom: 144px;
}

.l-space-horizontal-18 {
  margin-left: 144px;
  margin-right: 144px;
}

.l-width-18 {
  width: 144px;
}

.l-max-width-18 {
  max-width: 144px;
}

.l-pad-19 {
  padding: 152px;
}

.l-pad-left-19 {
  padding-left: 152px;
}

.l-pad-right-19 {
  padding-right: 152px;
}

.l-pad-top-19 {
  padding-top: 152px;
}

.l-pad-bottom-19 {
  padding-bottom: 152px;
}

.l-pad-vertical-19 {
  padding-top: 152px;
  padding-bottom: 152px;
}

.l-pad-horizontal-19 {
  padding-left: 152px;
  padding-right: 152px;
}

.l-space-19 {
  margin: 152px;
}

.l-space-left-19 {
  margin-left: 152px;
}

.l-space-right-19 {
  margin-right: 152px;
}

.l-space-top-19 {
  margin-top: 152px;
}

.l-space-bottom-19 {
  margin-bottom: 152px;
}

.l-space-negative-left-19 {
  margin-left: -152px;
}

.l-space-negative-right-19 {
  margin-right: -152px;
}

.l-space-negative-top-19 {
  margin-top: -152px;
}

.l-space-negative-bottom-19 {
  margin-bottom: -152px;
}

.l-space-vertical-19 {
  margin-top: 152px;
  margin-bottom: 152px;
}

.l-space-horizontal-19 {
  margin-left: 152px;
  margin-right: 152px;
}

.l-width-19 {
  width: 152px;
}

.l-max-width-19 {
  max-width: 152px;
}

.l-pad-20 {
  padding: 160px;
}

.l-pad-left-20 {
  padding-left: 160px;
}

.l-pad-right-20 {
  padding-right: 160px;
}

.l-pad-top-20 {
  padding-top: 160px;
}

.l-pad-bottom-20 {
  padding-bottom: 160px;
}

.l-pad-vertical-20 {
  padding-top: 160px;
  padding-bottom: 160px;
}

.l-pad-horizontal-20 {
  padding-left: 160px;
  padding-right: 160px;
}

.l-space-20 {
  margin: 160px;
}

.l-space-left-20 {
  margin-left: 160px;
}

.l-space-right-20 {
  margin-right: 160px;
}

.l-space-top-20 {
  margin-top: 160px;
}

.l-space-bottom-20 {
  margin-bottom: 160px;
}

.l-space-negative-left-20 {
  margin-left: -160px;
}

.l-space-negative-right-20 {
  margin-right: -160px;
}

.l-space-negative-top-20 {
  margin-top: -160px;
}

.l-space-negative-bottom-20 {
  margin-bottom: -160px;
}

.l-space-vertical-20 {
  margin-top: 160px;
  margin-bottom: 160px;
}

.l-space-horizontal-20 {
  margin-left: 160px;
  margin-right: 160px;
}

.l-width-20 {
  width: 160px;
}

.l-max-width-20 {
  max-width: 160px;
}

.l-pad-21 {
  padding: 168px;
}

.l-pad-left-21 {
  padding-left: 168px;
}

.l-pad-right-21 {
  padding-right: 168px;
}

.l-pad-top-21 {
  padding-top: 168px;
}

.l-pad-bottom-21 {
  padding-bottom: 168px;
}

.l-pad-vertical-21 {
  padding-top: 168px;
  padding-bottom: 168px;
}

.l-pad-horizontal-21 {
  padding-left: 168px;
  padding-right: 168px;
}

.l-space-21 {
  margin: 168px;
}

.l-space-left-21 {
  margin-left: 168px;
}

.l-space-right-21 {
  margin-right: 168px;
}

.l-space-top-21 {
  margin-top: 168px;
}

.l-space-bottom-21 {
  margin-bottom: 168px;
}

.l-space-negative-left-21 {
  margin-left: -168px;
}

.l-space-negative-right-21 {
  margin-right: -168px;
}

.l-space-negative-top-21 {
  margin-top: -168px;
}

.l-space-negative-bottom-21 {
  margin-bottom: -168px;
}

.l-space-vertical-21 {
  margin-top: 168px;
  margin-bottom: 168px;
}

.l-space-horizontal-21 {
  margin-left: 168px;
  margin-right: 168px;
}

.l-width-21 {
  width: 168px;
}

.l-max-width-21 {
  max-width: 168px;
}

.l-pad-22 {
  padding: 176px;
}

.l-pad-left-22 {
  padding-left: 176px;
}

.l-pad-right-22 {
  padding-right: 176px;
}

.l-pad-top-22 {
  padding-top: 176px;
}

.l-pad-bottom-22 {
  padding-bottom: 176px;
}

.l-pad-vertical-22 {
  padding-top: 176px;
  padding-bottom: 176px;
}

.l-pad-horizontal-22 {
  padding-left: 176px;
  padding-right: 176px;
}

.l-space-22 {
  margin: 176px;
}

.l-space-left-22 {
  margin-left: 176px;
}

.l-space-right-22 {
  margin-right: 176px;
}

.l-space-top-22 {
  margin-top: 176px;
}

.l-space-bottom-22 {
  margin-bottom: 176px;
}

.l-space-negative-left-22 {
  margin-left: -176px;
}

.l-space-negative-right-22 {
  margin-right: -176px;
}

.l-space-negative-top-22 {
  margin-top: -176px;
}

.l-space-negative-bottom-22 {
  margin-bottom: -176px;
}

.l-space-vertical-22 {
  margin-top: 176px;
  margin-bottom: 176px;
}

.l-space-horizontal-22 {
  margin-left: 176px;
  margin-right: 176px;
}

.l-width-22 {
  width: 176px;
}

.l-max-width-22 {
  max-width: 176px;
}

.l-pad-23 {
  padding: 184px;
}

.l-pad-left-23 {
  padding-left: 184px;
}

.l-pad-right-23 {
  padding-right: 184px;
}

.l-pad-top-23 {
  padding-top: 184px;
}

.l-pad-bottom-23 {
  padding-bottom: 184px;
}

.l-pad-vertical-23 {
  padding-top: 184px;
  padding-bottom: 184px;
}

.l-pad-horizontal-23 {
  padding-left: 184px;
  padding-right: 184px;
}

.l-space-23 {
  margin: 184px;
}

.l-space-left-23 {
  margin-left: 184px;
}

.l-space-right-23 {
  margin-right: 184px;
}

.l-space-top-23 {
  margin-top: 184px;
}

.l-space-bottom-23 {
  margin-bottom: 184px;
}

.l-space-negative-left-23 {
  margin-left: -184px;
}

.l-space-negative-right-23 {
  margin-right: -184px;
}

.l-space-negative-top-23 {
  margin-top: -184px;
}

.l-space-negative-bottom-23 {
  margin-bottom: -184px;
}

.l-space-vertical-23 {
  margin-top: 184px;
  margin-bottom: 184px;
}

.l-space-horizontal-23 {
  margin-left: 184px;
  margin-right: 184px;
}

.l-width-23 {
  width: 184px;
}

.l-max-width-23 {
  max-width: 184px;
}

.l-pad-24 {
  padding: 192px;
}

.l-pad-left-24 {
  padding-left: 192px;
}

.l-pad-right-24 {
  padding-right: 192px;
}

.l-pad-top-24 {
  padding-top: 192px;
}

.l-pad-bottom-24 {
  padding-bottom: 192px;
}

.l-pad-vertical-24 {
  padding-top: 192px;
  padding-bottom: 192px;
}

.l-pad-horizontal-24 {
  padding-left: 192px;
  padding-right: 192px;
}

.l-space-24 {
  margin: 192px;
}

.l-space-left-24 {
  margin-left: 192px;
}

.l-space-right-24 {
  margin-right: 192px;
}

.l-space-top-24 {
  margin-top: 192px;
}

.l-space-bottom-24 {
  margin-bottom: 192px;
}

.l-space-negative-left-24 {
  margin-left: -192px;
}

.l-space-negative-right-24 {
  margin-right: -192px;
}

.l-space-negative-top-24 {
  margin-top: -192px;
}

.l-space-negative-bottom-24 {
  margin-bottom: -192px;
}

.l-space-vertical-24 {
  margin-top: 192px;
  margin-bottom: 192px;
}

.l-space-horizontal-24 {
  margin-left: 192px;
  margin-right: 192px;
}

.l-width-24 {
  width: 192px;
}

.l-max-width-24 {
  max-width: 192px;
}

.l-pad-25 {
  padding: 200px;
}

.l-pad-left-25 {
  padding-left: 200px;
}

.l-pad-right-25 {
  padding-right: 200px;
}

.l-pad-top-25 {
  padding-top: 200px;
}

.l-pad-bottom-25 {
  padding-bottom: 200px;
}

.l-pad-vertical-25 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.l-pad-horizontal-25 {
  padding-left: 200px;
  padding-right: 200px;
}

.l-space-25 {
  margin: 200px;
}

.l-space-left-25 {
  margin-left: 200px;
}

.l-space-right-25 {
  margin-right: 200px;
}

.l-space-top-25 {
  margin-top: 200px;
}

.l-space-bottom-25 {
  margin-bottom: 200px;
}

.l-space-negative-left-25 {
  margin-left: -200px;
}

.l-space-negative-right-25 {
  margin-right: -200px;
}

.l-space-negative-top-25 {
  margin-top: -200px;
}

.l-space-negative-bottom-25 {
  margin-bottom: -200px;
}

.l-space-vertical-25 {
  margin-top: 200px;
  margin-bottom: 200px;
}

.l-space-horizontal-25 {
  margin-left: 200px;
  margin-right: 200px;
}

.l-width-25 {
  width: 200px;
}

.l-max-width-25 {
  max-width: 200px;
}

.l-pad-26 {
  padding: 208px;
}

.l-pad-left-26 {
  padding-left: 208px;
}

.l-pad-right-26 {
  padding-right: 208px;
}

.l-pad-top-26 {
  padding-top: 208px;
}

.l-pad-bottom-26 {
  padding-bottom: 208px;
}

.l-pad-vertical-26 {
  padding-top: 208px;
  padding-bottom: 208px;
}

.l-pad-horizontal-26 {
  padding-left: 208px;
  padding-right: 208px;
}

.l-space-26 {
  margin: 208px;
}

.l-space-left-26 {
  margin-left: 208px;
}

.l-space-right-26 {
  margin-right: 208px;
}

.l-space-top-26 {
  margin-top: 208px;
}

.l-space-bottom-26 {
  margin-bottom: 208px;
}

.l-space-negative-left-26 {
  margin-left: -208px;
}

.l-space-negative-right-26 {
  margin-right: -208px;
}

.l-space-negative-top-26 {
  margin-top: -208px;
}

.l-space-negative-bottom-26 {
  margin-bottom: -208px;
}

.l-space-vertical-26 {
  margin-top: 208px;
  margin-bottom: 208px;
}

.l-space-horizontal-26 {
  margin-left: 208px;
  margin-right: 208px;
}

.l-width-26 {
  width: 208px;
}

.l-max-width-26 {
  max-width: 208px;
}

.l-pad-27 {
  padding: 216px;
}

.l-pad-left-27 {
  padding-left: 216px;
}

.l-pad-right-27 {
  padding-right: 216px;
}

.l-pad-top-27 {
  padding-top: 216px;
}

.l-pad-bottom-27 {
  padding-bottom: 216px;
}

.l-pad-vertical-27 {
  padding-top: 216px;
  padding-bottom: 216px;
}

.l-pad-horizontal-27 {
  padding-left: 216px;
  padding-right: 216px;
}

.l-space-27 {
  margin: 216px;
}

.l-space-left-27 {
  margin-left: 216px;
}

.l-space-right-27 {
  margin-right: 216px;
}

.l-space-top-27 {
  margin-top: 216px;
}

.l-space-bottom-27 {
  margin-bottom: 216px;
}

.l-space-negative-left-27 {
  margin-left: -216px;
}

.l-space-negative-right-27 {
  margin-right: -216px;
}

.l-space-negative-top-27 {
  margin-top: -216px;
}

.l-space-negative-bottom-27 {
  margin-bottom: -216px;
}

.l-space-vertical-27 {
  margin-top: 216px;
  margin-bottom: 216px;
}

.l-space-horizontal-27 {
  margin-left: 216px;
  margin-right: 216px;
}

.l-width-27 {
  width: 216px;
}

.l-max-width-27 {
  max-width: 216px;
}

.l-pad-28 {
  padding: 224px;
}

.l-pad-left-28 {
  padding-left: 224px;
}

.l-pad-right-28 {
  padding-right: 224px;
}

.l-pad-top-28 {
  padding-top: 224px;
}

.l-pad-bottom-28 {
  padding-bottom: 224px;
}

.l-pad-vertical-28 {
  padding-top: 224px;
  padding-bottom: 224px;
}

.l-pad-horizontal-28 {
  padding-left: 224px;
  padding-right: 224px;
}

.l-space-28 {
  margin: 224px;
}

.l-space-left-28 {
  margin-left: 224px;
}

.l-space-right-28 {
  margin-right: 224px;
}

.l-space-top-28 {
  margin-top: 224px;
}

.l-space-bottom-28 {
  margin-bottom: 224px;
}

.l-space-negative-left-28 {
  margin-left: -224px;
}

.l-space-negative-right-28 {
  margin-right: -224px;
}

.l-space-negative-top-28 {
  margin-top: -224px;
}

.l-space-negative-bottom-28 {
  margin-bottom: -224px;
}

.l-space-vertical-28 {
  margin-top: 224px;
  margin-bottom: 224px;
}

.l-space-horizontal-28 {
  margin-left: 224px;
  margin-right: 224px;
}

.l-width-28 {
  width: 224px;
}

.l-max-width-28 {
  max-width: 224px;
}

.l-pad-29 {
  padding: 232px;
}

.l-pad-left-29 {
  padding-left: 232px;
}

.l-pad-right-29 {
  padding-right: 232px;
}

.l-pad-top-29 {
  padding-top: 232px;
}

.l-pad-bottom-29 {
  padding-bottom: 232px;
}

.l-pad-vertical-29 {
  padding-top: 232px;
  padding-bottom: 232px;
}

.l-pad-horizontal-29 {
  padding-left: 232px;
  padding-right: 232px;
}

.l-space-29 {
  margin: 232px;
}

.l-space-left-29 {
  margin-left: 232px;
}

.l-space-right-29 {
  margin-right: 232px;
}

.l-space-top-29 {
  margin-top: 232px;
}

.l-space-bottom-29 {
  margin-bottom: 232px;
}

.l-space-negative-left-29 {
  margin-left: -232px;
}

.l-space-negative-right-29 {
  margin-right: -232px;
}

.l-space-negative-top-29 {
  margin-top: -232px;
}

.l-space-negative-bottom-29 {
  margin-bottom: -232px;
}

.l-space-vertical-29 {
  margin-top: 232px;
  margin-bottom: 232px;
}

.l-space-horizontal-29 {
  margin-left: 232px;
  margin-right: 232px;
}

.l-width-29 {
  width: 232px;
}

.l-max-width-29 {
  max-width: 232px;
}

.l-pad-30 {
  padding: 240px;
}

.l-pad-left-30 {
  padding-left: 240px;
}

.l-pad-right-30 {
  padding-right: 240px;
}

.l-pad-top-30 {
  padding-top: 240px;
}

.l-pad-bottom-30 {
  padding-bottom: 240px;
}

.l-pad-vertical-30 {
  padding-top: 240px;
  padding-bottom: 240px;
}

.l-pad-horizontal-30 {
  padding-left: 240px;
  padding-right: 240px;
}

.l-space-30 {
  margin: 240px;
}

.l-space-left-30 {
  margin-left: 240px;
}

.l-space-right-30 {
  margin-right: 240px;
}

.l-space-top-30 {
  margin-top: 240px;
}

.l-space-bottom-30 {
  margin-bottom: 240px;
}

.l-space-negative-left-30 {
  margin-left: -240px;
}

.l-space-negative-right-30 {
  margin-right: -240px;
}

.l-space-negative-top-30 {
  margin-top: -240px;
}

.l-space-negative-bottom-30 {
  margin-bottom: -240px;
}

.l-space-vertical-30 {
  margin-top: 240px;
  margin-bottom: 240px;
}

.l-space-horizontal-30 {
  margin-left: 240px;
  margin-right: 240px;
}

.l-width-30 {
  width: 240px;
}

.l-max-width-30 {
  max-width: 240px;
}

.l-pad-31 {
  padding: 248px;
}

.l-pad-left-31 {
  padding-left: 248px;
}

.l-pad-right-31 {
  padding-right: 248px;
}

.l-pad-top-31 {
  padding-top: 248px;
}

.l-pad-bottom-31 {
  padding-bottom: 248px;
}

.l-pad-vertical-31 {
  padding-top: 248px;
  padding-bottom: 248px;
}

.l-pad-horizontal-31 {
  padding-left: 248px;
  padding-right: 248px;
}

.l-space-31 {
  margin: 248px;
}

.l-space-left-31 {
  margin-left: 248px;
}

.l-space-right-31 {
  margin-right: 248px;
}

.l-space-top-31 {
  margin-top: 248px;
}

.l-space-bottom-31 {
  margin-bottom: 248px;
}

.l-space-negative-left-31 {
  margin-left: -248px;
}

.l-space-negative-right-31 {
  margin-right: -248px;
}

.l-space-negative-top-31 {
  margin-top: -248px;
}

.l-space-negative-bottom-31 {
  margin-bottom: -248px;
}

.l-space-vertical-31 {
  margin-top: 248px;
  margin-bottom: 248px;
}

.l-space-horizontal-31 {
  margin-left: 248px;
  margin-right: 248px;
}

.l-width-31 {
  width: 248px;
}

.l-max-width-31 {
  max-width: 248px;
}

.l-pad-32 {
  padding: 256px;
}

.l-pad-left-32 {
  padding-left: 256px;
}

.l-pad-right-32 {
  padding-right: 256px;
}

.l-pad-top-32 {
  padding-top: 256px;
}

.l-pad-bottom-32 {
  padding-bottom: 256px;
}

.l-pad-vertical-32 {
  padding-top: 256px;
  padding-bottom: 256px;
}

.l-pad-horizontal-32 {
  padding-left: 256px;
  padding-right: 256px;
}

.l-space-32 {
  margin: 256px;
}

.l-space-left-32 {
  margin-left: 256px;
}

.l-space-right-32 {
  margin-right: 256px;
}

.l-space-top-32 {
  margin-top: 256px;
}

.l-space-bottom-32 {
  margin-bottom: 256px;
}

.l-space-negative-left-32 {
  margin-left: -256px;
}

.l-space-negative-right-32 {
  margin-right: -256px;
}

.l-space-negative-top-32 {
  margin-top: -256px;
}

.l-space-negative-bottom-32 {
  margin-bottom: -256px;
}

.l-space-vertical-32 {
  margin-top: 256px;
  margin-bottom: 256px;
}

.l-space-horizontal-32 {
  margin-left: 256px;
  margin-right: 256px;
}

.l-width-32 {
  width: 256px;
}

.l-max-width-32 {
  max-width: 256px;
}

.l-pad-33 {
  padding: 264px;
}

.l-pad-left-33 {
  padding-left: 264px;
}

.l-pad-right-33 {
  padding-right: 264px;
}

.l-pad-top-33 {
  padding-top: 264px;
}

.l-pad-bottom-33 {
  padding-bottom: 264px;
}

.l-pad-vertical-33 {
  padding-top: 264px;
  padding-bottom: 264px;
}

.l-pad-horizontal-33 {
  padding-left: 264px;
  padding-right: 264px;
}

.l-space-33 {
  margin: 264px;
}

.l-space-left-33 {
  margin-left: 264px;
}

.l-space-right-33 {
  margin-right: 264px;
}

.l-space-top-33 {
  margin-top: 264px;
}

.l-space-bottom-33 {
  margin-bottom: 264px;
}

.l-space-negative-left-33 {
  margin-left: -264px;
}

.l-space-negative-right-33 {
  margin-right: -264px;
}

.l-space-negative-top-33 {
  margin-top: -264px;
}

.l-space-negative-bottom-33 {
  margin-bottom: -264px;
}

.l-space-vertical-33 {
  margin-top: 264px;
  margin-bottom: 264px;
}

.l-space-horizontal-33 {
  margin-left: 264px;
  margin-right: 264px;
}

.l-width-33 {
  width: 264px;
}

.l-max-width-33 {
  max-width: 264px;
}

.l-pad-34 {
  padding: 272px;
}

.l-pad-left-34 {
  padding-left: 272px;
}

.l-pad-right-34 {
  padding-right: 272px;
}

.l-pad-top-34 {
  padding-top: 272px;
}

.l-pad-bottom-34 {
  padding-bottom: 272px;
}

.l-pad-vertical-34 {
  padding-top: 272px;
  padding-bottom: 272px;
}

.l-pad-horizontal-34 {
  padding-left: 272px;
  padding-right: 272px;
}

.l-space-34 {
  margin: 272px;
}

.l-space-left-34 {
  margin-left: 272px;
}

.l-space-right-34 {
  margin-right: 272px;
}

.l-space-top-34 {
  margin-top: 272px;
}

.l-space-bottom-34 {
  margin-bottom: 272px;
}

.l-space-negative-left-34 {
  margin-left: -272px;
}

.l-space-negative-right-34 {
  margin-right: -272px;
}

.l-space-negative-top-34 {
  margin-top: -272px;
}

.l-space-negative-bottom-34 {
  margin-bottom: -272px;
}

.l-space-vertical-34 {
  margin-top: 272px;
  margin-bottom: 272px;
}

.l-space-horizontal-34 {
  margin-left: 272px;
  margin-right: 272px;
}

.l-width-34 {
  width: 272px;
}

.l-max-width-34 {
  max-width: 272px;
}

.l-pad-35 {
  padding: 280px;
}

.l-pad-left-35 {
  padding-left: 280px;
}

.l-pad-right-35 {
  padding-right: 280px;
}

.l-pad-top-35 {
  padding-top: 280px;
}

.l-pad-bottom-35 {
  padding-bottom: 280px;
}

.l-pad-vertical-35 {
  padding-top: 280px;
  padding-bottom: 280px;
}

.l-pad-horizontal-35 {
  padding-left: 280px;
  padding-right: 280px;
}

.l-space-35 {
  margin: 280px;
}

.l-space-left-35 {
  margin-left: 280px;
}

.l-space-right-35 {
  margin-right: 280px;
}

.l-space-top-35 {
  margin-top: 280px;
}

.l-space-bottom-35 {
  margin-bottom: 280px;
}

.l-space-negative-left-35 {
  margin-left: -280px;
}

.l-space-negative-right-35 {
  margin-right: -280px;
}

.l-space-negative-top-35 {
  margin-top: -280px;
}

.l-space-negative-bottom-35 {
  margin-bottom: -280px;
}

.l-space-vertical-35 {
  margin-top: 280px;
  margin-bottom: 280px;
}

.l-space-horizontal-35 {
  margin-left: 280px;
  margin-right: 280px;
}

.l-width-35 {
  width: 280px;
}

.l-max-width-35 {
  max-width: 280px;
}

.l-pad-36 {
  padding: 288px;
}

.l-pad-left-36 {
  padding-left: 288px;
}

.l-pad-right-36 {
  padding-right: 288px;
}

.l-pad-top-36 {
  padding-top: 288px;
}

.l-pad-bottom-36 {
  padding-bottom: 288px;
}

.l-pad-vertical-36 {
  padding-top: 288px;
  padding-bottom: 288px;
}

.l-pad-horizontal-36 {
  padding-left: 288px;
  padding-right: 288px;
}

.l-space-36 {
  margin: 288px;
}

.l-space-left-36 {
  margin-left: 288px;
}

.l-space-right-36 {
  margin-right: 288px;
}

.l-space-top-36 {
  margin-top: 288px;
}

.l-space-bottom-36 {
  margin-bottom: 288px;
}

.l-space-negative-left-36 {
  margin-left: -288px;
}

.l-space-negative-right-36 {
  margin-right: -288px;
}

.l-space-negative-top-36 {
  margin-top: -288px;
}

.l-space-negative-bottom-36 {
  margin-bottom: -288px;
}

.l-space-vertical-36 {
  margin-top: 288px;
  margin-bottom: 288px;
}

.l-space-horizontal-36 {
  margin-left: 288px;
  margin-right: 288px;
}

.l-width-36 {
  width: 288px;
}

.l-max-width-36 {
  max-width: 288px;
}

.l-pad-37 {
  padding: 296px;
}

.l-pad-left-37 {
  padding-left: 296px;
}

.l-pad-right-37 {
  padding-right: 296px;
}

.l-pad-top-37 {
  padding-top: 296px;
}

.l-pad-bottom-37 {
  padding-bottom: 296px;
}

.l-pad-vertical-37 {
  padding-top: 296px;
  padding-bottom: 296px;
}

.l-pad-horizontal-37 {
  padding-left: 296px;
  padding-right: 296px;
}

.l-space-37 {
  margin: 296px;
}

.l-space-left-37 {
  margin-left: 296px;
}

.l-space-right-37 {
  margin-right: 296px;
}

.l-space-top-37 {
  margin-top: 296px;
}

.l-space-bottom-37 {
  margin-bottom: 296px;
}

.l-space-negative-left-37 {
  margin-left: -296px;
}

.l-space-negative-right-37 {
  margin-right: -296px;
}

.l-space-negative-top-37 {
  margin-top: -296px;
}

.l-space-negative-bottom-37 {
  margin-bottom: -296px;
}

.l-space-vertical-37 {
  margin-top: 296px;
  margin-bottom: 296px;
}

.l-space-horizontal-37 {
  margin-left: 296px;
  margin-right: 296px;
}

.l-width-37 {
  width: 296px;
}

.l-max-width-37 {
  max-width: 296px;
}

.l-pad-38 {
  padding: 304px;
}

.l-pad-left-38 {
  padding-left: 304px;
}

.l-pad-right-38 {
  padding-right: 304px;
}

.l-pad-top-38 {
  padding-top: 304px;
}

.l-pad-bottom-38 {
  padding-bottom: 304px;
}

.l-pad-vertical-38 {
  padding-top: 304px;
  padding-bottom: 304px;
}

.l-pad-horizontal-38 {
  padding-left: 304px;
  padding-right: 304px;
}

.l-space-38 {
  margin: 304px;
}

.l-space-left-38 {
  margin-left: 304px;
}

.l-space-right-38 {
  margin-right: 304px;
}

.l-space-top-38 {
  margin-top: 304px;
}

.l-space-bottom-38 {
  margin-bottom: 304px;
}

.l-space-negative-left-38 {
  margin-left: -304px;
}

.l-space-negative-right-38 {
  margin-right: -304px;
}

.l-space-negative-top-38 {
  margin-top: -304px;
}

.l-space-negative-bottom-38 {
  margin-bottom: -304px;
}

.l-space-vertical-38 {
  margin-top: 304px;
  margin-bottom: 304px;
}

.l-space-horizontal-38 {
  margin-left: 304px;
  margin-right: 304px;
}

.l-width-38 {
  width: 304px;
}

.l-max-width-38 {
  max-width: 304px;
}

.l-pad-39 {
  padding: 312px;
}

.l-pad-left-39 {
  padding-left: 312px;
}

.l-pad-right-39 {
  padding-right: 312px;
}

.l-pad-top-39 {
  padding-top: 312px;
}

.l-pad-bottom-39 {
  padding-bottom: 312px;
}

.l-pad-vertical-39 {
  padding-top: 312px;
  padding-bottom: 312px;
}

.l-pad-horizontal-39 {
  padding-left: 312px;
  padding-right: 312px;
}

.l-space-39 {
  margin: 312px;
}

.l-space-left-39 {
  margin-left: 312px;
}

.l-space-right-39 {
  margin-right: 312px;
}

.l-space-top-39 {
  margin-top: 312px;
}

.l-space-bottom-39 {
  margin-bottom: 312px;
}

.l-space-negative-left-39 {
  margin-left: -312px;
}

.l-space-negative-right-39 {
  margin-right: -312px;
}

.l-space-negative-top-39 {
  margin-top: -312px;
}

.l-space-negative-bottom-39 {
  margin-bottom: -312px;
}

.l-space-vertical-39 {
  margin-top: 312px;
  margin-bottom: 312px;
}

.l-space-horizontal-39 {
  margin-left: 312px;
  margin-right: 312px;
}

.l-width-39 {
  width: 312px;
}

.l-max-width-39 {
  max-width: 312px;
}

.l-pad-40 {
  padding: 320px;
}

.l-pad-left-40 {
  padding-left: 320px;
}

.l-pad-right-40 {
  padding-right: 320px;
}

.l-pad-top-40 {
  padding-top: 320px;
}

.l-pad-bottom-40 {
  padding-bottom: 320px;
}

.l-pad-vertical-40 {
  padding-top: 320px;
  padding-bottom: 320px;
}

.l-pad-horizontal-40 {
  padding-left: 320px;
  padding-right: 320px;
}

.l-space-40 {
  margin: 320px;
}

.l-space-left-40 {
  margin-left: 320px;
}

.l-space-right-40 {
  margin-right: 320px;
}

.l-space-top-40 {
  margin-top: 320px;
}

.l-space-bottom-40 {
  margin-bottom: 320px;
}

.l-space-negative-left-40 {
  margin-left: -320px;
}

.l-space-negative-right-40 {
  margin-right: -320px;
}

.l-space-negative-top-40 {
  margin-top: -320px;
}

.l-space-negative-bottom-40 {
  margin-bottom: -320px;
}

.l-space-vertical-40 {
  margin-top: 320px;
  margin-bottom: 320px;
}

.l-space-horizontal-40 {
  margin-left: 320px;
  margin-right: 320px;
}

.l-width-40 {
  width: 320px;
}

.l-max-width-40 {
  max-width: 320px;
}

.l-pad-41 {
  padding: 328px;
}

.l-pad-left-41 {
  padding-left: 328px;
}

.l-pad-right-41 {
  padding-right: 328px;
}

.l-pad-top-41 {
  padding-top: 328px;
}

.l-pad-bottom-41 {
  padding-bottom: 328px;
}

.l-pad-vertical-41 {
  padding-top: 328px;
  padding-bottom: 328px;
}

.l-pad-horizontal-41 {
  padding-left: 328px;
  padding-right: 328px;
}

.l-space-41 {
  margin: 328px;
}

.l-space-left-41 {
  margin-left: 328px;
}

.l-space-right-41 {
  margin-right: 328px;
}

.l-space-top-41 {
  margin-top: 328px;
}

.l-space-bottom-41 {
  margin-bottom: 328px;
}

.l-space-negative-left-41 {
  margin-left: -328px;
}

.l-space-negative-right-41 {
  margin-right: -328px;
}

.l-space-negative-top-41 {
  margin-top: -328px;
}

.l-space-negative-bottom-41 {
  margin-bottom: -328px;
}

.l-space-vertical-41 {
  margin-top: 328px;
  margin-bottom: 328px;
}

.l-space-horizontal-41 {
  margin-left: 328px;
  margin-right: 328px;
}

.l-width-41 {
  width: 328px;
}

.l-max-width-41 {
  max-width: 328px;
}

.l-pad-42 {
  padding: 336px;
}

.l-pad-left-42 {
  padding-left: 336px;
}

.l-pad-right-42 {
  padding-right: 336px;
}

.l-pad-top-42 {
  padding-top: 336px;
}

.l-pad-bottom-42 {
  padding-bottom: 336px;
}

.l-pad-vertical-42 {
  padding-top: 336px;
  padding-bottom: 336px;
}

.l-pad-horizontal-42 {
  padding-left: 336px;
  padding-right: 336px;
}

.l-space-42 {
  margin: 336px;
}

.l-space-left-42 {
  margin-left: 336px;
}

.l-space-right-42 {
  margin-right: 336px;
}

.l-space-top-42 {
  margin-top: 336px;
}

.l-space-bottom-42 {
  margin-bottom: 336px;
}

.l-space-negative-left-42 {
  margin-left: -336px;
}

.l-space-negative-right-42 {
  margin-right: -336px;
}

.l-space-negative-top-42 {
  margin-top: -336px;
}

.l-space-negative-bottom-42 {
  margin-bottom: -336px;
}

.l-space-vertical-42 {
  margin-top: 336px;
  margin-bottom: 336px;
}

.l-space-horizontal-42 {
  margin-left: 336px;
  margin-right: 336px;
}

.l-width-42 {
  width: 336px;
}

.l-max-width-42 {
  max-width: 336px;
}

.l-pad-43 {
  padding: 344px;
}

.l-pad-left-43 {
  padding-left: 344px;
}

.l-pad-right-43 {
  padding-right: 344px;
}

.l-pad-top-43 {
  padding-top: 344px;
}

.l-pad-bottom-43 {
  padding-bottom: 344px;
}

.l-pad-vertical-43 {
  padding-top: 344px;
  padding-bottom: 344px;
}

.l-pad-horizontal-43 {
  padding-left: 344px;
  padding-right: 344px;
}

.l-space-43 {
  margin: 344px;
}

.l-space-left-43 {
  margin-left: 344px;
}

.l-space-right-43 {
  margin-right: 344px;
}

.l-space-top-43 {
  margin-top: 344px;
}

.l-space-bottom-43 {
  margin-bottom: 344px;
}

.l-space-negative-left-43 {
  margin-left: -344px;
}

.l-space-negative-right-43 {
  margin-right: -344px;
}

.l-space-negative-top-43 {
  margin-top: -344px;
}

.l-space-negative-bottom-43 {
  margin-bottom: -344px;
}

.l-space-vertical-43 {
  margin-top: 344px;
  margin-bottom: 344px;
}

.l-space-horizontal-43 {
  margin-left: 344px;
  margin-right: 344px;
}

.l-width-43 {
  width: 344px;
}

.l-max-width-43 {
  max-width: 344px;
}

.l-pad-44 {
  padding: 352px;
}

.l-pad-left-44 {
  padding-left: 352px;
}

.l-pad-right-44 {
  padding-right: 352px;
}

.l-pad-top-44 {
  padding-top: 352px;
}

.l-pad-bottom-44 {
  padding-bottom: 352px;
}

.l-pad-vertical-44 {
  padding-top: 352px;
  padding-bottom: 352px;
}

.l-pad-horizontal-44 {
  padding-left: 352px;
  padding-right: 352px;
}

.l-space-44 {
  margin: 352px;
}

.l-space-left-44 {
  margin-left: 352px;
}

.l-space-right-44 {
  margin-right: 352px;
}

.l-space-top-44 {
  margin-top: 352px;
}

.l-space-bottom-44 {
  margin-bottom: 352px;
}

.l-space-negative-left-44 {
  margin-left: -352px;
}

.l-space-negative-right-44 {
  margin-right: -352px;
}

.l-space-negative-top-44 {
  margin-top: -352px;
}

.l-space-negative-bottom-44 {
  margin-bottom: -352px;
}

.l-space-vertical-44 {
  margin-top: 352px;
  margin-bottom: 352px;
}

.l-space-horizontal-44 {
  margin-left: 352px;
  margin-right: 352px;
}

.l-width-44 {
  width: 352px;
}

.l-max-width-44 {
  max-width: 352px;
}

.l-pad-45 {
  padding: 360px;
}

.l-pad-left-45 {
  padding-left: 360px;
}

.l-pad-right-45 {
  padding-right: 360px;
}

.l-pad-top-45 {
  padding-top: 360px;
}

.l-pad-bottom-45 {
  padding-bottom: 360px;
}

.l-pad-vertical-45 {
  padding-top: 360px;
  padding-bottom: 360px;
}

.l-pad-horizontal-45 {
  padding-left: 360px;
  padding-right: 360px;
}

.l-space-45 {
  margin: 360px;
}

.l-space-left-45 {
  margin-left: 360px;
}

.l-space-right-45 {
  margin-right: 360px;
}

.l-space-top-45 {
  margin-top: 360px;
}

.l-space-bottom-45 {
  margin-bottom: 360px;
}

.l-space-negative-left-45 {
  margin-left: -360px;
}

.l-space-negative-right-45 {
  margin-right: -360px;
}

.l-space-negative-top-45 {
  margin-top: -360px;
}

.l-space-negative-bottom-45 {
  margin-bottom: -360px;
}

.l-space-vertical-45 {
  margin-top: 360px;
  margin-bottom: 360px;
}

.l-space-horizontal-45 {
  margin-left: 360px;
  margin-right: 360px;
}

.l-width-45 {
  width: 360px;
}

.l-max-width-45 {
  max-width: 360px;
}

.l-pad-46 {
  padding: 368px;
}

.l-pad-left-46 {
  padding-left: 368px;
}

.l-pad-right-46 {
  padding-right: 368px;
}

.l-pad-top-46 {
  padding-top: 368px;
}

.l-pad-bottom-46 {
  padding-bottom: 368px;
}

.l-pad-vertical-46 {
  padding-top: 368px;
  padding-bottom: 368px;
}

.l-pad-horizontal-46 {
  padding-left: 368px;
  padding-right: 368px;
}

.l-space-46 {
  margin: 368px;
}

.l-space-left-46 {
  margin-left: 368px;
}

.l-space-right-46 {
  margin-right: 368px;
}

.l-space-top-46 {
  margin-top: 368px;
}

.l-space-bottom-46 {
  margin-bottom: 368px;
}

.l-space-negative-left-46 {
  margin-left: -368px;
}

.l-space-negative-right-46 {
  margin-right: -368px;
}

.l-space-negative-top-46 {
  margin-top: -368px;
}

.l-space-negative-bottom-46 {
  margin-bottom: -368px;
}

.l-space-vertical-46 {
  margin-top: 368px;
  margin-bottom: 368px;
}

.l-space-horizontal-46 {
  margin-left: 368px;
  margin-right: 368px;
}

.l-width-46 {
  width: 368px;
}

.l-max-width-46 {
  max-width: 368px;
}

.l-pad-47 {
  padding: 376px;
}

.l-pad-left-47 {
  padding-left: 376px;
}

.l-pad-right-47 {
  padding-right: 376px;
}

.l-pad-top-47 {
  padding-top: 376px;
}

.l-pad-bottom-47 {
  padding-bottom: 376px;
}

.l-pad-vertical-47 {
  padding-top: 376px;
  padding-bottom: 376px;
}

.l-pad-horizontal-47 {
  padding-left: 376px;
  padding-right: 376px;
}

.l-space-47 {
  margin: 376px;
}

.l-space-left-47 {
  margin-left: 376px;
}

.l-space-right-47 {
  margin-right: 376px;
}

.l-space-top-47 {
  margin-top: 376px;
}

.l-space-bottom-47 {
  margin-bottom: 376px;
}

.l-space-negative-left-47 {
  margin-left: -376px;
}

.l-space-negative-right-47 {
  margin-right: -376px;
}

.l-space-negative-top-47 {
  margin-top: -376px;
}

.l-space-negative-bottom-47 {
  margin-bottom: -376px;
}

.l-space-vertical-47 {
  margin-top: 376px;
  margin-bottom: 376px;
}

.l-space-horizontal-47 {
  margin-left: 376px;
  margin-right: 376px;
}

.l-width-47 {
  width: 376px;
}

.l-max-width-47 {
  max-width: 376px;
}

.l-pad-48 {
  padding: 384px;
}

.l-pad-left-48 {
  padding-left: 384px;
}

.l-pad-right-48 {
  padding-right: 384px;
}

.l-pad-top-48 {
  padding-top: 384px;
}

.l-pad-bottom-48 {
  padding-bottom: 384px;
}

.l-pad-vertical-48 {
  padding-top: 384px;
  padding-bottom: 384px;
}

.l-pad-horizontal-48 {
  padding-left: 384px;
  padding-right: 384px;
}

.l-space-48 {
  margin: 384px;
}

.l-space-left-48 {
  margin-left: 384px;
}

.l-space-right-48 {
  margin-right: 384px;
}

.l-space-top-48 {
  margin-top: 384px;
}

.l-space-bottom-48 {
  margin-bottom: 384px;
}

.l-space-negative-left-48 {
  margin-left: -384px;
}

.l-space-negative-right-48 {
  margin-right: -384px;
}

.l-space-negative-top-48 {
  margin-top: -384px;
}

.l-space-negative-bottom-48 {
  margin-bottom: -384px;
}

.l-space-vertical-48 {
  margin-top: 384px;
  margin-bottom: 384px;
}

.l-space-horizontal-48 {
  margin-left: 384px;
  margin-right: 384px;
}

.l-width-48 {
  width: 384px;
}

.l-max-width-48 {
  max-width: 384px;
}

.l-pad-49 {
  padding: 392px;
}

.l-pad-left-49 {
  padding-left: 392px;
}

.l-pad-right-49 {
  padding-right: 392px;
}

.l-pad-top-49 {
  padding-top: 392px;
}

.l-pad-bottom-49 {
  padding-bottom: 392px;
}

.l-pad-vertical-49 {
  padding-top: 392px;
  padding-bottom: 392px;
}

.l-pad-horizontal-49 {
  padding-left: 392px;
  padding-right: 392px;
}

.l-space-49 {
  margin: 392px;
}

.l-space-left-49 {
  margin-left: 392px;
}

.l-space-right-49 {
  margin-right: 392px;
}

.l-space-top-49 {
  margin-top: 392px;
}

.l-space-bottom-49 {
  margin-bottom: 392px;
}

.l-space-negative-left-49 {
  margin-left: -392px;
}

.l-space-negative-right-49 {
  margin-right: -392px;
}

.l-space-negative-top-49 {
  margin-top: -392px;
}

.l-space-negative-bottom-49 {
  margin-bottom: -392px;
}

.l-space-vertical-49 {
  margin-top: 392px;
  margin-bottom: 392px;
}

.l-space-horizontal-49 {
  margin-left: 392px;
  margin-right: 392px;
}

.l-width-49 {
  width: 392px;
}

.l-max-width-49 {
  max-width: 392px;
}

.l-pad-50 {
  padding: 400px;
}

.l-pad-left-50 {
  padding-left: 400px;
}

.l-pad-right-50 {
  padding-right: 400px;
}

.l-pad-top-50 {
  padding-top: 400px;
}

.l-pad-bottom-50 {
  padding-bottom: 400px;
}

.l-pad-vertical-50 {
  padding-top: 400px;
  padding-bottom: 400px;
}

.l-pad-horizontal-50 {
  padding-left: 400px;
  padding-right: 400px;
}

.l-space-50 {
  margin: 400px;
}

.l-space-left-50 {
  margin-left: 400px;
}

.l-space-right-50 {
  margin-right: 400px;
}

.l-space-top-50 {
  margin-top: 400px;
}

.l-space-bottom-50 {
  margin-bottom: 400px;
}

.l-space-negative-left-50 {
  margin-left: -400px;
}

.l-space-negative-right-50 {
  margin-right: -400px;
}

.l-space-negative-top-50 {
  margin-top: -400px;
}

.l-space-negative-bottom-50 {
  margin-bottom: -400px;
}

.l-space-vertical-50 {
  margin-top: 400px;
  margin-bottom: 400px;
}

.l-space-horizontal-50 {
  margin-left: 400px;
  margin-right: 400px;
}

.l-width-50 {
  width: 400px;
}

.l-max-width-50 {
  max-width: 400px;
}

.left {
  padding-right: 25px;
}

.right {
  padding-left: 25px;
}

.justify-content-md-center {
  justify-content: center !important;
}

.d-md-flex {
  display: flex !important;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.aside {
  box-sizing: border-box;
  z-index: 1;
  background: #fff;
  border: none;
  border-radius: 16px;
  padding: 32px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  box-shadow: 0 4px 16px #62138e1f, 0 4px 16px #0000001f;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .aside {
    margin-bottom: 32px;
    position: relative;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .aside {
    margin-bottom: 16px;
  }
}

.aside figure {
  text-align: center;
}

.aside figure img {
  max-height: 144px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.aside h3 {
  color: #0e0e0f8f;
  margin: 32px 0 8px;
  font-weight: bold;
}

.aside h4 {
  margin: 0 0 8px;
}

.button, .mktoForm .mktoButton {
  appearance: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  height: 40px;
  text-align: center;
  white-space: nowrap;
  background: #62138e;
  border: none;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  text-decoration: none;
  transition: all .5s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.button:hover, .mktoForm .mktoButton:hover {
  color: #fff;
  background: #62138e;
  box-shadow: 4px 4px 16px #5ba3b052, -4px -4px 16px #62138e3d;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .button, .mktoForm .mktoButton {
    width: 100%;
    margin: 8px 0;
    padding: 0;
    display: block;
  }
}

.button.is-large, .mktoForm .is-large.mktoButton {
  height: 48px;
  padding: 0 48px;
  font-size: 16px;
  line-height: 48px;
}

.button.is-small, .mktoForm .is-small.mktoButton {
  height: 32px;
  font-size: 12px;
  line-height: 32px;
}

.button.is-gradient, .mktoForm .is-gradient.mktoButton, .hero.is-case-study .hero-content h1.button, .hero.is-case-study .hero-content .mktoForm h1.mktoButton, .mktoForm .hero.is-case-study .hero-content h1.mktoButton, .text-auto-format h2.button, .text-auto-format .mktoForm h2.mktoButton, .mktoForm .text-auto-format h2.mktoButton, .aside h4.button, .aside .mktoForm h4.mktoButton, .mktoForm .aside h4.mktoButton {
  background: linear-gradient(-90deg, #5ba3b0 0%, #62138e 60%, #582f6b 100%);
}

.button.is-gradient:hover, .mktoForm .is-gradient.mktoButton:hover, .hero.is-case-study .hero-content h1.button:hover, .hero.is-case-study .hero-content .mktoForm h1.mktoButton:hover, .mktoForm .hero.is-case-study .hero-content h1.mktoButton:hover, .text-auto-format h2.button:hover, .text-auto-format .mktoForm h2.mktoButton:hover, .mktoForm .text-auto-format h2.mktoButton:hover, .aside h4.button:hover, .aside .mktoForm h4.mktoButton:hover, .mktoForm .aside h4.mktoButton:hover {
  color: #fff;
  box-shadow: 4px 4px 16px #5ba3b052, -4px -4px 16px #62138e3d;
}

.button.is-link, .mktoForm .is-link.mktoButton {
  background: none;
  border: 2px solid #ffffff3d;
}

.button.is-link:hover, .mktoForm .is-link.mktoButton:hover {
  box-shadow: none;
  color: #fff;
  border: 2px solid #fff;
}

.button.is-light, .mktoForm .is-light.mktoButton {
  color: #62138e;
  background: #fff;
}

.button.is-light:hover, .mktoForm .is-light.mktoButton:hover {
  color: #a122cc;
  box-shadow: 4px 4px 16px #ffffff52, -4px -4px 16px #e2e4ea3d;
}

.button.is-block, .mktoForm .is-block.mktoButton {
  width: 100%;
  display: block;
}

.button.is-cta, .mktoForm .is-cta.mktoButton {
  margin-top: 40px;
  margin-bottom: 40px;
}

.button.is-cta-bottom, .mktoForm .is-cta-bottom.mktoButton {
  margin-top: 40px;
}

.button.is-cta-top, .mktoForm .is-cta-top.mktoButton {
  margin-bottom: 40px;
}

.btn-outline-primary, .btn-primary {
  appearance: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  height: 40px;
  text-align: center;
  white-space: nowrap;
  background: #62138e;
  border: none;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  text-decoration: none;
  transition: all .5s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.card {
  box-sizing: border-box;
  min-height: 320px;
  background: #fff;
  border: none;
  border-radius: 16px;
  margin: 16px;
  padding: 188px 32px 32px;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 16px #62138e1f, 0 4px 16px #0000001f;
  height: 95% !important;
}

.card:hover {
  transform: scale(1);
  box-shadow: 0 8px 32px #62138e1f, 0 8px 32px #0000003d;
}

.card figure {
  height: 164px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.card figure img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card figure em {
  color: #fff;
  margin: 32px 0 8px;
  font-size: 64px;
  position: relative;
}

.card time, .card h5 {
  color: #8c9198;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
}

.card h4, .card h4 a {
  color: #0e0e0f;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}

.card p {
  color: #8c9198;
  margin: 16px 0 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
}

.card a:after {
  z-index: 1000;
  content: "";
  position: absolute;
  inset: 0;
}

.card.is-case-study figure {
  justify-content: center;
  align-items: center;
  display: flex;
}

.card.is-case-study figure img {
  width: 70%;
  position: relative;
}

.card.is-flat {
  box-shadow: none;
  border: 1px solid #e2e4ea;
  padding: 248px 32px 32px;
}

.card.is-flat:hover {
  box-shadow: none;
  transform: scale(1);
}

.card.is-flat figure {
  height: 224px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card.is-flat figure img {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.card.is-flat-no-image {
  box-shadow: none;
  min-height: 100px;
  border: 1px solid #e2e4ea;
  padding: 32px;
}

.card.is-flat-no-image:hover {
  box-shadow: none;
  transform: scale(1);
}

.card.is-event {
  min-height: 260px;
  background: linear-gradient(-90deg, #5ba3b0 0%, #62138e 60%, #582f6b 100%);
  padding: 32px;
  position: relative;
}

.card.is-event.is-webinar {
  background: linear-gradient(-60deg, #db5b62 0%, #62138e 50%, #582f6b 100%);
}

.card.is-event.is-tradeshow {
  background: linear-gradient(140deg, #305b5e 40%, #5ba3b0 100%);
}

.card.is-event i {
  text-shadow: 0 4px 16px #0000003d;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  z-index: 0;
  margin: -32px 0 0 -32px;
  font-size: 64px;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.card.is-event time {
  box-shadow: #0000003d;
  color: #fff;
  text-transform: uppercase;
  border-left: 4px solid #fff;
  margin-bottom: 24px;
  padding-left: 8px;
  font-style: italic;
  font-weight: bold;
  line-height: 16px;
  display: inline-block;
}

.card.is-event h6 {
  opacity: 0;
  text-shadow: 0 8px 32px #000000de;
  text-align: center;
  z-index: 0;
  margin-top: 80px;
  font-size: 32px;
  position: absolute;
  left: 0;
  right: 0;
}

.card.is-event h6, .card.is-event h5, .card.is-event h4, .card.is-event h4 a {
  color: #fff;
  transition: all .3s;
}

.card.is-event h5 {
  text-transform: uppercase;
  margin-bottom: 16px;
}

.card.is-event h4, .card.is-event h4 a {
  font-size: 24px;
  line-height: 32px;
}

.card.is-event:hover h5, .card.is-event:hover h4 {
  opacity: .24;
}

.card.is-event:hover h6, .card.is-event:hover i {
  opacity: 1;
}

.card.is-partner {
  min-height: 200px;
  justify-content: center;
  align-items: center;
  padding: 32px;
  display: flex;
  box-shadow: 0 1px 4px #0000003d;
}

.card.is-partner:hover {
  box-shadow: 0 1px 4px #0000003d;
}

.card.is-partner.has-link:hover {
  box-shadow: 0 8px 32px #62138e1f, 0 8px 32px #0000003d;
}

.card.is-partner a:after {
  border: 2px solid #62138e;
  border-radius: 16px;
}

.card.is-partner h4 {
  text-align: center;
}

.card.is-partner h4 img {
  max-width: 80%;
}

.card.is-code {
  min-height: 380px;
}

.card.is-code figure {
  text-align: center;
  display: block;
}

.card.is-code figure figcaption {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.card.is-code figure img {
  width: 64px;
  margin: 32px 0 8px;
  position: relative;
}

.card .arrow {
  justify-content: end;
  margin-top: -12px;
  padding-right: 15px;
  font-size: 25px;
  display: flex;
  position: absolute;
  right: 0;
}

.careers-table {
  max-width: 800px;
  margin: 0 auto;
}

table.careers td {
  vertical-align: baseline;
  border: 0;
  margin-top: 0;
}

table.careers td a {
  color: gray;
  font-weight: 600;
  transition: all .2s;
}

table.careers td a:hover {
  color: #62138e;
  transition: all .2s;
}

.careers-table h3.text-info-title, .careers-table .mktoFormWrapper h3, .mktoFormWrapper .careers-table h3 {
  margin-top: 30px;
  margin-bottom: 10px;
}

td.location {
  color: gray;
  text-align: right;
  font-size: 12px;
  font-weight: 600;
}

table.careers tr:nth-child(2n+1) {
  background-color: #f6f6f6;
}

table.careers tr {
  border: 1px solid #f6f6f6;
}

table.careers td {
  padding: 5px;
}

.codebox-wrapper {
  background: linear-gradient(140deg, #060110 0%, #1a0441 40%, #41185c 100%);
  border-radius: 16px;
  padding: 2px;
  box-shadow: 0 4px 32px #0000008f, -16px -16px 156px #ec787852;
}

.codebox {
  background: #0c021e;
  border-radius: 16px;
  overflow: hidden;
}

.codebox nav {
  background: #000;
  padding: 8px 0 0;
  display: block;
}

.codebox nav ul {
  margin: 0;
  padding: 8px 24px 0;
  list-style-type: none;
}

.codebox nav li {
  margin: 0 8px 0 0;
  display: inline-block;
}

.codebox nav button {
  box-sizing: border-box;
  color: #8c9198;
  cursor: pointer;
  height: 40px;
  background: none;
  border: none;
  border-bottom: 4px solid #000;
  align-items: center;
  padding: 0 8px;
  font-family: Ubuntu Mono, Courier New, Courier, monospace;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

.codebox nav button:hover, .codebox nav button:focus {
  background: #0e0e0f;
  border-color: #8c919852;
}

.codebox nav button.is-active {
  color: #fff;
  border-color: #62138e;
}

.codebox nav button img {
  height: 18px;
  margin-right: 8px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .codebox nav button img {
    display: none;
  }
}

.codebox .codebox-code {
  max-height: 500px;
  display: none;
  overflow: auto;
}

.codebox .codebox-code.is-active {
  height: 440px;
  display: block;
}

.codebox pre {
  tab-size: 0;
  background: #0c021e;
  border: none;
  margin: 0;
  padding: 0 24px 24px;
  color: #fff !important;
  background: none !important;
}

.codebox code, .codebox code.hljs {
  background: #0c021e;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
}

.customer-carousel {
  max-width: 1120px;
  border-radius: 16px;
  margin: 40px auto 0;
  box-shadow: 0 8px 32px #0000003d;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .customer-carousel {
    margin-top: 0;
  }
}

.customer-carousel .slick-list {
  border-radius: 16px;
}

.customer-carousel .slide {
  box-sizing: border-box;
  height: 400px;
  max-width: 1120px;
  text-align: center;
  padding: 50px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .customer-carousel .slide {
    height: auto;
    padding: 24px;
  }
}

.customer-carousel .slide img {
  height: 100px;
  margin: 0 auto;
}

.customer-carousel .slide p {
  margin-bottom: 32px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .customer-carousel .slide p {
    font-size: 16px;
    line-height: 24px;
  }
}

.customer-carousel .slick-dots {
  justify-content: center;
  margin: 0;
  padding: 32px 0;
  list-style-type: none;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
}

.customer-carousel .slick-dots li.slick-active button {
  color: #5ba3b0;
  border-color: #5ba3b0;
}

.customer-carousel .slick-dots button {
  color: #8c9198;
  cursor: pointer;
  height: 24px;
  width: 24px;
  background: #fff;
  border: 2px solid #cfd1d7;
  border-radius: 4px;
  margin: 0 12px;
  transition: all .3s;
}

.customer-carousel .slick-dots button:hover {
  color: #62138e;
  border-color: #62138e;
}

.diagram {
  background: #f7f8fa;
  border-radius: 16px;
  margin: 60px auto 80px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .diagram {
    margin: 24px auto 40px;
  }
}

.diagram .diagram-header {
  height: 80px;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.diagram .diagram-header img {
  margin-right: 24px;
  display: inline-block;
}

.diagram .diagram-header .diagram-dropdown {
  box-sizing: border-box;
  color: #8c9198;
  height: 40px;
  border: 1px solid #e2e4ea;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.diagram .diagram-header .diagram-dropdown i {
  opacity: .56;
  margin: 0 16px;
}

.diagram .diagram-process {
  background: url("../img/graphics/data-transfer.f8583f24.webp") 50% 112px no-repeat;
  padding: 0 80px;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .diagram .diagram-process {
    padding: 0 16px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .diagram .diagram-process {
    background: url("../img/graphics/data-transfer-vertical.13e20f19.webp") 50% 148px no-repeat;
  }
}

.diagram .diagram-footer {
  height: 80px;
  padding: 0 16px;
  line-height: 80px;
}

.diagram-card {
  background: #fff;
  border: 1px solid #cfd1d7;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 8px 32px #0000003d;
}

.diagram-card.is-original {
  margin-right: 40px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .diagram-card.is-original {
    margin: 0 0 40px;
  }
}

.diagram-card.is-new {
  margin-left: 40px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .diagram-card.is-new {
    margin: 40px 0 0;
  }
}

.diagram-card header {
  height: 48px;
  background: #f7f8fa;
  border-bottom: 1px solid #e2e4ea;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.diagram-card header h3 {
  color: #0e0e0f;
  margin: 0 0 0 16px;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .diagram-card header h3 {
    font-size: 12px;
  }
}

.diagram-card header .diagram-card-version {
  box-sizing: border-box;
  color: #cfd1d7;
  height: 32px;
  border: 1px solid #e2e4ea;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.diagram-card header .diagram-card-version i {
  margin: 0 8px;
}

.diagram-card ul {
  justify-content: space-between;
  margin: 0;
  padding: 0 8px;
  list-style-type: none;
  display: flex;
}

.diagram-card ul li {
  margin: 8px;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .diagram-card ul li {
    margin: 4px;
  }
}

.diagram-card ul img {
  height: 56px;
  width: 56px;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .diagram-card ul img {
    height: 48px;
    width: 48px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .diagram-card ul img {
    height: 64px;
    width: 64px;
  }
}

.diagram-card footer {
  color: #cfd1d7;
  height: 32px;
  background: #f7f8fa;
  border-top: 1px solid #e2e4ea;
  align-items: center;
  margin-top: 8px;
  padding: 0 16px;
  font-size: 10px;
  display: flex;
}

.diagram-store {
  margin: 16px auto 0;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .diagram-store {
    visibility: hidden;
  }
}

.diagram-terminal {
  height: 250px;
  background: #000;
  border: 1px solid #0e0e0f;
  border-radius: 8px;
  margin-bottom: 48px;
  overflow: hidden;
  box-shadow: 0 8px 32px #0000008f;
}

.diagram-terminal header {
  height: 48px;
  border-bottom: 1px solid #8c91983d;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.diagram-terminal header h3 {
  color: #fff;
  margin: 0 0 0 16px;
  font-size: 14px;
  font-weight: 500;
}

.diagram-terminal header h3 i {
  color: #8c91988f;
  margin-right: 8px;
}

.diagram-terminal header .diagram-terminal-dropdown {
  box-sizing: border-box;
  color: #8c91988f;
  height: 32px;
  border: 1px solid #8c91988f;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.diagram-terminal header .diagram-terminal-dropdown i {
  margin: 0 8px;
}

.diagram-terminal pre {
  tab-size: 0;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #fff !important;
  background: none !important;
}

.diagram-terminal code, .diagram-terminal code.hljs {
  opacity: .4;
  text-align: left;
  background: #000;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.diagram-features {
  flex-wrap: wrap;
  display: flex;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .diagram-features {
    display: block;
  }
}

.diagram-features > div {
  box-sizing: border-box;
  width: 20%;
  padding: 0 8px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .diagram-features > div {
    width: auto;
    background: #f7f8fa;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px 16px 8px;
  }
}

.dropdown {
  width: 432px;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
  background: #fff;
  border-radius: 16px;
  margin-left: -216px;
  position: absolute;
  top: 96px;
  left: 50%;
  transform: translateY(-16px);
  box-shadow: 0 8px 32px #0000003d;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .dropdown {
    width: auto;
    background: #f7f8fa;
    margin: 0;
    transition: all .3s;
    display: none;
    position: relative;
    top: 0;
    left: 0;
    transform: translateY(0);
    box-shadow: inset 0 1px 3px #0000001f;
  }

  .dropdown.is-active {
    visibility: visible !important;
    opacity: 1 !important;
    display: block !important;
  }
}

.dropdown .dropdown-arrow {
  width: 0;
  height: 0;
  border: 18px solid #0000;
  border-top-width: 0;
  border-bottom: 24px solid #fff;
  margin-left: -18px;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: rotate(0);
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .dropdown .dropdown-arrow {
    display: none;
  }
}

.dropdown ul {
  margin: 0;
  padding: 16px;
  list-style-type: none;
}

.dropdown ul li {
  margin: 0 0 8px;
}

.dropdown ul a {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 8px 8px 8px 72px;
  font-weight: 400;
  text-decoration: none;
  transition: all .3s;
  display: block;
  position: relative;
}

.dropdown ul a:hover {
  background: #f7f8fa;
  border: 1px solid #5ba3b0;
}

.dropdown ul a:hover i {
  color: #5ba3b0;
}

.dropdown ul i {
  color: #cfd1d7;
  height: 48px;
  text-align: center;
  width: 48px;
  background: #f7f8fa;
  border-radius: 8px;
  line-height: 48px;
  transition: all .3s;
  position: absolute;
  top: 8px;
  left: 8px;
}

.dropdown ul strong {
  color: #0e0e0f;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  display: block;
}

.dropdown ul em {
  color: #8c9198;
  font-size: 12px;
  font-style: normal;
  line-height: 24px;
  display: block;
}

.dropdown footer {
  box-sizing: border-box;
  background: #f7f8fa;
  border-radius: 0 0 16px 16px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  display: flex;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .dropdown footer {
    background: none;
    display: block;
  }
}

.dropdown footer strong {
  color: #0e0e0f;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  display: block;
}

.dropdown footer em {
  color: #8c9198;
  font-size: 12px;
  font-style: normal;
  line-height: 24px;
  display: block;
}

.graphic-image {
  border-radius: 8px;
  box-shadow: 0 4px 32px #0000001f;
}

.graphic-any-data {
  height: 500px;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-any-data {
    height: 440px;
  }
}

.graphic-any-data img {
  z-index: 1;
  position: absolute;
}

.graphic-any-data .is-csv {
  opacity: .87;
  top: 0;
  right: 0;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-any-data .is-csv {
    height: 150px;
  }
}

.graphic-any-data .is-table {
  opacity: .87;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-any-data .is-table {
    height: 150px;
  }
}

.graphic-any-data .is-genomics {
  opacity: .87;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-any-data .is-genomics {
    height: 150px;
  }
}

.graphic-any-data .is-photos {
  margin: -156px 0 0 -220px;
  top: 50%;
  left: 50%;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-any-data .is-photos {
    height: 240px;
    margin: -100px 0 0 -170px;
  }
}

.graphic-any-data .is-video {
  opacity: .87;
  top: 0;
  left: 0;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-any-data .is-video {
    height: 160px;
  }
}

.graphic-integrations {
  height: 540px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-integrations {
    height: 440px;
  }

  .graphic-integrations .is-elephant {
    height: 240px;
  }
}

.graphic-integrations .is-jupyter {
  position: absolute;
  bottom: -60px;
  right: 50%;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-integrations .is-jupyter {
    height: 96px;
    bottom: 0;
    right: 60%;
  }
}

.graphic-integrations .is-ls {
  position: absolute;
  bottom: 120px;
  right: 0;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-integrations .is-ls {
    height: 96px;
    bottom: 60px;
  }
}

.graphic-integrations .is-spark {
  position: absolute;
  top: 0;
  left: 80px;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-integrations .is-spark {
    height: 96px;
    left: 120px;
  }
}

.graphic-integrations .is-wb {
  position: absolute;
  bottom: 200px;
  left: -40px;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-integrations .is-wb {
    height: 96px;
    left: 0;
  }
}

.graphic-integrations .is-tensorflow {
  position: absolute;
  top: 16px;
  right: 16px;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .graphic-integrations .is-tensorflow {
    height: 96px;
    top: 116px;
  }
}

.hero h1 strong, .hero h1 b, .hero h1 em {
  font-style: normal;
  font-weight: 700;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .hero h1 strong, .hero h1 b, .hero h1 em {
    font-size: 68px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .hero h1 strong, .hero h1 b, .hero h1 em {
    font-size: 68px;
  }
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .hero h1 strong, .hero h1 b, .hero h1 em {
    font-size: 48px;
  }
}

.hero.is-case-study {
  background-size: cover;
}

.hero.is-case-study .hero-inner {
  padding-bottom: 40px;
}

.hero.is-case-study .hero-content h1, .hero.is-case-study .hero-content p {
  text-shadow: 0 4px 16px #0000001f;
}

.hero.is-case-study .hero-content h1 {
  font-size: 60px;
  line-height: 70px;
}

.hero.is-case-study .hero-content p {
  font-size: 20px;
}

.icon.is-large {
  margin-bottom: 16px;
  font-size: 32px;
}

.icon.is-small {
  font-size: 16px;
}

.icon.is-gradient, .text-auto-format h2.icon, .aside h4.icon, .hero.is-case-study .hero-content h1.icon {
  background: linear-gradient(-90deg, #5ba3b0 0%, #62138e 60%, #582f6b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
}

.industry-diagram .industry-inputs, .industry-diagram .industry-outputs {
  z-index: 3;
  background: #fff;
  border: 1px solid #e2e4ea;
  border-radius: 16px;
  padding: 24px;
}

.industry-diagram .industry-model {
  z-index: 1;
  text-align: center;
  background: url("../img/graphics/data.176d4fe2.gif") top no-repeat;
}

.industry-diagram .industry-model figure {
  background: url("../img/graphics/data.176d4fe2.gif") bottom no-repeat;
  margin: 0;
  padding: 120px 0;
}

.industry-diagram .industry-model figure img {
  max-height: 400px;
  max-width: 100%;
}

.launchpad footer {
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  display: flex;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .launchpad footer {
    text-align: center;
    margin-top: 0;
    display: block;
  }

  .launchpad footer .logo {
    justify-content: center;
    margin-bottom: 24px;
  }
}

.launchpad footer ul, .launchpad footer p {
  color: #0e0e0f;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 32px;
  list-style-type: none;
}

.launchpad footer ul li, .launchpad footer p li {
  margin: 0 8px;
  display: inline-block;
}

.launchpad footer ul a, .launchpad footer p a {
  color: #0e0e0f;
  font-weight: 400;
  text-decoration: underline;
}

iframe[src*="livestorm.co"] {
  width: 100%;
  height: 650px;
  border: none;
  overflow: hidden;
}

@media (max-width: 675px) {
  iframe[src*="livestorm.co"] {
    height: 975px;
  }
}

.logo {
  color: #0e0e0f;
  align-items: center;
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.logo a {
  color: #0e0e0f;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.logo img {
  height: 48px;
  margin-top: -12px;
  margin-right: 12px;
}

.logo-bar {
  text-align: center;
  margin-top: 80px;
}

.logo-bar img {
  max-width: 100%;
  max-height: 35px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .logo-bar {
    margin: 40px 16px 0;
    padding: 0 16px;
  }
}

.pagination {
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #62138e;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

.page-link:hover {
  z-index: 2;
  color: #62138e;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #62138e;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 .25rem #62138e;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #62138e;
  border-color: #62138e;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: .375rem .75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.pricing-card {
  box-sizing: border-box;
  height: 480px;
  border-radius: 16px;
  margin-top: 24px;
  overflow: hidden;
}

.pricing-card.is-enterprise {
  position: relative;
  box-shadow: 0 4px 32px #62138e3d, 0 8px 32px #0000003d;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .pricing-card.is-enterprise .text-section-title, .pricing-card.is-enterprise .text-auto-format h3, .text-auto-format .pricing-card.is-enterprise h3 {
    font-size: 32px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .pricing-card.is-enterprise .text-section-title, .pricing-card.is-enterprise .text-auto-format h3, .text-auto-format .pricing-card.is-enterprise h3 {
    font-size: 28px;
  }
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .pricing-card.is-enterprise .text-section-title, .pricing-card.is-enterprise .text-auto-format h3, .text-auto-format .pricing-card.is-enterprise h3 {
    line-height: 22px;
  }
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .pricing-card.is-enterprise .text-paragraph, .pricing-card.is-enterprise .text-auto-format p, .text-auto-format .pricing-card.is-enterprise p, .pricing-card.is-enterprise .hero.is-case-study .hero-content p, .hero.is-case-study .hero-content .pricing-card.is-enterprise p {
    font-size: 18px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .pricing-card.is-enterprise .text-paragraph, .pricing-card.is-enterprise .text-auto-format p, .text-auto-format .pricing-card.is-enterprise p, .pricing-card.is-enterprise .hero.is-case-study .hero-content p, .hero.is-case-study .hero-content .pricing-card.is-enterprise p {
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .pricing-card.is-enterprise .text-list li, .pricing-card.is-enterprise .text-auto-format ul li, .text-auto-format .pricing-card.is-enterprise ul li {
    font-size: 18px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .pricing-card.is-enterprise .text-list li, .pricing-card.is-enterprise .text-auto-format ul li, .text-auto-format .pricing-card.is-enterprise ul li {
    font-size: 14px;
  }
}

.pricing-card.is-enterprise .is-background {
  box-sizing: border-box;
  height: 480px;
  background: url("../img/backgrounds/skyscraper.f7b1795e.png") 0 0 / 320px 490px no-repeat;
  padding: 40px 40px 0 380px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .pricing-card.is-enterprise .is-background {
    background: none;
    padding: 40px;
  }
}

.pricing-card.is-enterprise .button.is-light, .pricing-card.is-enterprise .mktoForm .is-light.mktoButton, .mktoForm .pricing-card.is-enterprise .is-light.mktoButton {
  margin-right: 3px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .pricing-card.is-enterprise .button.is-light, .pricing-card.is-enterprise .mktoForm .is-light.mktoButton, .mktoForm .pricing-card.is-enterprise .is-light.mktoButton {
    margin: 0 0 3px;
  }
}

.pricing-card.is-community {
  background: #fff;
  border: 1px solid #e2e4ea;
  padding: 40px;
  position: relative;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .pricing-card.is-community {
    height: auto;
  }
}

.pricing-card.is-community h3 {
  font-weight: 800;
}

.pricing-card.is-community .button, .pricing-card.is-community .mktoForm .mktoButton, .mktoForm .pricing-card.is-community .mktoButton {
  background: #f7f8fa;
  position: absolute;
  bottom: 40px;
  left: 40px;
  right: 40px;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .pricing-card.is-community .button, .pricing-card.is-community .mktoForm .mktoButton, .mktoForm .pricing-card.is-community .mktoButton {
    margin-top: 32px;
    display: block;
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
  }
}

.pricing-table table {
  border-collapse: collapse;
  box-sizing: border-box;
  max-width: 100%;
}

.pricing-table table .is-community, .pricing-table table .is-enterprise {
  text-align: center;
}

.pricing-table table thead .text-meta-title, .pricing-table table thead .text-auto-format h5, .text-auto-format .pricing-table table thead h5, .pricing-table table thead .text-auto-format h6, .text-auto-format .pricing-table table thead h6, .pricing-table table thead .aside h3, .aside .pricing-table table thead h3 {
  line-height: 32px;
}

.pricing-table table thead .text-info-title, .pricing-table table thead .mktoFormWrapper h3, .mktoFormWrapper .pricing-table table thead h3, .pricing-table table thead .text-auto-format h4, .text-auto-format .pricing-table table thead h4, .pricing-table table thead .aside h4, .aside .pricing-table table thead h4 {
  margin-bottom: 16px;
}

.pricing-table table thead th {
  text-align: center;
  text-transform: none;
  padding: 0 16px;
  font-weight: normal;
}

.pricing-table table thead th:first-child {
  vertical-align: bottom;
}

.pricing-table table thead th:first-child img {
  max-width: 80%;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .pricing-table table thead th {
    padding: 16px;
  }
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .pricing-table table thead th {
    vertical-align: top;
    padding: 8px;
  }

  .pricing-table table thead th h4 {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px !important;
  }

  .pricing-table table thead th p {
    font-size: 12px;
    line-height: 18px;
  }
}

.pricing-table table thead th.is-enterprise {
  background-color: #fff;
  border-top: 3px solid #62138e;
  border-left: 3px solid #62138e;
  border-right: 3px solid #62138e;
  box-shadow: 0 4px 16px #0000003d;
}

.pricing-table table tbody {
  box-shadow: 0 4px 16px #0000003d;
}

.pricing-table table tbody tr {
  overflow: hidden;
}

.pricing-table table tbody tr:first-child td:first-child {
  border-radius: 16px;
}

.pricing-table table tbody tr:nth-child(2n) {
  background: #f7f8fa;
}

.pricing-table table tbody tr:nth-child(2n+1) {
  background: #fff;
}

.pricing-table table tbody td {
  width: 33%;
  border: 1px solid #e2e4ea;
  padding: 24px 48px;
  font-weight: 500;
  line-height: 24px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .pricing-table table tbody td {
    padding: 16px;
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .pricing-table table tbody td {
    padding: 8px;
  }
}

.pricing-table table tbody td.is-enterprise {
  border-left: 3px solid #62138e;
  border-right: 3px solid #62138e;
}

.pricing-table table tbody td i {
  color: #208873;
  font-size: 24px;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .pricing-table table tbody td i {
    font-size: 18px;
  }
}

.pricing-table table tfoot td {
  padding: 24px 48px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .pricing-table table tfoot td {
    padding: 16px;
    font-size: 12px;
  }
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .pricing-table table tfoot td {
    padding: 8px;
  }
}

.pricing-table table tfoot td .button, .pricing-table table tfoot td .mktoForm .mktoButton, .mktoForm .pricing-table table tfoot td .mktoButton {
  white-space: normal;
  display: block;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .pricing-table table tfoot td .button, .pricing-table table tfoot td .mktoForm .mktoButton, .mktoForm .pricing-table table tfoot td .mktoButton {
    font-size: 12px;
  }
}

.pricing-table table tfoot td .button.is-light, .pricing-table table tfoot td .mktoForm .is-light.mktoButton, .mktoForm .pricing-table table tfoot td .is-light.mktoButton {
  background: #f7f8fa;
}

.pricing-table table tfoot td.is-enterprise {
  background-color: #fff;
  border-bottom: 3px solid #62138e;
  border-left: 3px solid #62138e;
  border-right: 3px solid #62138e;
}

.customer-quote {
  background: #fff;
  border: 2px solid #62138e;
  border-radius: 16px;
  margin: 24px 0;
  padding: 32px 32px 32px 88px;
  font-family: Montserrat, Helvetica, Arial, Lucida Grande, sans-serif;
  position: relative;
  box-shadow: 0 4px 16px #0000001f, 0 0 16px #5ba3b01f;
}

.customer-quote blockquote {
  margin: 0;
  font-size: 18px;
}

.customer-quote p {
  color: #0e0e0fde;
  padding: 0;
  font-size: 18px;
  font-weight: normal;
  line-height: 32px;
}

.customer-quote p:first-child:before {
  content: " “ ";
  color: #8c9198;
  opacity: .3;
  margin: 0;
  font-size: 180px;
  position: absolute;
  top: 64px;
  left: 16px;
}

.customer-quote p b, .customer-quote p strong {
  font-weight: normal;
}

.customer-quote .blockquote-footer {
  color: #0e0e0f8f;
  text-align: left;
  margin-top: 24px;
  font-style: italic;
}

.showcase {
  margin-top: 40px;
  display: flex;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .showcase {
    display: block;
  }

  .showcase .showcase-nav {
    background: #fff;
    border: 2px solid #62138e;
    border-bottom: none;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
  }
}

.showcase .showcase-nav ul {
  margin: 48px 0 0;
  padding: 0;
  list-style-type: none;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .showcase .showcase-nav ul {
    margin: 0;
    display: flex;
  }

  .showcase .showcase-nav ul li {
    display: inline-block;
  }
}

.showcase .showcase-nav button {
  color: #0e0e0fde;
  height: 88px;
  width: 300px;
  background: none;
  border: none;
  align-items: center;
  padding: 0 40px;
  transition: all .3s;
  display: flex;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .showcase .showcase-nav button {
    width: 248px;
    padding: 0 16px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .showcase .showcase-nav button {
    height: 48px;
    width: auto;
    padding: 0 16px;
    font-size: 12px;
  }
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .showcase .showcase-nav button {
    height: 48px;
    width: auto;
    padding: 0 16px;
    font-size: 10px;
  }
}

.showcase .showcase-nav button:hover {
  color: #62138e;
}

.showcase .showcase-nav button.is-active {
  color: #62138e;
  background: linear-gradient(90deg, #fff, #62138e1f);
}

.showcase .showcase-nav button i {
  margin-right: 16px;
  font-size: 24px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .showcase .showcase-nav button i {
    display: none;
  }
}

.showcase .showcase-content {
  background: #fff url("../img/backgrounds/grid.4a8fe855.png") 0 0;
  border: 2px solid #62138e;
  border-radius: 16px;
  padding: 48px 32px 48px 80px;
  box-shadow: 0 8px 32px #0000003d;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .showcase .showcase-content {
    padding: 32px 16px 32px 32px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .showcase .showcase-content {
    border-radius: 0 0 16px 16px;
    padding: 16px;
  }
}

.showcase .showcase-data {
  background: url("../img/backgrounds/connectors.d6efabe0.svg") 48px 88px no-repeat;
  margin: 0;
  padding-right: 40px;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .showcase .showcase-data {
    background: url("../img/backgrounds/connectors.d6efabe0.svg") 32px 64px no-repeat;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .showcase .showcase-data {
    background: url("../img/backgrounds/connectors-vertical.b94d936f.svg") 50% 56px no-repeat;
    padding: 0 0 40px;
  }
}

.showcase .showcase-data ul {
  width: 80px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .showcase .showcase-data ul {
    width: 64px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .showcase .showcase-data ul {
    width: auto;
    justify-content: space-between;
    display: flex;
  }
}

.showcase .showcase-data li {
  color: #0e0e0fde;
  height: 96px;
  text-align: center;
  width: 80px;
  background: #fff;
  border: 1px solid #f7f8fa;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 16px;
  box-shadow: 0 4px 16px #0000003d;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .showcase .showcase-data li {
    height: 80px;
    width: 64px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .showcase .showcase-data li {
    width: 30%;
    font-size: 14px;
    display: inline-block;
  }
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .showcase .showcase-data li {
    width: 30%;
    font-size: 11px;
    display: inline-block;
  }
}

.showcase .showcase-data li i {
  margin: 12px 0;
  font-size: 24px;
  display: block;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .showcase .showcase-data li i {
    margin: 8px 0;
    font-size: 16px;
  }
}

.showcase .showcase-diagram {
  align-items: center;
  padding-top: 16px;
  display: flex;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .showcase .showcase-diagram {
    display: block;
  }
}

.showcase .showcase-slide {
  display: none;
}

.showcase .showcase-slide.is-active {
  display: block;
}

.showcase .showcase-slide h3 {
  align-items: center;
  display: flex;
}

.showcase .showcase-slide h3 i {
  background: linear-gradient(-90deg, #5ba3b0 0%, #62138e 60%, #582f6b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
  margin-right: 16px;
  font-size: 32px;
}

.showcase .showcase-slide p {
  margin-top: 24px;
  font-weight: normal;
}

.showcase .showcase-slide figure {
  margin: 0;
}

.showcase .showcase-slide figure img {
  max-width: 100%;
}

.showcase .showcase-slide .showcase-highlights {
  width: 240px;
  margin-left: 32px;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .showcase .showcase-slide .showcase-highlights {
    width: 220px;
    margin-left: 16px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .showcase .showcase-slide .showcase-highlights {
    width: 100%;
    margin: 0;
  }
}

.showcase .showcase-slide .showcase-highlights li {
  align-items: flex-start;
  margin-bottom: 24px;
  display: flex;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .showcase .showcase-slide .showcase-highlights li {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
  }
}

.showcase .showcase-slide .showcase-highlights i {
  color: #208873;
  font-size: 24px;
}

.text-carousel {
  height: 64px;
  width: 100%;
  min-width: 300px;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .text-carousel {
    width: auto;
    padding: 0;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .text-carousel {
    min-width: auto;
    display: block;
  }
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .text-carousel {
    height: 148px;
    display: block;
  }
}

.text-carousel span {
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  background: linear-gradient(-90deg, #5ba3b0 0%, #62138e 60%, #582f6b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
  transition: all 5s;
  position: absolute;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .text-carousel span {
    white-space: normal;
    display: block;
  }
}

.text-carousel span.is-active {
  opacity: 1;
  visibility: visible;
  display: inline-block;
  top: 0;
  transform: translateY(0);
}

.text-carousel span.is-exiting {
  opacity: 0;
  transition: all .5s;
}

.top-nav {
  height: 96px;
  align-items: center;
  padding: 0 80px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .top-nav {
    padding: 0 16px;
  }
}

.top-nav.is-grey {
  background: #f7f8fa;
}

.top-nav .logo {
  margin-right: 80px;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .top-nav .logo {
    margin-right: 0;
    padding: 0 8px;
    font-size: 18px;
  }

  .top-nav .logo img {
    height: 32px;
  }
}

.top-nav .top-nav-open, .top-nav .top-nav-close {
  color: #8c9198;
  cursor: pointer;
  z-index: 3000;
  font-size: 24px;
  display: none;
  position: absolute;
  top: 32px;
  right: 32px;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .top-nav .top-nav-open.is-active, .top-nav .top-nav-close.is-active {
    display: block;
  }
}

.top-nav .top-nav-close {
  position: fixed;
}

.top-nav .top-nav-menu {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .top-nav .top-nav-menu {
    box-sizing: border-box;
    padding: 80px 16px 16px;
    display: none;
    overflow: auto;
  }

  .top-nav .top-nav-menu.is-active {
    z-index: 1000;
    background: #fff;
    display: block;
    position: fixed;
    inset: 0;
  }
}

.top-nav .top-nav-menu > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.top-nav .top-nav-menu > ul > li {
  margin: 0;
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .top-nav .top-nav-menu > ul > li {
    margin-bottom: 16px;
    display: block;
  }
}

.top-nav .top-nav-menu > ul > li:hover button, .top-nav .top-nav-menu > ul > li:focus button, .top-nav .top-nav-menu > ul > li:active button {
  color: #62138e;
  background: #fff;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .top-nav .top-nav-menu > ul > li:hover button, .top-nav .top-nav-menu > ul > li:focus button, .top-nav .top-nav-menu > ul > li:active button {
    background: #f7f8fa;
  }
}

.top-nav .top-nav-menu > ul > li:hover .dropdown, .top-nav .top-nav-menu > ul > li:focus .dropdown, .top-nav .top-nav-menu > ul > li:active .dropdown {
  visibility: visible;
  opacity: 1;
  display: block;
  transform: translateY(0);
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .top-nav .top-nav-menu > ul > li:hover .dropdown, .top-nav .top-nav-menu > ul > li:focus .dropdown, .top-nav .top-nav-menu > ul > li:active .dropdown {
    display: none;
  }
}

.top-nav .top-nav-menu > ul > li:hover button {
  color: #62138e;
  background: #fff;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .top-nav .top-nav-menu > ul > li:hover button {
    background: #f7f8fa;
  }
}

.top-nav .top-nav-menu > ul > li:hover .dropdown {
  visibility: visible;
  opacity: 1;
  display: block;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .top-nav .top-nav-menu > ul > li:hover .dropdown {
    display: none;
  }
}

.top-nav .top-nav-button {
  box-sizing: border-box;
  color: #0e0e0f;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 96px;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .top-nav .top-nav-button {
    padding: 0 8px;
    font-size: 12px;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .top-nav .top-nav-button {
    text-align: center;
    width: 100%;
    background: #f7f8fa;
    border-radius: 8px;
    padding: 0 24px;
    line-height: 56px;
    display: block;
  }

  .top-nav .top-nav-cta {
    height: 48px;
    margin-top: 40px;
    line-height: 48px;
  }
}

.video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.video iframe {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 4px 32px #0000003d;
}

.mktoFormWrapper {
  background: #fff;
  border: 1px solid #e2e4ea;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 4px 16px #0000001f;
}

.mktoFormWrapper h3 {
  margin-top: 0;
}

.mktoFormWrapper.is-grey {
  background: #f7f8fa;
}

.mktoFormWrapper footer {
  padding-top: 24px;
}

.mktoFormWrapper footer p, .mktoFormWrapper footer a {
  color: #8c9198;
}

.mktoForm {
  max-width: 100%;
}

.mktoForm .mktoFormRow, .mktoForm .mktoFieldWrap, .mktoForm .mktoField, .mktoForm .mktoFormCol {
  width: 100% !important;
}

.mktoForm .mktoAsterix {
  display: none !important;
}

.mktoForm .mktoFieldWrap, .mktoForm .mktoButtonRow {
  padding: 5px 0;
}

.mktoForm input, .mktoForm textarea {
  box-sizing: border-box;
  height: 48px;
  border: 1px solid #cfd1d7;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px #0000001f;
  margin-bottom: 16px !important;
  padding: 8px 16px !important;
}

.mktoForm textarea {
  height: 120px !important;
}

.mktoForm .mktoButton {
  color: #fff !important;
  cursor: pointer !important;
  background: linear-gradient(-90deg, #5ba3b0 0%, #62138e 60%, #582f6b 100%) !important;
  border: none !important;
  padding: 0 24px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  display: inline-flex !important;
}

.mktoForm .mktoButton:hover {
  color: #fff !important;
  box-shadow: 4px 4px 16px #5ba3b052, -4px -4px 16px #62138e3d !important;
}

*, :before, :after {
  box-sizing: border-box;
}

a {
  font-weight: 700;
  text-decoration: none !important;
}

.background-black {
  background: #000;
}

.background-transparent-black {
  background: #0000008f;
}

.background-grey {
  background: #f7f8fa;
}

.background-transparent-white, .hero.is-case-study .hero-inner {
  background: linear-gradient(90deg, #ffffffde 35%, #ffffff5c);
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .background-transparent-white, .hero.is-case-study .hero-inner {
    background: linear-gradient(0deg, #ffffffde 20%, #ffffff5c);
  }
}

.background-dark-1 {
  background: linear-gradient(140deg, #000 0%, #040404 40%, #65158d 80%, #bc5060 100%);
}

.background-purple {
  background: linear-gradient(140deg, #060110 0%, #1a0441 40%, #41185c 100%);
}

.background-purple-blue {
  background: linear-gradient(140deg, #582f6b 0%, #62138e 40%, #5ba3b0 100%);
}

.background-gradient-pink {
  background: linear-gradient(-60deg, #db5b62 0%, #62138e 50%, #582f6b 100%);
}

.background-gradient-purple {
  background: linear-gradient(140deg, #060110 0%, #1a0441 40%, #41185c 100%);
}

.background-gradient-blue {
  background: linear-gradient(-90deg, #5ba3b0 0%, #62138e 60%, #582f6b 100%);
}

.background-gradient-green {
  background: linear-gradient(140deg, #305b5e 40%, #5ba3b0 100%);
}

.background-gradient-dark {
  background: linear-gradient(140deg, #000 40%, #8c9198 100%);
}

.background-gradient-grey-white {
  background: linear-gradient(90deg, #f7f8fa, #fff);
}

.background-gradient-spotlight {
  background: #5d1e52;
  background: -webkit-radial-gradient(top, #5d1e52 0%, #1a0441 40%, #000 80%, #000 100%);
  background: -moz-radial-gradient(top, #5d1e52 0%, #1a0441 40%, #000 80%, #000 100%);
  background: radial-gradient(to bottom, #5d1e52 0%, #1a0441 40%, #000 80%, #000 100%);
}

.bg-lightblue {
  background: linear-gradient(90deg, #f7f8fa, #fff);
}

.background-squares {
  background: url("../img/backgrounds/squares.b2ad89ff.webp") top;
}

.background-dots {
  background: url("../img/backgrounds/dots.a19e28da.svg") top no-repeat;
}

.background-elephant-diagram {
  background: url("../img/graphics/elephant-diagram.da7a53d3.webp") 100% no-repeat;
}

@media screen and (max-width: 1400px), screen and (max-device-width: 1400px), screen and (max-width: 1400px) {
  .background-elephant-diagram {
    background-position: 100%;
    background-size: 40%;
  }
}

@media screen and (max-width: 1260px), screen and (max-device-width: 1260px), screen and (max-width: 1260px) {
  .background-elephant-diagram {
    background-position: 100%;
    background-size: 30%;
  }
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .background-elephant-diagram {
    background: none;
  }
}

.background-agbiome {
  background: url("../img/backgrounds/customers/agbiome.9d23a859.webp") center / cover no-repeat;
}

.background-arrow-grey-down {
  background: url("../img/backgrounds/grey-arrow-down.f3049663.svg") 0 0 / 100% no-repeat;
  padding-top: 164px;
}

.background-arrow-grey-down-inverse {
  background: url("../img/backgrounds/grey-arrow-down-inverse.8b397c06.svg") 0 0 / 100% no-repeat;
  padding-top: 164px;
}

.background-arrow-triple-down {
  background: url("../img/backgrounds/arrows-triple-bottom.7679e188.svg") 0 0 / 100% no-repeat;
  padding-top: 280px;
}

.background-arrow-triple {
  min-height: 800px;
  background: #000 url("../img/backgrounds/arrows-triple.db66c001.svg") center / cover;
  padding: 100px 0;
}

.background-arrow-triple-light {
  min-height: 800px;
  background: #fff url("../img/backgrounds/arrows-triple-white.94c224a8.svg") center / cover;
  padding: 100px 0;
}

.background-diagonal-dark {
  background: url("../img/backgrounds/diagonal-dark.68d4eef5.svg") 0 100% / 100% no-repeat;
  padding-bottom: 350px;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .background-diagonal-dark {
    padding-bottom: 200px;
  }
}

.background-diagonal-top {
  background: url("../img/backgrounds/diagonal-top.9f1d7d90.svg") 0 100% / 100% no-repeat;
  padding-bottom: 350px;
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
  .background-diagonal-top {
    padding-bottom: 200px;
  }
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.no-overflow {
  overflow: hidden !important;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

.is-visible-mobile {
  display: none !important;
}

@media screen and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .is-visible-mobile {
    display: block !important;
  }
}

@media screen and (max-height: 880px) {
  .is-hidden-short {
    display: none !important;
  }
}

.is-visible-short {
  display: none !important;
}

@media screen and (max-height: 880px) {
  .is-visible-short {
    display: block !important;
  }
}

.slick-slider {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  height: auto;
  border: 1px solid #0000;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*# sourceMappingURL=index.8423fc58.css.map */
