.customer-quote {
  background: $color-white;
  border-radius: 16px;
  border: 2px solid $color-primary;
  box-shadow: 0 4px 16px rgba($color: $color-black, $alpha: .12), 0 0 16px rgba($color: $color-cyan, $alpha: .12);
  font-family: $primary-font;
  padding: 32px 32px 32px 88px;
  margin: 24px 0;
  position: relative;

  blockquote {
    margin: 0;
    font-size: 18px;
  }

  p {
    color: rgba($color: $color-text, $alpha: .87);
    font-size: 18px;
    font-weight: normal;
    line-height: 32px;
    padding: 0;

    &:first-child:before {
      content: " “ ";
      color: $color-grey-4;
      opacity: .3;
      margin: 0;
      font-size: 180px;
      left: 16px;
      top: 64px;
      position: absolute;
    }

    b, strong {
      font-weight: normal;
    }
  }

  .blockquote-footer {
    color: rgba($color: $color-text, $alpha: .56);
    font-style: italic;
    text-align: left;
    margin-top: 24px;
  }
}