iframe[src*="livestorm.co"] {
    width: 100%;
    height: 650px;
    border: none;
    overflow: hidden;

    @media (max-width:675px) {
        height: 975px;
    }
}
