
.text-carousel {
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  height: 64px;
  width: 100%;
  min-width: 300px;
  position: relative;
  padding: 0;

  @include respond-to('large') {
    padding: 0;
    width: auto;
  }

  @include respond-to('medium') {
    display: block;
    min-width: auto;
  }

  @include respond-to('small') {
    display: block;
    height: 148px;
  }

  span {
    opacity: 0;
    visibility: hidden;
    transition: all 5s;
    position: absolute;
    white-space: nowrap;
    background: -webkit-linear-gradient(-90deg, #5BA3B0 0%, #62138E 60%, #582F6B 100%);
    background: linear-gradient(-90deg, #5BA3B0 0%, #62138E 60%, #582F6B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;

    @include respond-to('medium') {
      display: block;
      white-space: normal;
    }
  
    &.is-active {
      display: inline-block;
      top: 0px;
      opacity: 1;
      transform: translateY(0px);
      visibility: visible;
    }
  
    &.is-exiting {
      opacity: 0;
      transition: all .5s;
    }
  }
}