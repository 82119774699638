/*
* Layout Styles
*
* description: Styles for section layouts 
*/


.l-section {
  padding: 80px;

  @include respond-to('medium') {
    padding: 16px;
  }
}

.l-section-inner {
  margin: 0 auto;
  max-width: $layout-max-width;
}

.l-center {
  text-align: center;
}

.l-right {
  text-align: right;
}

.l-flex {
  display: flex;

  > div {
    margin: 0 16px;
  }
}

.l-relative {
  position: relative;
}

/*
* Padding & Margins
*
*/

@for $i from 1 through 50 {
  .l-pad-#{$i} { padding: $unit * $i;}
  .l-pad-left-#{$i} { padding-left: $unit * $i;}
  .l-pad-right-#{$i} { padding-right: $unit * $i;}
  .l-pad-top-#{$i} { padding-top: $unit * $i;}
  .l-pad-bottom-#{$i} { padding-bottom: $unit * $i;}
  .l-pad-vertical-#{$i} { padding-top: $unit * $i; padding-bottom: $unit * $i;}
  .l-pad-horizontal-#{$i} { padding-left: $unit * $i; padding-right: $unit * $i;}
  .l-space-#{$i} { margin: $unit * $i;}
  .l-space-left-#{$i} { margin-left: $unit * $i;}
  .l-space-right-#{$i} { margin-right: $unit * $i;}
  .l-space-top-#{$i} { margin-top: $unit * $i;}
  .l-space-bottom-#{$i} { margin-bottom: $unit * $i;}
  .l-space-negative-left-#{$i} { margin-left: -($unit * $i);}
  .l-space-negative-right-#{$i} { margin-right: -($unit * $i);}
  .l-space-negative-top-#{$i} { margin-top: -($unit * $i);}
  .l-space-negative-bottom-#{$i} { margin-bottom: -($unit * $i);}
  .l-space-vertical-#{$i} { margin-top: $unit * $i; margin-bottom: $unit * $i;}
  .l-space-horizontal-#{$i} { margin-left: $unit * $i; margin-right: $unit * $i;}
  .l-width-#{$i} { width: $unit * $i; }
  .l-max-width-#{$i} { max-width: $unit * $i; }

  @include respond-to('mobile') {
    .l-pad-#{$i} { padding: $unit * $i/4;}
    .l-pad-left-#{$i} { padding-left: $unit * $i/4;}
    .l-pad-right-#{$i} { padding-right: $unit * $i/4;}
    .l-pad-top-#{$i} { padding-top: $unit * $i/4;}
    .l-pad-bottom-#{$i} { padding-bottom: $unit * $i/4;}
    .l-pad-vertical-#{$i} { padding-top: $unit * $i/4; padding-bottom: $unit * $i/4;}
    .l-pad-horizontal-#{$i} { padding-left: $unit * $i/4; padding-right: $unit * $i/4;}
    .l-space-#{$i} { margin: $unit * $i/4;}
    .l-space-left-#{$i} { margin-left: $unit * $i/4;}
    .l-space-right-#{$i} { margin-right: $unit * $i/4;}
    .l-space-top-#{$i} { margin-top: $unit * $i/4;}
    .l-space-bottom-#{$i} { margin-bottom: $unit * $i/4;}
    .l-space-negative-left-#{$i} { margin-left: 0;}
    .l-space-negative-right-#{$i} { margin-right: 0;}
    .l-space-negative-top-#{$i} { margin-top: 0;}
    .l-space-negative-bottom-#{$i} { margin-bottom: 0;}
    .l-space-vertical-#{$i} { margin-top: $unit * $i/4; margin-bottom: $unit * $i/4;}
    .l-space-horizontal-#{$i} { margin-left: $unit * $i/4; margin-right: $unit * $i/2;}
  }
}

//added by Alex from old styles
.left {
  padding-right: 25px;
}

.right {
  padding-left: 25px;
}

.justify-content-md-center {
  justify-content: center !important;
}

.d-md-flex {
  display: flex !important;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}