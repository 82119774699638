.card {
  background: $color-white;
  box-shadow: 0 4px 16px rgba($color: $color-primary, $alpha: .12), 0 4px 16px rgba($color: $color-black, $alpha: .12);
  border-radius: 16px;
  box-sizing: border-box;
  border: none;
  overflow: hidden;
  margin: 16px;
  padding: 188px 32px 32px 32px;
  position: relative;
  transition: all .3s;
  min-height: 320px;
  height: 95% !important;

  &:hover {
    box-shadow: 0 8px 32px rgba($color: $color-primary, $alpha: .12), 0 8px 32px rgba($color: $color-black, $alpha: .24);
    transform: scale(1);
  }

  figure {
    height: 164px;
    left: 0;
    overflow: hidden;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;

    img {
      left: 0;
      position: absolute;
      top: 0;
      width:100%;
    }

    em {
      font-size:64px;
      color:white;
      margin: 32px 0px 8px;
      position:relative;
    }
  }

  time, h5 {
    color: $color-grey-4;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
  }

  h4, 
  h4 a {
    color: $color-text;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    text-decoration: none;
  }

  p {
    color: $color-grey-4;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    margin: 16px 0 0 0;
  }

  a::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-max-1;
    content: "";
  }


  // DECORATORS FOR SPECIFIC CARD TYPES
  &.is-case-study {
    figure {
      align-items: center;
      display: flex;
      justify-content: center;

      img {
        position: relative;
        width: 70%;
      }
    }
  }

  // FLAT CARD 
  &.is-flat {
    box-shadow: none;
    border: 1px solid $color-grey-2;;
    padding: 248px 32px 32px 32px;

    &:hover {
      box-shadow: none;
      transform: scale(1);
    }

    figure {
      height: 224px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    figure img {
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
    }
  }

  &.is-flat-no-image {
    box-shadow: none;
    border: 1px solid $color-grey-2;;
    padding: 32px 32px 32px 32px;
    min-height:100px;

    &:hover {
      box-shadow: none;
      transform: scale(1);
    }
  }

  // EVENT CARD
  &.is-event {
    background: $color-gradient-blue;
    min-height: 260px;
    padding: 32px;
    position: relative;
 
    &.is-webinar {
      background: $color-gradient-pink;
    }

    &.is-tradeshow {
      background: $color-gradient-green;
    }

    i {
      text-shadow: 0 4px 16px rgba($color: $color-black, $alpha: .24);
      color: $color-white;
      cursor: pointer;
      font-size: 64px;
      margin: -32px 0 0 -32px;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: all .3s;
      z-index: 0;
    }

    time {
      box-shadow: rgba($color: $color-black, $alpha: .24);
      color: $color-white;
      font-weight: bold;
      display: inline-block;
      line-height: 16px;
      text-transform: uppercase;
      font-style: italic;
      margin-bottom: 24px;
      border-left: 4px solid $color-white;
      padding-left: 8px;
    }

    h6 {
      font-size: 32px;
      position: absolute;
      opacity: 0;
      margin-top: 80px;
      left: 0;
      right: 0;
      text-shadow: 0 8px 32px rgba($color: $color-black, $alpha: .87);
      text-align: center;
      z-index: 0;
    }

    h6,
    h5, 
    h4, 
    h4 a {
      color: $color-white;
      transition: all .3s;
    }

    h5 {
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    h4, 
    h4 a {
      font-size: 24px;
      line-height: 32px;
    }

    &:hover {
      h5, h4 {
        opacity: .24;
      }

      h6, i {
        opacity: 1;
      }
      
    }
  }

  // IS PARTNER
  &.is-partner {
    box-shadow: 0 1px 4px rgba($color: $color-black, $alpha: .24);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    min-height: 200px;

    &:hover {
      box-shadow: 0 1px 4px rgba($color: $color-black, $alpha: .24);
    }

    &.has-link {
      &:hover {
        box-shadow: 0 8px 32px rgba($color: $color-primary, $alpha: .12), 0 8px 32px rgba($color: $color-black, $alpha: .24);
      }
    }

    a::after {
      border: 2px solid $color-primary;
      border-radius: 16px;
    }
  

    h4 {
      text-align: center;

      img {
        max-width: 80%;
      }
    }
  }
  
  // DECORATORS FOR SPECIFIC CARD TYPES
  &.is-code {
    min-height: 380px;
    figure {
      display: block;
      text-align: center;

      figcaption {
        color: $color-white;
        font-weight: 500;
        font-size: 14px;
      }

      img {
        position: relative;
        width: 64px;
        margin: 32px 0 8px 0;
      }
    }
  }
}

// arrow that shows when there's a link
.card .arrow {
  padding-right: 15px;
  font-size: 25px;
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: end;
  margin-top: -12px;
}