.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.no-overflow {
  overflow: hidden !important;
}

/*
* RESPONSIVE STATES
*
*/

.is-hidden-mobile {
  @include respond-to('medium') {
    display: none !important;
  }
}

.is-visible-mobile {
  display: none !important;

  @include respond-to('medium') {
    display: block !important;
  }
}

.is-hidden-short {
  @include respond-to('short') {
    display: none !important;
  }
}

.is-visible-short {
  display: none !important;

  @include respond-to('short') {
    display: block !important;
  }
}