/*
* Dropdown
* 
* description: Dropdown generally used in top navigation 
*/

.dropdown {
  background: $color-white;
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba($color: #000000, $alpha: .24);
  left: 50%;
  margin-left: -216px;
  position: absolute;
  top: 96px;
  transform: translateY(-16px);
  width: 432px;
  visibility: hidden;
  opacity: 0;
  z-index: $z-max-1;

  @include respond-to('medium') {
    background: $color-grey-1;
    box-shadow: inset 0 1px 3px rgba($color: #000000, $alpha: .12);
    display: none;
    margin: 0;
    position: relative;
    left: 0;
    top: 0;
    width: auto;
    transition: all .3s;
    transform: translateY(0);
  }

  &.is-active {
    @include respond-to('medium') {
      display: block !important;
      visibility: visible !important;
      opacity: 1 !important;
    }
  }

  .dropdown-arrow {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 18px 24px 18px;
    border-color: transparent transparent #FFF transparent;
    left: 50%;
    margin-left: -18px;
    position: absolute;
    top: -12px; 
    transform: rotate(0deg);

    @include respond-to('medium') {
      display: none;
    }
 }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 16px;

    li {
      margin: 0 0 8px 0;
    }

    a {
      background: $color-white;
      border: 1px solid $color-white;
      border-radius: 8px;
      display: block;
      font-weight: 400;
      padding: 8px 8px 8px 72px;
      position: relative;
      text-decoration: none;
      transition: all .3s;

      &:hover {
        background: $color-grey-1;
        border: 1px solid $color-cyan;

        i {
          color: $color-cyan;
        }
      }
    }

    i {
      background: $color-grey-1;
      color: $color-grey-3;
      border-radius: 8px;
      height: 48px;
      left: 8px;
      line-height: 48px;
      position: absolute;
      text-align: center;
      top: 8px;
      transition: all .3s;
      width: 48px;
    }

    strong {
      color: $color-text;
      display: block;
      font-size: 12px;
      font-weight: 700;
      line-height: 24px;
    }

    em {
      color: $color-grey-4; 
      display: block;
      font-size: 12px;
      font-style: normal;
      line-height: 24px;
    }
  }

  footer {
    align-items: center;
    background: $color-grey-1;
    border-radius: 0 0 16px 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;

    @include respond-to('medium') {
      background: none;
      display: block;
    }

    strong {
      color: $color-text;
      display: block;
      font-size: 12px;
      font-weight: 700;
      line-height: 24px;
    }

    em {
      color: $color-grey-4; 
      display: block;
      font-size: 12px;
      font-style: normal;
      line-height: 24px;
    }
  }
}