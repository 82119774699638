/*
* Icons
* 
* description: decorator styles for icons
*/

.icon {
  // SIZE
  &.is-large {
    font-size: 32px;
    margin-bottom: 16px;
  }

  &.is-small {
    font-size: 16px;
  }

  // COLORS
  &.is-gradient {
    background: -webkit-linear-gradient(-90deg, #5BA3B0 0%, #62138E 60%, #582F6B 100%);
    background: linear-gradient(-90deg, #5BA3B0 0%, #62138E 60%, #582F6B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
}