/*
* Buttons
*
* description: button component with various decorators and styles
*/

.button {
  align-items: center;
  appearance: none;
  background: $color-primary;
  border: none;
  border-radius: 100px;
  box-sizing: border-box;
  color: $color-white;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  height: $unit * 5;
  justify-content: center;
  line-height: $unit * 5;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  text-decoration: none;
  text-align: center;
  transition: all .5s;
  white-space: nowrap;

  &:hover {
    background: $color-primary;
    box-shadow: 4px 4px 16px rgba($color: $color-cyan, $alpha: .32), -4px -4px 16px rgba($color: $color-primary, $alpha: .24);
    color: $color-white;
  }

  @include respond-to('medium') {
    padding: 0;
    display: block;
    margin: 8px 0;
    width: 100%;
  }
  

  // SIZE DECORATORS
  &.is-large {
    font-size: 16px;
    height: $unit * 6;
    line-height: $unit * 6;
    padding: 0 48px;
  }
  &.is-small {
    font-size: 12px;
    height: $unit * 4;
    line-height: $unit * 4;
  }

  
  // COLOR DECORATORS
  &.is-gradient {
    background: linear-gradient(-90deg, #5BA3B0 0%, #62138E 60%, #582F6B 100%);

    &:hover {
      box-shadow: 4px 4px 16px rgba($color: $color-cyan, $alpha: .32), -4px -4px 16px rgba($color: $color-primary, $alpha: .24);
      color: $color-white;
    }
  }

  &.is-link {
    background: none;
    border: 2px solid rgba($color: $color-white, $alpha: .24); 

    &:hover {
      box-shadow: none;
      color: $color-white;
      border: 2px solid rgba($color: $color-white, $alpha: 1); 
    }
  }

  &.is-light {
    background: $color-white;
    color: $color-primary;

    &:hover {
      box-shadow: 4px 4px 16px rgba($color: $color-white, $alpha: .32), -4px -4px 16px rgba($color: $color-grey-2, $alpha: .24);
      color: $color-highlight;
    }
  }


  // LAYOUT DECORATORS
  &.is-block {
    display: block;
    width: 100%;
  }
  &.is-cta {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  &.is-cta-bottom {
    margin-top: 40px;
  }
  &.is-cta-top {
    margin-bottom: 40px;
  }
}


//Old Buttons
.btn-outline-primary,  .btn-primary{
  align-items: center;
  appearance: none;
  background: #62138e;
  border: none;
  border-radius: 100px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  line-height: 40px;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  text-decoration: none;
  text-align: center;
  transition: all .5s;
  white-space: nowrap;
}