/*
* LOGO BAR
* 
* description: logo bar slide show module
*/

.logo-bar {
  margin-top: 80px;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 35px;
  }

  @include respond-to('medium') {
    margin: 40px 16px 0 16px;
    padding: 0 16px;
  }
}
