/*
* Top Navigation 
*
* description: Styles for main navigation of the website
*/

.top-nav {
  align-items: center;
  display: flex;
  height: 96px;
  padding: 0 80px;
  position: relative;

  @include respond-to('medium') {
    padding: 0 16px;
  }

  // DECORATORS
  &.is-grey {
    background: $color-grey-1;
  }

  .logo {
    margin-right: 80px;
    
    @include respond-to('large') {
      font-size: 18px;
      margin-right: 0;
      padding: 0 8px;

      img {
        height: 32px;
      }
    }
  }

  .top-nav-open, 
  .top-nav-close {
    color: $color-grey-4;
    cursor: pointer;
    display: none;
    font-size: 24px;
    position: absolute;
    right: 32px;
    top: 32px;
    z-index: $z-max-3;

    @include respond-to('medium') {
      &.is-active {
        display: block;
      }
    }
  }

  .top-nav-close {
    position: fixed;
  }

  .top-nav-menu {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include respond-to('medium') {
      box-sizing: border-box;
      display: none;
      overflow: auto;
      padding: 80px 16px 16px 16px;

      &.is-active {
        background: $color-white;
        bottom: 0;
        display: block;
        left: 0;
        position: fixed;
        right: 0; 
        top: 0; 
        z-index: $z-max-1;
      }
    }

    > ul {
      list-style-type: none;
      margin: 0; 
      padding: 0; 

      > li {
        display: inline-block;
        margin: 0; 
        position: relative;

        @include respond-to('medium') {
          display: block;
          margin-bottom: 16px;
        }
  
        // DROPDOWN MENU REVEAL STYLES
        &:hover, &:focus, &:active {
           button {
            background: $color-white;
            color: $color-primary;

            @include respond-to('medium') {
              background: $color-grey-1;
            }
          }
  
          .dropdown {
            display: block;
            visibility: visible;
            transform: translateY(0px);
            opacity: 1;

            @include respond-to('medium') {
              display: none;
            }
          }
        }
      }
  
      // SEPERATED SO IE 11 DOESN'T REMOVE OTHER STYLES SINCE IT DOES NOT SUPPOR THIS
      > li {
        &:hover {
          button {
            background: $color-white;
            color: $color-primary;

            @include respond-to('medium') {
              background: $color-grey-1;
            }
          }
          .dropdown {
            display: block;
            visibility: visible;
            opacity: 1;

            @include respond-to('medium') {
              display: none;
            }
          }
        }
      }
    }
  }

  .top-nav-button {
    background: none;
    border: none;
    box-sizing: border-box;
    color: $color-text;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 96px;
    margin: 0;
    padding: 0 12px;
    text-decoration: none;
    transition: all .2s;

    @include respond-to('large') {
      font-size: 12px;
      padding: 0 8px;
    }

    @include respond-to('medium') {
      background: $color-grey-1;
      border-radius: 8px;
      display: block;
      line-height: 56px;
      padding: 0 24px;
      text-align: center;
      width: 100%;
    }
  }

  .top-nav-cta {
    @include respond-to('medium') {
      margin-top: 40px;
      line-height: 48px;
      height: 48px;
    }
  }
}
