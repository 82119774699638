/*
* Launchpad
*
* description: A mega menu located at the bottom of the page
*/

.launchpad {

  footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    
    @include respond-to('small') {
      display: block;
      margin-top: 0;
      text-align: center;

      .logo {
        justify-content: center;
        margin-bottom: 24px;
      }
    }

    ul, p {
      color: $color-text;
      font-size: 12px;
      line-height: 32px;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 8px;
      }

      a {
        color: $color-text; 
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }
}