/*
* Marketo Forms
*
* description: Used to overwrite the standard marketo css
*/

.mktoFormWrapper {
    background: $color-white;
    border: 1px solid $color-grey-2;
    border-radius: 16px;
    box-shadow: 0 4px 16px rgba($color: $color-black, $alpha: .12);
    padding: 24px;

    h3 {
        @extend .text-info-title; 
        margin-top: 0;
    }

    &.is-grey {
        background: $color-grey-1;
    }

    footer {
        padding-top: 24px;

        p {
            @extend .text-meta; 
            color: $color-grey-4;
        }

        a {
            color: $color-grey-4;
        }
    }
}

.mktoForm {
    max-width:100%;
    
    .mktoFormRow, .mktoFieldWrap, .mktoField, .mktoFormCol {
        width: 100%!important;
    }
    .mktoAsterix {
        display: none!important;
    }
    .mktoFieldWrap {
        padding: 5px 0px 5px 0px;
    }
    .mktoButtonRow {
        padding: 5px 0px 5px 0px;
    }
    .mktoButton {
        @extend .button;
    }

    input, textarea {
        border: 1px solid $color-grey-3;
        box-shadow: inset 0 1px 3px rgba($color: $color-black, $alpha: .12);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 8px 16px!important;
        height: 48px;
        margin-bottom: 16px !important;
    }

    textarea {
        height: 120px !important;
    }

    .mktoButton {
        background: linear-gradient(-90deg, #5BA3B0 0%, #62138E 60%, #582F6B 100%) !important;
        border: none  !important;
        padding: 0 24px !important;
        color: $color-white !important;
        cursor: pointer !important;
        display: inline-flex !important;
        font-weight: 500 !important;
        font-size: 14px !important;

        &:hover {
            box-shadow: 4px 4px 16px rgba($color: $color-cyan, $alpha: .32), -4px -4px 16px rgba($color: $color-primary, $alpha: .24)  !important;
            color: $color-white  !important;
        }
    }
}