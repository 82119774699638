.industry-diagram {
  
  
  .industry-inputs, 
  .industry-outputs {
    background: $color-white;
    border:  1px solid $color-grey-2;
    border-radius: 16px;
    // box-shadow: 0 4px 16px rgba($color: $color-black, $alpha: .24);
    padding: 24px;
    z-index: 3;
  }

  .industry-model {
    background: url("/src/wp-content/themes/pachyderm/img/graphics/data.gif") top center no-repeat;
    z-index: 1;
    text-align: center;

    figure {
      background: url("/src/wp-content/themes/pachyderm/img/graphics/data.gif") bottom center no-repeat;
      padding: 120px 0;
      margin: 0;

      img {
        max-height: 400px;
        max-width: 100%;
      }
    }
  }
}


