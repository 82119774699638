.hero {
  h1 {
    strong, b, em {
      font-style: normal;
      font-weight: 700;

      @include respond-to('large') {
        font-size: 68px;
      }
    
      @include respond-to('medium') {
        font-size: 68px;
      }
    
      @include respond-to('small') {
        font-size: 48px;
      }
    }
  }

  // STYLES FOR CASE STUDY HEROS
  &.is-case-study {
    background-size: cover;


    .hero-inner {
      @extend .background-transparent-white; 
      padding-bottom: 40px;
    }

    .hero-content {
      h1, p {
        text-shadow: 0 4px 16px rgba($color: $color-black, $alpha: .12);
      }
      h1 {
        @extend .text-main-title, .is-gradient; 
        font-size: 60px;
        line-height: 70px;
      }
      p {
        @extend .text-paragraph;
        font-size: 20px;
      }
    }
  }
}