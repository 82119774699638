.pricing-card {
  border-radius: 16px;
  box-sizing: border-box;
  height: 480px;
  overflow: hidden;
  margin-top: 24px;

  &.is-enterprise {
    box-shadow: 0 4px 32px rgba($color: $color-primary, $alpha: .24), 0 8px 32px rgba($color: $color-black, $alpha: .24);
    position: relative;

    .text-section-title {
      @include respond-to('large') {
        font-size: 32px;
      }
      @include respond-to('medium') {
        font-size: 28px;
      }
      @include respond-to('small') {
        line-height: 22px;
      }
    }

    .text-paragraph {
      @include respond-to('large') {
        font-size: 18px;
      }
      @include respond-to('medium') {
        font-size: 14px;
        line-height: 24px;
      }
    }

    .text-list li {
      @include respond-to('large') {
        font-size: 18px;
      }
      @include respond-to('medium') {
        font-size: 14px;
      }
    }





    .is-background {
      background: url("/src/wp-content/themes/pachyderm/img/backgrounds/skyscraper.png") top left no-repeat;
      background-size: 320px 490px;
      padding: 40px 40px 0 380px;
      box-sizing: border-box;
      height: 480px;

      @include respond-to('large') {
        
      }
    
      @include respond-to('medium') {
        background: none;
        padding: 40px;
      }
    }

    .button.is-light {
      margin-right: 3px;

      @include respond-to('medium') {
        margin: 0 0 3px 0;
      }
    }
  }

  &.is-community {
    background: $color-white;
    border: 1px solid $color-grey-2;
    padding: 40px;
    position: relative;

    @include respond-to('large') {
      height: auto;
    }

    h3 {
      font-weight: 800;
    }

    .button {
      background: $color-grey-1;
      bottom: 40px;
      left: 40px;
      position: absolute;
      right: 40px;

      @include respond-to('large') {
        bottom: auto;
        display: block;
        left: auto;
        margin-top: 32px;
        position: relative;
        right: auto;
      }
    }
  }


}