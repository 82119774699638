/*
* Customer Carousel
*
* description: A sliding carousel of customer case studies
*/

.customer-carousel {
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba($color: $color-black, $alpha: .24);
  margin: 40px auto 0 auto;
  max-width: 1120px;

  @include respond-to('medium') {
    margin-top: 0;
  }

  .slick-list {
    border-radius: 16px;
  }
  
  .slide {
    box-sizing: border-box;
    height: 400px;
    max-width: 1120px;
    padding: 50px;
    text-align: center;

    @include respond-to('medium') {
      height: auto;
      padding: 24px;
    }

    img {
      height: 100px;
      margin: 0 auto 0 auto;
    }

    p {
      margin-bottom: 32px;

      @include respond-to('medium') {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    left: 0;
    list-style-type: none;
    margin: 0;
    padding: 32px 0;
    position: absolute;
    right: 0;

    li.slick-active {
      button {
        border-color: $color-cyan;
        color: $color-cyan;
      }
    }
    
    button {
      background: $color-white;
      border: 2px solid $color-grey-3;
      border-radius: 4px;
      color: $color-grey-4;
      cursor: pointer;
      height: 24px;
      margin: 0 12px;
      transition: all .3s;
      width: 24px;

      &:hover {
        border-color: $color-primary;
        color: $color-primary;
      }
    }
  }
}