.aside {
  background: $color-white;
  box-shadow: 0 4px 16px rgba($color: $color-primary, $alpha: .12), 0 4px 16px rgba($color: $color-black, $alpha: .12);
  border-radius: 16px;
  box-sizing: border-box;
  border: none;
  overflow: hidden;
  padding: 32px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: $z-1;

  @include respond-to('large') {
    position: relative;
    margin-bottom: 32px;
  }

  @include respond-to('medium') {
    margin-bottom: 16px;
  }

  figure {
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
      max-height:144px;
      max-width: 100%;
    }
  }

  h3 {
    @extend .text-meta-title; 
    color: rgba($color: $color-text, $alpha: .56);
    font-weight: bold;
    margin: 32px 0 8px 0;
  }

  h4 {
    @extend .text-info-title, .is-gradient; 
    margin: 0 0 8px 0;
  }

  p {
    @extend .text-meta; 
  
  }
}