/*
* Typographical Styles
*
* description: Styles for text treatment of titles and body text
*/


/*
* Shared Styles
*
* description: Shared styles and decorators  
*/

.text-super-title, 
.text-jumbo-title, 
.text-large-title, 
.text-main-title, 
.text-section-title, 
.text-info-title, 
.text-meta-title, 
.text-paragraph, 
.text-list,
.text-meta, 
.text-meta-list {
  color: $color-text; 
  font-weight: 400;
  font-family: $primary-font;
  font-style: normal;
  margin: 0 0 16px 0;
  
  // Style Decorators
  &.is-italic {
    font-style: italic;
  }
  &.is-medium {
    font-weight: 500;
  }
  &.is-bold {
    font-weight: 700;
  }
  &.is-extra-bold {
    font-weight: 800;
  }
  &.is-black {
    font-weight: 900;
  }
  &.is-uppercase {
    text-transform: uppercase;
  }

  &.is-lowercase {
    text-transform: lowercase;
  }

  // Layout Decorators
  &.no-margin {
    margin: 0 !important;
  }
  &.is-center {
    text-align: center;
  }
  &.is-right {
    text-align: right;
  }

  // Color Decorators
  &.is-disabled {
    opacity: .24;
  }
  &.is-brand {
    color: $color-primary;
  }
  &.is-gradient {
    background: $color-gradient-blue;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
  &.is-gradient-pink {
    background: -webkit-linear-gradient(-90deg, #DB5B62 , #A122CC);
    background: linear-gradient(-90deg, #DB5B62 , #A122CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
  &.is-light {
    color: $color-white;
  }
}

/*
* Title Text
*
* description: Text for large and small titles (in order of size) 
*/

.text-super-title {
  font-size: 124px;
  font-weight: 800;
  line-height: 132px;
  margin-bottom: 32px;

  @include respond-to('small') {
    font-size: 64px;
    font-weight: 700;
    line-height: 80px;
    margin-bottom: 40px;
  }
}

.text-jumbo-title {
  font-size: 70px;
  font-weight: 700;
  line-height: 80px;
  margin-bottom: 40px;

  @include respond-to('medium') {
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    margin-bottom: 24px;
  }

  @include respond-to('small') {
    font-size: 48px;
    font-weight: 700;
    line-height: 54px;
    margin-bottom: 24px;
  }
}

.text-large-title {
  font-size: 64px;
  font-weight: 800;
  line-height: 80px;
  margin-bottom: 24px;

  @include respond-to('medium') {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 24px;
  }
}

.text-main-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  margin-bottom: 24px;

  @include respond-to('medium') {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 24px;
  }
}

.text-section-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 24px;
}

.text-info-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 24px;
}

.text-meta-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}



/*
* Body Text
*
* description: Text for paragraphs and longer form messages
*/

.text-paragraph {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
}


/*
* List Text
*
* description: Text for lists of items
*/

.text-list {

  &.is-plain {
    list-style-type: none;
    margin:  0 0 32px 0;
    padding: 0;
  }
  
  li {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 8px 0;
  }

  i {
    margin-right: 8px;
  }
}


/*
* Meta Text
*
* description: Small text content for titles and paragraphs
*/

.text-meta {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
}

.text-meta-list {
  list-style-type: none;
  margin:  0 0 32px 0;
  padding: 0;
  
  li {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 8px 0;
  }

  a {
    color: rgba($color: $color-text, $alpha: .87);
    font-weight: 400;
    text-decoration: none;

    @include respond-to('medium') {
      background: $color-white;
      border-radius: 8px;
      color: $color-cyan;
      display: block;
      line-height: 48px;
      padding: 0 16px;
    }
  }
}


/*
* Auto Text
*
* description: Automatic Text formating for all items within this class
*/

.text-auto-format {
  h2 {@extend .text-main-title, .is-gradient;}
  h3 {@extend .text-section-title; margin-top: 48px;}
  h4 {@extend .text-info-title;}
  h5 {@extend .text-meta-title;}
  h6 {@extend .text-meta-title;}
  p {@extend .text-paragraph;}
  small {@extend .text-meta;}
  ul {
    @extend .text-list; 
    
    i {
      color: $color-cyan;
      font-size: 16px;
      margin-right: 8px;
    }
  };
}